import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import BeatLoader from 'react-spinners/BeatLoader'
import sary from '../../../assets/images/sooda_logo/default.png'
import { getAllData } from '../../../helpers/suivi/suivi'
import PageNotFound from '../../../components/404/PageNotFound'
import { useToasts } from 'react-toast-notifications'
//Liste entreprise du suivi opérationnel
const ListeEntreprise = () => {
  const {addToast} = useToasts()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const [wait, setWait] = useState(false)
  //const [penalites, setPenalite] = useState(statut)

  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const navigate = useNavigate()
  const [lots, setLot] = useState([])

  useEffect(() => {
    setWait(true)
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((result) => {
      console.log(result.data)
      console.log(result)
      setLot(result.data)
      setWait(false)
    })
  }, [])
  const filterColor = (color) => {
    switch (color) {
      case '#5bc0de':
        return 'thumb-xs justify-content-center d-flex align-items-center bg-soft-info rounded-circle me-1'
      case '#f0ad4e':
        return 'thumb-xs justify-content-center d-flex align-items-center bg-soft-warning rounded-circle me-1'
      case '#d9534f':
        return 'thumb-xs justify-content-center d-flex align-items-center bg-soft-danger rounded-circle me-1'
      default:
        return 'thumb-xs justify-content-center d-flex align-items-center bg-soft-info rounded-circle me-1'
    }
  }
  return (
    <>
      <div class="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div class="page-wrapper">
        <div class="page-content-tab">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <h4 class="page-title">Suivi opérationnel</h4>
                </div>
                {/* <div class="page-title-box">
                  <div class="float-end"></div>
                  <h6 class="page-title">Suivi LOT par LOT</h6>
                </div> */}

                {/* liste debut  */}
                <div className="row">
                  {lots &&
                    lots.map((lot) => {
                      if (lot) {
                        return (
                          <>
                            <div
                              class="col-lg-4"
                              style={{ cursor: 'pointer' }}
                              onClick={(el) => {
                                el.preventDefault()
                                localStorage.setItem('_id', lot.entreprise?._id)
                                localStorage.setItem('titreLot', lot.titre)
                                localStorage.setItem('_idLot', lot._id)
                                if (
                                  infoUser?.user?.role?.roleType == 'admin' ||
                                  infoUser?.user?.role?.roleType == 'opc'
                                ) {
                                  navigate(`/SuiviOperationnel_Profil`)
                                }
                              }}
                            >
                              <div class="card">
                                <div class="card-body">
                                  <div class="media mb-3">
                                    <img
                                      src={`${process.env.REACT_APP_URL}/api/v1/lots/photo/${lot.photo}`}
                                      alt=""
                                      class="thumb-md rounded-circle"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null // prevents looping
                                        currentTarget.src = sary
                                      }}
                                    />
                                    <div class="media-body align-self-center text-truncate ms-2">
                                      <h4 class="m-0 fw-bold text-dark font-18">
                                        Lot : {lot ? lot.titre : <>...</>}
                                      </h4>
                                      <p class="text-muted  mb-0 font-13">
                                        <span class="text-dark">
                                          Responsable :{' '}
                                        </span>
                                        {lot.entreprise?.responsable?.name ? (
                                          lot.entreprise.responsable.name
                                        ) : (
                                          <>...</>
                                        )}{' '}
                                        {lot.entreprise?.responsable
                                          ?.lastname ? (
                                          lot.entreprise.responsable.lastname
                                        ) : (
                                          <>...</>
                                        )}
                                      </p>
                                    </div>
                                    <div className="media">
                                      <span
                                        class={filterColor(
                                          lot.globalProgressDetails.color
                                        )}
                                      >
                                        {lot.globalProgressDetails.note
                                          ? lot.globalProgressDetails.note
                                          : 'A'}
                                      </span>
                                    </div>
                                  </div>
                                  <div class="d-flex justify-content-between">
                                    <h6 class="fw-semibold">
                                      Date début :{' '}
                                      <span class="text-muted font-weight-normal">
                                        {' '}
                                        {lot?.info?.startEnd?.start_date
                                          ? lot.info.startEnd.start_date
                                          : 'JJ/MM/AAAA'}
                                      </span>
                                    </h6>
                                    <h6 class="fw-semibold">
                                      Deadline :{' '}
                                      <span class="text-muted font-weight-normal">
                                        {' '}
                                        {lot?.info?.startEnd?.end_date
                                          ? lot.info.startEnd.end_date
                                          : 'JJ/MM/AAAA'}
                                      </span>
                                    </h6>
                                  </div>
                                  <div>
                                    <div class="d-flex justify-content-between"></div>
                                    <p class="text-muted text-end mb-1">
                                      {lot?.info?.progress
                                        ? (lot.info.progress * 100).toFixed(2)
                                        : '0'}
                                      % Réalisé
                                    </p>
                                    <div class="progress mb-4">
                                      <input
                                        type="range"
                                        min={0}
                                        max={1}
                                        step={0.01}
                                        value={
                                          lot?.info?.progress
                                            ? lot.info.progress
                                            : 0
                                        }
                                        style={{ width: '100%' }}
                                      />
                                    </div>
                                    <div class="d-flex justify-content-between">
                                      <div class="img-group"></div>
                                      <ul class="list-inline mb-0 align-self-center">
                                        <li class="list-item d-inline-block me-2">
                                          <a class="" href="#">
                                            <i class="mdi mdi-format-list-bulleted text-info font-15"></i>
                                            <span class="text-muted fw-bold">
                                              {' '}
                                              P:
                                              {lot.details?.planningSo?.progress
                                                ? lot.details.planningSo
                                                    .progress
                                                : '0'}
                                              /
                                              {lot.details?.planningSo?.length
                                                ? lot.details.planningSo.length
                                                : '0'}
                                            </span>
                                          </a>
                                        </li>
                                        <li class="list-item d-inline-block me-2">
                                          <a class="" href="#">
                                            <i class="mdi mdi-format-list-bulleted text-success font-15"></i>
                                            <span class="text-muted fw-bold">
                                              {' '}
                                              E:
                                              {lot.details?.engagement?.progress
                                                ? lot.details.engagement
                                                    .progress
                                                : '0'}
                                              /
                                              {lot.details?.engagement?.length
                                                ? lot.details.engagement.length
                                                : '0'}
                                            </span>
                                          </a>
                                        </li>
                                        <li class="list-item d-inline-block me-2">
                                          <a class="" href="#">
                                            <i class="mdi mdi-format-list-bulleted text-warning font-15"></i>
                                            <span class="text-muted fw-bold">
                                              {' '}
                                              A:
                                              {lot.details?.administratif
                                                ?.progress
                                                ? lot.details.administratif
                                                    .progress
                                                : '0'}
                                              /
                                              {lot.details?.administratif
                                                ?.length
                                                ? lot.details.administratif
                                                    .length
                                                : '0'}
                                            </span>
                                          </a>
                                        </li>
                                        <li class="list-item d-inline-block me-2">
                                          <a class="" href="#">
                                            <i class="mdi mdi-format-list-bulleted text-primary font-15"></i>
                                            <span class="text-muted fw-bold">
                                              {' '}
                                              D:
                                              {lot.details?.document?.progress
                                                ? lot.details?.document
                                                    ?.progress
                                                : '0'}
                                              /
                                              {lot.details?.document?.length
                                                ? lot.details?.document?.length
                                                : '0'}
                                            </span>
                                          </a>
                                        </li>
                                        <li class="list-item d-inline-block me-2">
                                          <a class="" href="#">
                                            <i class="mdi mdi-format-list-bulleted text-danger font-15"></i>
                                            <span class="text-muted fw-bold">
                                              {' '}
                                              S:
                                              {lot.details?.securite?.progress
                                                ? lot.details.securite.progress
                                                : '0'}
                                              /
                                              {lot.details?.securite?.length
                                                ? lot.details.securite.length
                                                : '0'}
                                            </span>
                                          </a>
                                        </li>
                                        <li class="list-item d-inline-block me-2">
                                          <a class="" href="#">
                                            <i class="mdi mdi-format-list-bulleted font-15"></i>
                                            <span class="text-muted fw-bold">
                                              {' '}
                                              IC:
                                              {lot.details?.ic?.progress
                                                ? lot.details.ic.progress
                                                : '0'}
                                              /
                                              {lot.details?.ic?.length
                                                ? lot.details.ic.length
                                                : '0'}
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    })}
                </div>
                {/* liste fin */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {wait && (
        <>
          <div style={{ position: 'fixed', top: '40%', left: '55%' }}>
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default ListeEntreprise
