import React from 'react';
import { Check2Circle } from 'react-bootstrap-icons';
const Spinner = ({ importation, convertion, exploit }) => {
  return (
    <div className="container-fluid">
      <div className="container" style={{ width: '50%', marginTop: '5%' }}>
       <h3 className='text-center' style={{color:"gray"}}><u>Veuillez patienter durant le traitement des données</u></h3>
        <div className="d-flex"> 
          {importation ? (
            <div class="spinner-border text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <Check2Circle color="#79CA47" size={25} />
          )}
          <h6 style={{ marginLeft: '2vw' }}>-Importation</h6>
        </div>
        <br />
        <div className="d-flex">
        {convertion ? (
            <div class="spinner-border text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <Check2Circle color="#79CA47" size={25} />
          )}
          <h6 style={{ marginLeft: '2vw' }}>-Convertion des données</h6>
        </div>
        <br />
        <div className="d-flex">
        {exploit ? (
            <div class="spinner-border text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <Check2Circle color="#79CA47" size={25} />
          )}
          <h6 style={{ marginLeft: '2vw' }}>-Exploitation des données</h6>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Spinner;
