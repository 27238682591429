import React from "react";
import "react-tabulator/lib/styles.css";
import "tabulator-tables/dist/css/tabulator_bootstrap3.min.css";
import { ReactTabulator } from "react-tabulator";

const Table = () => {
  const data = [
    {
      id: 1,
      name: "Oli Bob",
      progress: 12,
      gender: "male",
      rating: 1,
      col: "red",
      dob: "19/02/1984",
      car: 1,
    },
    {
      id: 2,
      name: "Mary May",
      progress: 1,
      gender: "female",
      rating: 2,
      col: "blue",
      dob: "14/05/1982",
      car: true,
    },
    {
      id: 3,
      name: "Christine Lobowski",
      progress: 42,
      gender: "female",
      rating: 0,
      col: "green",
      dob: "22/05/1982",
      car: "true",
    },
    {
      id: 4,
      name: "Brendon Philips",
      progress: 100,
      gender: "male",
      rating: 1,
      col: "orange",
      dob: "01/08/1980",
    },
    {
      id: 5,
      name: "Margret Marmajuke",
      progress: 16,
      gender: "female",
      rating: 5,
      col: "yellow",
      dob: "31/01/1999",
    },
    {
      id: 6,
      name: "Frank Harbours",
      progress: 38,
      gender: "male",
      rating: 4,
      col: "red",
      dob: "12/05/1966",
      car: 1,
    },
    {
      id: 1,
      name: "Oli Bob",
      progress: 12,
      gender: "male",
      rating: 1,
      col: "red",
      dob: "19/02/1984",
      car: 1,
    },
    {
      id: 2,
      name: "Mary May",
      progress: 1,
      gender: "female",
      rating: 2,
      col: "blue",
      dob: "14/05/1982",
      car: true,
    },
    {
      id: 3,
      name: "Christine Lobowski",
      progress: 42,
      gender: "female",
      rating: 0,
      col: "green",
      dob: "22/05/1982",
      car: "true",
    },
    {
      id: 4,
      name: "Brendon Philips",
      progress: 100,
      gender: "male",
      rating: 1,
      col: "orange",
      dob: "01/08/1980",
    },
    {
      id: 5,
      name: "Margret Marmajuke",
      progress: 16,
      gender: "female",
      rating: 5,
      col: "yellow",
      dob: "31/01/1999",
    },
    {
      id: 6,
      name: "Frank Harbours",
      progress: 38,
      gender: "male",
      rating: 4,
      col: "red",
      dob: "12/05/1966",
      car: 1,
    },
  ];

  const columns = [
    { title: "Name", field: "name", width: 150, editor: "input" },
    {
      title: "Location",
      field: "location",
      width: 130,
      editor: "autocomplete",
      editorParams: { allowEmpty: true, showListOnEmpty: true, values: true },
    },
    {
      title: "Progress",
      field: "progress",
      sorter: "number",
      hozAlign: "left",
      formatter: "progress",
      width: 140,
      editor: true,
    },
    {
      title: "Gender",
      field: "gender",
      editor: "select",
      editorParams: {
        values: { male: "Male", female: "Female", unknown: "Unknown" },
      },
    },
    {
      title: "Rating",
      field: "rating",
      formatter: "star",
      hozAlign: "center",
      width: 100,
      editor: true,
    },
    {
      title: "Date Of Birth",
      field: "dob",
      hozAlign: "center",
      sorter: "date",
      width: 140,
    },
    {
      title: "Driver",
      field: "car",
      hozAlign: "center",
      editor: true,
      formatter: "tickCross",
    },
  ];

  return (
    <>
      <div class="page-wrapper">
        <div class="page-content-tab">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <h4 class="page-title">Editable</h4>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h4 class="card-title">Engagements </h4>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <ReactTabulator
                        data={data}
                        columns={columns}
                        height={"310px"}
                        layout={"fitColumns"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
