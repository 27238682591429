import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
const Model = ({ id, lot, taches }) => {
  const dispatch = useDispatch()
  const bool = useSelector((state) => state.bool.bool)
  const [bools, setBools] = useState(true)
  const [titre, setTitre] = useState()
  const [tacheArticle, setTacheArticle] = useState([])
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)

  const saveArticle = () => {
    console.log(titre, tacheArticle, lot)
    axios
      .post(`${process.env.REACT_APP_URL}/api/v1/articles`, {
        articleName: titre,
        taches: tacheArticle,
        planning: infoChantier.planning,
        lot: lot,
      },{
        headers: {
          Authorization: `Bearer ${infoUser.token}`
        },
      })
      .then((e) => {
        dispatch({ type: 'bool/modifier', payload: !bool })
        //setBools(!bools)
        setTacheArticle([])
      })
      .catch((e) => {
        dispatch({ type: 'bool/modifier', payload: !bool })
        setTacheArticle([])
      })
  }
  return (
    <>
      <div
        class="modal fade modal-dialog-scrollable"
        id={id}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Ajouter une article
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={(el) => {
                el.preventDefault()
                el.target.reset()
                saveArticle()
              }}
            >
              <div class="modal-body">
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">
                    Article :
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputPassword1"
                    placeholder="Entrer le titre de l'article"
                    onChange={(e) => {
                      setTitre(e.target.value)
                    }}
                    required
                  />
                </div>
                <h5>
                  {' '}
                  <u>Selectionner les taches pour cette article</u>{' '}
                </h5>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">id</th>
                      <th scope="col">Taches</th>
                      <th scope="col">Assigner</th>
                    </tr>
                  </thead>
                  <tbody>
                    {taches.map((e) => {
                      return (
                        <>
                          <tr>
                            <td>{e.id}</td>
                            <td>{e.text}</td>
                            <td>
                              {' '}
                              <input
                                type="checkbox"
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    tacheArticle.push(e)
                                    console.log(tacheArticle)
                                  } else {
                                    setTacheArticle(
                                      tacheArticle.filter(
                                        (element) => element.id != e.id
                                      )
                                    )
                                    console.log(tacheArticle)
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Model
