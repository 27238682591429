import React, { useState, useEffect } from 'react'
import Menu from '../../components/navBar/Menu'
import Header from '../../components/navBar/Header'
import { getChantier, addChantier } from '../../helpers/acceuil/acceuil'
import plan from '../../assets/images/sooda_logo/planning.jpg'
import { useNavigate } from 'react-router-dom'
import PageNotFound from '../../components/404/PageNotFound'
import BeatLoader from 'react-spinners/BeatLoader'
const Acceuil = () => {
  const navigate = useNavigate()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const [adresse, setAdresse] = useState('')
  const [titre, setTitre] = useState('')
  const [photo, setPhoto] = useState()
  const [client, setClient] = useState('')
  const [data, setData] = useState([])
  const [bool, setBool] = useState(true)
  const [wait, setWait] = useState(false)
  const [nbJours, setNbJours] = useState(0)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  console.log(infoChantier)
  const totalJours = () => {
    const exist = data.filter((e) => {
      console.log(e)
      if (!e.intervenants) {
        return false
      }
      return e.intervenants.intervenants.find(
        (tb) => tb.intervenant == infoUser.user._id
      )
    })
    const total = exist.reduce((acc, value) => {
      if (value.dureeChantier) {
        return acc + value.dureeChantier
      } else {
        return acc + 0
      }
    }, 0)
    console.log(total)
    return total
  }
  const filter = (value) => {
    const exist = value.filter((e) => e.intervenant == infoUser.user._id)
    if (exist.length > 0) return true
    return false
  }
  useEffect(() => {
    setNbJours(totalJours())
  }, [data])
  //recupération des chantiers
  useEffect(() => {
    setWait(true)
    getChantier(infoUser.token).then((e) => {
      if (e.data.chantiers) {
        //setNbJours(e.data.nbJour)
        console.log(e.data.chantiers)
        setData(e.data.chantiers)
        setWait(false)
      }
    })
  }, [bool])
  const handleSubmit = () => {
    addChantier(adresse, titre, photo, client).then((e) => {
      setBool(!bool)
    })
  }
  return (
    <>
      {wait && (
        <>
          <div
            className="container-fluid d-flex"
            style={{
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              zIndex: '2000',
              backgroundColor: 'rgba(104, 93, 119, 0.7)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BeatLoader color="yellowgreen" loading={true} size={50} />
          </div>
        </>
      )}
      <div className="d-flex flex-column">
        <Menu bool={false} />
        <Header bool={false} />
      </div>
      <div class="page-wrapper">
        <div class="page-content-tab">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <h4 class="page-title">Accueil</h4>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="d-flex justify-content-between mb-3">
                  <div class="align-self-center">
                    {(infoUser?.user?.role?.roleType == 'admin' ||
                      infoUser?.user?.role?.roleType == 'opc') && (
                      <>
                        <button
                          type="button"
                          class="btn btn-de-success btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalRequest"
                          disabled={true}
                          style={{ cursor: 'not-allowed' }}
                        >
                          <i
                            data-feather="download"
                            class="align-self-center icon-xs me-2"
                          ></i>
                          Nouveau chantier
                        </button>
                      </>
                    )}

                    <div
                      class="modal fade"
                      id="exampleModalRequest"
                      tabindex="-1"
                      aria-labelledby="exampleModalRequestLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h6
                              class="modal-title m-0"
                              id="exampleModalRequestLabel"
                            >
                              Ajouter un nouveau chantier
                            </h6>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <form
                            onSubmit={(e) => {
                              e.target.reset()
                              e.preventDefault()
                              handleSubmit()
                            }}
                          >
                            <div class="modal-body">
                              <div class="mb-2">
                                <label for="cryptocurrency">Adresse</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setAdresse(e.target.value)
                                  }}
                                  required
                                />
                              </div>
                              <div class="mb-2">
                                <label for="cryptocurrency">Nom chantier</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setTitre(e.target.value)
                                  }}
                                  required
                                />
                              </div>
                              <div class="mb-2">
                                <label for="cryptocurrency">Client</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setClient(e.target.value)
                                  }}
                                  required
                                />
                              </div>
                              <div class=" mb-2">
                                <label for="toaddress">Photo</label>
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setPhoto(e.target.files[0])
                                  }}
                                  required
                                />
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                class="btn btn-de-success btn-sm"
                                type="submit"
                              >
                                Enregistrer
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="media">
                    <img
                      src={plan}
                      class="me-2 thumb-sm align-self-center rounded-circle"
                      alt="..."
                    />
                    <div class="media-body align-self-center">
                      <p class="mb-1 text-muted">Jours plannifiés</p>
                      <h5 class="mt-0 text-dark mb-1">
                        {nbJours ? nbJours : 0} jours
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              {data &&
                data.map((e) => {
                  if (e.intervenants && filter(e.intervenants?.intervenants)) {
                    return (
                      <>
                        <div
                          class="col-lg-4"
                          style={{ cursor: 'pointer' }}
                          onClick={(el) => {
                            el.preventDefault()
                            localStorage.removeItem('infoChantiers')
                            localStorage.setItem(
                              'infoChantiers',
                              JSON.stringify(e)
                            )
                            sessionStorage.setItem('zoomPlanning', 'mois')
                            navigate('/Planning_Dashboard')
                          }}
                        >
                          <div class="card ">
                            <div class="card-header">
                              <div class="row align-items-center">
                                <div class="col">
                                  <div class="media">
                                    <img
                                      src={`${process.env.REACT_APP_URL}/api/v1/ressources/img/files,photo,chantier/${e.photo}?accessToken=${infoUser.token}`}
                                      class="me-2 thumb-sm rounded-circle"
                                      alt="..."
                                    />
                                    <div class="media-body align-self-center">
                                      <h5 class="m-0 font-15">{e.titre}</h5>
                                      <p class="text-muted fw-semibold mb-0">
                                        Client :{' '}
                                        {e.client ? e.client : <>...</>}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {e.statut ? (
                                  e.statut == 'Actif' ? (
                                    <>
                                      <div class="col-auto">
                                        <span class="badge bg-soft-success">
                                          Actif
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  ''
                                )}
                                {e.statut ? (
                                  e.statut == 'Inactif' ? (
                                    <>
                                      <div class="col-auto">
                                        <span class="badge bg-soft-danger">
                                          Inactif
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  ''
                                )}
                                {e.statut ? (
                                  e.statut == 'Terminé' ? (
                                    <>
                                      <div class="col-auto">
                                        <span class="badge bg-soft-danger">
                                          Terminé
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="row">
                                {e.dateDebut ? (
                                  <>
                                    <div class="col-md-6">
                                      <p class="mb-0 ">
                                        Date de début :{' '}
                                        <span class="text-muted">
                                          {e.dateDebut
                                            ? e.dateDebut
                                            : 'Non précisée'}
                                        </span>
                                      </p>
                                      <p class="">
                                        Date de fin :{' '}
                                        <span class="text-muted">
                                          {e.dateFin
                                            ? e.dateFin
                                            : 'Non précisée'}
                                        </span>
                                      </p>
                                    </div>
                                    <div class="col-md-6 text-end align-self-center">
                                      <ul class="list-inline">
                                        <li class="list-inline-item me-0">
                                          <i
                                            class={
                                              e.etoile
                                                ? e.etoile > 0
                                                  ? 'fas fa-star gold'
                                                  : 'fas fa-star'
                                                : 'fas fa-star'
                                            }
                                          ></i>
                                        </li>
                                        <li class="list-inline-item me-0">
                                          <i
                                            class={
                                              e.etoile
                                                ? e.etoile > 1
                                                  ? 'fas fa-star gold'
                                                  : 'fas fa-star'
                                                : 'fas fa-star'
                                            }
                                          ></i>
                                        </li>
                                        <li class="list-inline-item me-0">
                                          <i
                                            class={
                                              e.etoile
                                                ? e.etoile > 2
                                                  ? 'fas fa-star gold'
                                                  : 'fas fa-star'
                                                : 'fas fa-star'
                                            }
                                          ></i>
                                        </li>
                                        <li class="list-inline-item me-0">
                                          <i
                                            class={
                                              e.etoile
                                                ? e.etoile > 3
                                                  ? 'fas fa-star gold'
                                                  : 'fas fa-star'
                                                : 'fas fa-star'
                                            }
                                          ></i>
                                        </li>
                                        <li class="list-inline-item me-0">
                                          <i
                                            class={
                                              e.etoile
                                                ? e.etoile > 4
                                                  ? 'fas fa-star gold'
                                                  : 'fas fa-star'
                                                : 'fas fa-star'
                                            }
                                          ></i>
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="col-md-6">
                                      <p className="mb-4 mt-3">
                                        Aucun planning importé
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                })}
            </div>
          </div>
          <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="Appearance"
            aria-labelledby="AppearanceLabel"
          >
            <div class="offcanvas-header border-bottom">
              <h5 class="m-0 font-14" id="AppearanceLabel">
                Appearance
              </h5>
              <button
                type="button"
                class="btn-close text-reset p-0 m-0 align-self-center"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <h6>Account Settings</h6>
              <div class="p-2 text-start mt-3">
                <div class="form-check form-switch mb-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="settings-switch1"
                  />
                  <label class="form-check-label" for="settings-switch1">
                    Auto updates
                  </label>
                </div>
                <div class="form-check form-switch mb-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="settings-switch2"
                    checked
                  />
                  <label class="form-check-label" for="settings-switch2">
                    Location Permission
                  </label>
                </div>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="settings-switch3"
                  />
                  <label class="form-check-label" for="settings-switch3">
                    Show offline Contacts
                  </label>
                </div>
              </div>
              <h6>General Settings</h6>
              <div class="p-2 text-start mt-3">
                <div class="form-check form-switch mb-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="settings-switch4"
                  />
                  <label class="form-check-label" for="settings-switch4">
                    Show me Online
                  </label>
                </div>
                <div class="form-check form-switch mb-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="settings-switch5"
                    checked
                  />
                  <label class="form-check-label" for="settings-switch5">
                    Status visible to all
                  </label>
                </div>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="settings-switch6"
                  />
                  <label class="form-check-label" for="settings-switch6">
                    Notifications Popup
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Acceuil
