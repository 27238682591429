import axios from "axios"
export const getPresence =  async (token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/presences`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const updateCr =  async (id,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/comptesrendu/${id}`,{
            archive : true
        },config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const updatefiche =  async (id,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/visites/${id}`,{
            archive : true
        },config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAllCR =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/comptesrendu?planning=${planning}&sort=-idCR&limit=1000&archive=false`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAnnexe =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/annexes?planning=${planning}&sort=-idLastCr&limit=1000`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}


export const getAllFiche =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/visites?planning=${planning}&sort=-idVisite&limit=1000&archive=false`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}

export const getAllEntreprise =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/lots/lotProfile?planning=${planning}`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAllObservation =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/ressources/pins/?planning=${planning}&mediaType=pdf`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const saveFiche =  async (planning,fiche,query,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.post(`${process.env.REACT_APP_URL}/api/v1/visites?avecPhoto=${query}`,{visites:fiche,planning:planning},config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}