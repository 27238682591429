import React, { useState, useEffect } from 'react'
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { ReactTabulator } from 'react-tabulator'
import BeatLoader from 'react-spinners/BeatLoader'
import { Button, Upload } from 'antd'
import 'antd/dist/reset.css'
import Presence from './component/Presence'
import LastPlanner2 from '../planning/LastPlanner2'
import { getAllEntreprise, getAnnexe } from '../../../helpers/rapport/rapport'
import ModalDocument from '../suivi/component/ModalDocument'
import { useToasts } from 'react-toast-notifications'
import {
  getAllData,
  updateData,
  addData,
  delData,
  getDataPresence,
  getForfait,
} from './../../../helpers/suivi/suivi'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import PageNotFound from '../../../components/404/PageNotFound'
import {
  getFavori,
  updateFavori,
} from '../../../helpers/planning/favori/favori'
import Modal from '../planning/Favori/Modal'
import Penalite from './component/Penalite'
import { useSelector } from 'react-redux'
import { getISOWeek,getISOWeekYear} from 'date-fns'

//Page pour la création des nouveaus compte rendu
const NewCr = () => {
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const { addToast } = useToasts()
  const [btnPartage, setBtnPartage] = useState(false)
  const bools = useSelector((state) => state.bool.bool)
  const [file, setFile] = useState([])
  const [disabled, setDisabled] = useState(true)
  const navigate = useNavigate()
  const [dataPresence, setDataPresence] = useState([])
  const [administratif, setAdministratif] = useState('')
  const [generalite, setGeneralite] = useState('')
  const [classement, setClassement] = useState([])
  const [entreprise, setEntreprise] = useState([])
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [allCr, setAllCr] = useState([])
  const [allTask, setAllTask] = useState([])
  const [annexe, setAnnexe] = useState([])
  const [dateRendu, setDateRendu] = useState(new Date(Date.now()))
  const [dateProchainReunion, setDateProchainReunion] = useState(
    new Date(dateRendu.getTime() + 7 * 24 * 60 * 60 * 1000)
  )
  const [wait, setWait] = useState(false)
  const [wait2, setWait2] = useState(false)
  const [bool, setBool] = useState(false)
  const [bool1, setBool1] = useState(false)
  const [page, setPage] = useState(1)
  const [nbPage, setNbPage] = useState(0)
  const [dataEngagement, setDataEngagement] = useState([])
  const [dataAdministratif, setDataAdministratif] = useState([])
  const [dataDocument, setDataDocument] = useState([])
  const [dataSecurite, setDataSecurite] = useState([])
  const [dataInstallation, setDataInstallation] = useState([])
  const [dataEffectif, setDataEffectif] = useState([])
  const [dataPlanningSo, setDataPlanningSo] = useState([])
  const [dataModalPlanification, setDataModalPlanification] = useState({})
  const [dataModalEngagement, setDataModalEngagement] = useState({})
  const [dataModalAdministration, setDataModalAdministration] = useState({})
  const [dataModalDocument, setDataModalDocument] = useState({})
  const [dataModalSuiviDoc, setDataModalSuiviDoc] = useState([])
  const [dataModalSecurite, setDataModalSecurite] = useState({})
  const [dataModalInstallation, setDataModalInstallation] = useState({})
  const [dataModalEffectif, setDataModalEffectif] = useState({})
  const [lots, setLots] = useState([])
  const [forfait, setForfait] = useState({})
  const indiceDoc = [
    { value: 'A' },
    { value: 'B' },
    { value: 'C' },
    { value: 'D' },
    { value: 'E' },
    { value: 'F' },
    { value: 'G' },
    { value: 'H' },
    { value: 'I' },
    { value: 'J' },
    { value: 'K' },
    { value: 'L' },
    { value: 'M' },
    { value: 'N' },
    { value: 'O' },
    { value: 'P' },
    { value: 'Q' },
    { value: 'R' },
    { value: 'S' },
    { value: 'T' },
    { value: 'U' },
    { value: 'V' },
    { value: 'W' },
    { value: 'X' },
    { value: 'Y' },
    { value: 'Z' },
  ]

  //Recuperation de tous les informations concernant les lots
  useEffect(() => {
    getForfait(infoChantier.planning, infoUser.token).then((e) => {
      setForfait(e.data[0])
    })
  }, [])
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((e) => {
      console.log(e)
      setLots(e.data)
      setWait(false)
    })
  }, [])
  //Recuperation presence et tous les informations d'entreprises
  useEffect(() => {
    getAllEntreprise(infoChantier.planning, infoUser.token).then((e) => {
      console.log(e.data)
      setClassement(e.data)
      setWait(false)
    })
  }, [bool])
  useEffect(()=>{
     axios.get(`${process.env.REACT_APP_URL}/api/v1/crDocs?planning=${infoChantier.planning}`,{
      headers: {
        Authorization: `Bearer ${infoUser.token}`
      }
    }).then((res)=>{
      console.log(res);
      if(res.data.data){
        setAdministratif(res.data.data[0]?.administratif)
        setGeneralite(res.data.data[0]?.generalite)
      }
     }).catch((err)=>{
      console.log(err);
     })
  },[])
  useEffect(() => {
    setDisabled(true)
    setWait(true)
    setWait2(true)
    getAnnexe(infoChantier.planning, infoUser.token).then((e) => {
      setAllCr(e.data)
    })
    getDataPresence(infoChantier.planning, infoUser.token).then((e) => {
      console.log('nouveau data presence')
      console.log(e)
      /* getnew table */
      const contacts = e.responsables.reduce((accumulator, responsable) => {
        //console.log(responsable);
        const user = {}
        user._id = responsable._id ? responsable._id : '---'
        user.name = responsable.name ? responsable.name : 'Non défini'
        user.lastname = responsable.lastname
          ? responsable.lastname
          : 'Non défini'
        user.email = responsable.email ? responsable.email : 'Non défini'
        user.telephone = responsable.telephone
          ? responsable.telephone
          : 'Non défini'
        user.present = true
        user.lot = responsable.lot ? responsable.lot : ''
        user.retard = false
        user.absent = false
        user.convoque =
          responsable.role.roleType === 'ent'
            ? responsable.statut
              ? responsable.statut.convoque
              : true
            : responsable.statut
            ? responsable.statut.convoque
            : false
        user.diffusionPresence = responsable.statut
          ? responsable.statut.diffusionPresence
          : true
        user.intervenant = responsable._id ? responsable._id : 'Non défini'
        user.role = responsable.role.roleType
          ? responsable.role.roleType
          : 'Non défini'
        user.entreprise = responsable.entrepriseName
          ? responsable.entrepriseName
          : responsable.entreprise
          ? responsable.entreprise
          : 'Non défini'
        const exist = accumulator.find((res) => res._id === responsable._id)
        if (!exist) {
          accumulator.push(user)
        }
        return accumulator
      }, [])
      console.log(contacts)
      setDataPresence(contacts)
      setDisabled(false)
      setWait(false)
      setWait2(false)
    })
  }, [])
  useEffect(() => {
    getFavori(infoChantier.planning, page, infoUser.token).then((e) => {
      setNbPage((e.length / 10).toFixed(0))
      setAllTask(e.data)
    })
  }, [bool1, page, bools])
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((e) => {
      setEntreprise(e.data)
    })
  }, [bool])
  //Create date picker
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('blur', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  const inputUrgence = (cell) => {
    console.log(cell.getData().urgence)
    const option1 = document.createElement('option')
    option1.value = 1
    option1.innerText = 'Faible'
    if (option1.value == cell.getValue()) {
      option1.selected = true
    }
    const option2 = document.createElement('option')
    option2.value = 2
    option2.innerText = 'Important'
    if (option2.value == cell.getValue()) {
      option2.selected = true
    }
    const option3 = document.createElement('option')
    option3.value = 3
    option3.innerText = 'Primordial'
    if (option3.value == cell.getValue()) {
      option3.selected = true
    }
    const defaults = document.createElement('option')
    defaults.selected = true
    defaults.innerText = cell.getValue() ? cell.getValue() : 0
    defaults.value = cell.getValue() ? cell.getValue() : 0
    const view = document.createElement('select')
    view.className = 'form-select'
    view.appendChild(option1)
    view.appendChild(option2)
    view.appendChild(option3)
    view.addEventListener('blur', function (e) {
      console.log(e.target.value)
      cell.setValue(e.target.value)
    })
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const checkbox = (cell) => {
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }

  //formatter de date en fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }
  //del Function
  const pop = (candidate, data) => {
    return data.filter((d) => d._id !== candidate._id)
  }

  //sauvegarde compte rendu
  const handleSubmit = () => {
    const corps = document.createElement('div')
    corps.style =
      'width:1300px;position:relative;z-index:222222222222222222222;background-color:white'
    const navPlanner = document.querySelector('.dhx_cal_navline')
    const headPlanner = document.querySelector('.dhx_cal_header')
    const dataPlanner = document.querySelector('.dhx_timeline_table_wrapper')
    dataPlanner.style =
      'position:absolute;top:110px;left:0;width:140%;background-color:white'
    corps.appendChild(navPlanner)
    corps.appendChild(headPlanner)
    corps.appendChild(dataPlanner)
    console.log(corps.innerHTML)
    const formData = new FormData()
    formData.append('planningGlissantHtml', corps.innerHTML)
    formData.append('administratif', administratif)
    formData.append('generalite', generalite)
    formData.append('planning', infoChantier.planning)
    formData.append('presence', JSON.stringify(dataPresence))
    formData.append('annexes', JSON.stringify(annexe))
    formData.append('dateCr', dateRendu)
    formData.append('dateProchainCr', dateProchainReunion)
    formData.append('withPg', btnPartage)
    file.map((e) => {
      formData.append('annexe', e.originFileObj)
    })

    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/comptesrendu/getCr`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((e) => {
        addToast('Le compte rendu a été créé avec succès.', {
          appearance: 'success',
          autoDismiss: false,
        })
        setWait(false)
        setWait2(false)
        navigate('/Rapport_CR')
      })
      .catch((e) => {
        console.log(e)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: false,
        })
      })
  }

  //Modification de la partie suivi
  const planningEdited = async (cell) => {
    console.log('ato')
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataPlanningSo(
          dataPlanningSo.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `planningSo`, infoUser.token)
      } else {
        data.statut = true
        setDataPlanningSo(
          dataPlanningSo.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `planningSo`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataPlanningSo(
              dataPlanningSo.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `planningSo`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataPlanningSo(
            dataPlanningSo.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `planningSo`, infoUser.token)
        }
      } else {
        updateData(data, `planningSo`, infoUser.token)
      }
    }
  }

  const engagementEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataEngagement(
          dataEngagement.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `engagements`, infoUser.token)
      } else {
        data.statut = true
        setDataEngagement(
          dataEngagement.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `engagements`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataEngagement(
              dataEngagement.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `engagements`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataEngagement(
            dataEngagement.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `engagements`, infoUser.token)
        }
      } else {
        updateData(data, `engagements`, infoUser.token)
      }
    }
  }

  const administratifEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataAdministratif(
          dataAdministratif.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `administratifs`, infoUser.token)
      } else {
        data.statut = true
        setDataAdministratif(
          dataAdministratif.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `administratifs`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataAdministratif(
              dataAdministratif.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `administratifs`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataAdministratif(
            dataAdministratif.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `administratifs`, infoUser.token)
        }
      } else {
        updateData(data, 'administratifs', infoUser.token)
      }
    }
  }
  const documentEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.diffusion == '') |
        (data.diffusion == undefined) |
        (data.diffusion == null)
      ) {
        data.statut = false
        setDataDocument(
          dataDocument.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `documents`, infoUser.token)
      } else {
        data.statut = true
        setDataDocument(
          dataDocument.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `documents`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.diffusion == '') |
            (data.diffusion == undefined) |
            (data.diffusion == null)
          ) {
            data.diffusion = new Date(Date.now()).toISOString()
            setDataDocument(
              dataDocument.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `documents`, infoUser.token)
          }
        } else {
          data.diffusion = ''
          setDataDocument(
            dataDocument.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `documents`, infoUser.token)
        }
      } else {
        updateData(data, `documents`, infoUser.token)
      }
    }
  }
  const securiteEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataSecurite(
          dataSecurite.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `securites`, infoUser.token)
      } else {
        data.statut = true
        setDataSecurite(
          dataSecurite.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `securites`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataSecurite(
              dataSecurite.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `securites`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataSecurite(
            dataSecurite.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `securites`, infoUser.token)
        }
      } else {
        updateData(data, `securites`, infoUser.token)
      }
    }
  }
  const installationEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataInstallation(
          dataInstallation.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `installationchantiers`, infoUser.token)
      } else {
        data.statut = true
        setDataInstallation(
          dataInstallation.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `installationchantiers`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataInstallation(
              dataInstallation.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `installationchantiers`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataInstallation(
            dataInstallation.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `installationchantiers`, infoUser.token)
        }
      } else {
        updateData(data, `installationchantiers`, infoUser.token)
      }
    }
  }
  const effectifEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    updateData(data, `effectifs`, infoUser.token).then((e) => {
      console.log(e)
    })
  }

  //Ajout partie suivi
  const addPlanning = async (id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        acteLe: Date.now(),
        entreprise: id,
        planning: infoChantier.planning,
      },
      'planningSo',
      lot,
      infoUser.token
    ).then((e) => {
      setBool(!bool)
      setDataPlanningSo([e, ...dataPlanningSo])
    })
  }

  const addEngagementRow = async (id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: id,
        planning: infoChantier.planning,
      },
      'engagements',
      lot,
      infoUser.token
    ).then((e) => {
      setBool(!bool)
      setDataEngagement([e, ...dataEngagement])
    })
  }
  const addAdministratifRow = async (_id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'administratifs',
      lot,
      infoUser.token
    ).then((e) => {
      setBool(!bool)
      setDataAdministratif([e, ...dataAdministratif])
    })
  }
  const addDocumentRow = async (_id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'documents',
      lot,
      infoUser.token
    ).then((e) => {
      if (e.name == 'AxiosError' || e.message == 'erreur') {
        setWait(false)
        alert("Une erreur s'est produit")
      } else {
        setBool(!bool)
        setDataDocument([e, ...dataDocument])
      }
    })
  }
  const addSecuriteRow = async (_id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'securites',
      lot,
      infoUser.token
    ).then((e) => {
      console.log(e)
      setBool(!bool)
      setDataSecurite([e, ...dataSecurite])
    })
  }
  const addInstallationRow = async (_id, lot) => {
    setWait(true)
    addData(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'installationchantiers',
      lot,
      infoUser.token
    ).then((e) => {
      setBool(!bool)
      setDataInstallation([e, ...dataInstallation])
    })
  }
  const addEffectifRow = async (_id, lot) => {
    setWait(true)
    const result = await addData(
      {
        formattedSemaine: `${getISOWeekYear(Date.now())}-W${getISOWeek(
          new Date()
        )}`,
        noteLe: Date.now(),
        prevu: 0,
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'effectifs',
      lot,
      infoUser.token
    ).then((e) => {
      console.log(e)
      setBool(!bool)
      return e
    })
    setDataEffectif([{ ...result }, ...dataEffectif])
  }

  //suppression partie suivi
  const delPlanning = async (cell) => {
    setWait(true)
    try {
      await delData(cell.getData(), 'planningSo', infoUser.token).then((e) =>
        setBool(!bool)
      )
    } catch (err) {
      alert('le serveur ne répond pas')
    }
    setDataPlanningSo([...pop(cell.getData(), dataPlanningSo)])
    //cell.getRow().delete()
  }

  const delEngagements = async (cell) => {
    setWait(true)
    try {
      await delData(cell.getData(), 'engagements', infoUser.token).then((e) => {
        setBool(!bool)
      })
    } catch (err) {
      alert('le serveur ne répond pas')
    }
    setDataEngagement([...pop(cell.getData(), dataEngagement)])
  }

  const delAdministratifs = async (cell) => {
    setWait(true)
    try {
      await delData(cell.getData(), 'administratifs', infoUser.token).then(
        (e) => {
          setBool(!bool)
        }
      )
    } catch (err) {
      alert('le serveur ne répond pas')
    }
    setDataAdministratif([...pop(cell.getData(), dataAdministratif)])
  }

  const delDocuments = async (cell) => {
    setWait(true)
    try {
      await delData(cell.getData(), 'documents', infoUser.token).then((e) =>
        setBool(!bool)
      )
    } catch (err) {
      alert('le serveur ne répond pas')
    }
    setDataDocument([...pop(cell.getData(), dataDocument)])
  }

  const delSecurites = async (cell) => {
    setWait(true)
    try {
      await delData(cell.getData(), 'securites', infoUser.token).then((e) =>
        setBool(!bool)
      )
    } catch (err) {
      alert('le serveur ne répond pas')
    }
    setDataSecurite([...pop(cell.getData(), dataSecurite)])
  }
  const delInstallationChantier = async (cell) => {
    setWait(true)
    try {
      await delData(
        cell.getData(),
        'installationchantiers',
        infoUser.token
      ).then((e) => setBool(!bool))
    } catch (err) {
      alert('le serveur ne répond pas')
    }
    setDataInstallation([...pop(cell.getData(), dataInstallation)])
  }
  const delEffectif = async (cell) => {
    setWait(true)
    try {
      await delData(cell.getData(), 'effectifs', infoUser.token).then((e) =>
        setBool(!bool)
      )
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataEffectif([...pop(cell.getData(), dataEffectif)])
    //cell.getRow().delete()
  }

  //input semaine
  const semaine = (cell) => {
    const data = cell.getData()
    console.log(data)
    const div = document.createElement('div')
    const week = document.createElement('input')
    week.classList = 'form-control'
    week.type = 'week'
    week.defaultValue = data.formattedSemaine ? data.formattedSemaine : ''
    week.addEventListener('change', function (e) {
      console.log(e.target.value)
      updateData(
        { ...data, formattedSemaine: e.target.value },
        `effectifs`,
        infoUser.token
      ).then((e) => {
        console.log(e)
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    div.appendChild(week)
    return div
  }
  //Effectif pers
  const pers = (cell) => {
    const data = cell.getData()
    const div = document.createElement('div')
    div.style = 'display:flex;align-items:center'
    const inputPers = document.createElement('input')
    inputPers.type = 'number'
    inputPers.className = 'form-control'
    inputPers.style = 'width:70px;text-align:rigth'
    inputPers.defaultValue = data.prevu ? data.prevu : 0
    inputPers.addEventListener('change', function (e) {
      updateData(
        { ...data, prevu: e.target.value },
        `effectifs`,
        infoUser.token
      ).then((e) => {
        console.log(e)
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    const unite = document.createElement('sapan')
    unite.innerText = 'per(s)'
    div.appendChild(inputPers)
    div.appendChild(unite)
    return div
  }
  //Effectif pers
  const pers2 = (cell) => {
    const data = cell.getData()
    const div = document.createElement('div')
    div.style = 'display:flex;align-items:center'
    const inputPers = document.createElement('input')
    inputPers.type = 'number'
    inputPers.className = 'form-control'
    inputPers.style = 'width:70px;text-align:rigth'
    inputPers.defaultValue = data.reel?.present ? data.reel.present : 0
    inputPers.addEventListener('change', function (e) {
      console.log(data)
      updateData(
        { ...data, reel: { ...data.reel, present: e.target.value } },
        `effectifs`,
        infoUser.token
      ).then((e) => {
        console.log(e)
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    const unite = document.createElement('sapan')
    unite.innerHTML = `<span>per(s) <span style='color:${
      data.reel?.color ? data.reel.color : 'blue'
    };font-weight:bold'>(${data.reel?.diff ? data.reel.diff : 0})</span> </span>`
    div.appendChild(inputPers)
    div.appendChild(unite)
    return div
  }

  //format boutton
  const button = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-trash')

    //create delete button
    var delBt = document.createElement('button')
    delBt.type = 'button'
    delBt.appendChild(delIcon)
    delBt.classList.add('btn')
    delBt.classList.add('btn-outline-danger')
    delBt.addEventListener('click', function () {
      delFunction(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)

    return buttonHolder
  }
  //Modal document
  const modalDocument = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalDoc')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalDocument(cell.getData())
      setDataModalSuiviDoc(
        cell.getData().suiviDocs ? cell.getData().suiviDocs : []
      )
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }
  //Modal Plannification
  const modalPlannification = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalPlannification')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalPlanification(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Engagement
  const modalEngagement = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEngagement')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalEngagement(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Administratif
  const modalAdministratif = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAdministratif')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalAdministration(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Securite
  const modalSecurite = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalSecurite')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalSecurite(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Installation
  const modalInstallation = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalInstallation')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalInstallation(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Effectif
  const modalEffectif = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEffectif')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalEffectif(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }

  const colonneEngagement = [
    { title: 'Engagements', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Acté Le', field: 'engageLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalEngagement() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEngagements),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneAdministratif = [
    { title: 'Titre', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalAdministratif() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delAdministratifs),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneDocument = [
    { title: 'Titre', field: 'titre', editor: 'input' },
    { title: 'Phase', field: 'phase', editor: 'input' },
    { title: 'Type', field: 'type', editor: 'input' },
    { title: 'Niveau', field: 'niveau', editor: 'input' },
    { title: 'Zone', field: 'zone', editor: 'input' },
    { title: 'Indice', field: 'indice' },
    { title: 'Date BPE', field: 'dateBPE', formatter: date },
    { title: 'Prévu Le', field: 'prevision', formatter: date },
    { title: 'Diffusé Le', field: 'diffusion', formatter: date },
    { title: 'Visa', field: 'visa' },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'functionButton',
      formatter: modalDocument(),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delDocuments),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonnePlanning = [
    { title: 'Tâches', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Acté Le', field: 'acteLe', formatter: date },
    { title: 'Planifié Le', field: 'prevuLe', formatter: date },
    { title: 'Réalisé Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalPlannification() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delPlanning),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneSecurite = [
    { title: 'Remarques', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalSecurite() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delSecurites),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneInstallation = [
    { title: 'Remarques', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalInstallation() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delInstallationChantier),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneEffectif = [
    { title: 'Semaine(s)', field: '', formatter: semaine },
    { title: 'Noté le', field: 'noteLe', formatter: date },
    { title: 'Prévu', field: 'prevu', formatter: pers },
    { title: 'Réel (delta)', field: 'reel.present', formatter: pers2 },
    { title: 'Details', width: 100, formatter: modalEffectif() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEffectif),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fuild">
            <div class="page-title-box">
              <div class="float-end">
                <button
                  className="btn btn-dark"
                  onClick={(e) => {
                    navigate('/Rapport_CR')
                  }}
                >
                  Retour
                </button>
              </div>
              <h4 class="page-title">Rapport</h4>
            </div>
          </div>
          {/* wizard pour compte rendu et fiche de visite */}
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <div className="d-flex" style={{ alignItems: 'center' }}>
                    <div>
                      <h4>Nouveau compte rendu</h4>
                    </div>
                    <div
                      class="form-floating"
                      style={{ width: '200px', marginLeft: '1vw' }}
                    >
                      <input
                        type="date"
                        class="form-control"
                        id="floatingInput"
                        defaultValue={dateRendu.toISOString().substring(0, 10)}
                        onChange={(e) => {
                          setDateRendu(new Date(e.target.value))
                        }}
                      />
                      <label for="floatingInput">Date du compte rendu</label>
                    </div>
                    <div
                      class="form-floating"
                      style={{ width: '200px', marginLeft: '1vw' }}
                    >
                      <input
                        type="date"
                        class="form-control"
                        id="floatingInput"
                        defaultValue={dateProchainReunion
                          .toISOString()
                          .substring(0, 10)}
                        onChange={(e) => {
                          setDateProchainReunion(new Date(e.target.value))
                        }}
                      />
                      <label for="floatingInput">
                        Date du prochain réunion
                      </label>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form action="" method="post" id="custom-step">
                    <nav>
                      <div class="nav nav-tabs" id="nav-tab">
                        <a
                          class="nav-link active"
                          id="step1-tab"
                          data-bs-toggle="tab"
                          href="#step1"
                        >
                          Présence
                        </a>
                        <a
                          class="nav-link"
                          id="step2-tab"
                          data-bs-toggle="tab"
                          href="#step2"
                        >
                          Administratif et Généralité
                        </a>
                        <a
                          class="nav-link"
                          id="step4-tab"
                          data-bs-toggle="tab"
                          href="#step4"
                        >
                          Suivi Planning
                        </a>
                        <a
                          class="nav-link"
                          id="step3-tab"
                          data-bs-toggle="tab"
                          href="#step3"
                        >
                          Suivi Opérationnel
                        </a>
                        <a
                          class="nav-link"
                          id="step5-tab"
                          data-bs-toggle="tab"
                          href="#step5"
                        >
                          Pénalités
                        </a>
                        <a
                          class="nav-link"
                          id="step6-tab"
                          data-bs-toggle="tab"
                          href="#step6"
                        >
                          Annexes
                        </a>
                      </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                      <div class="tab-pane active" id="step1">
                        <div className="container-fluid">
                          {dataPresence.length > 0 && (
                            <Presence
                              data={dataPresence}
                              updateData={setDataPresence}
                            />
                          )}
                        </div>
                      </div>
                      <div class="tab-pane " id="step2">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-3">
                              <label for="message">Administratifs</label>
                              <textarea
                                class="form-control"
                                rows="5"
                                id="message"
                                defaultValue={administratif}
                                onChange={(e) => {
                                  setAdministratif(e.target.value)
                                }}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-3">
                              <label for="message">Généralités</label>
                              <textarea
                                class="form-control"
                                defaultValue={generalite}
                                onChange={(e) => {
                                  e.preventDefault(
                                    setGeneralite(e.target.value)
                                  )
                                }}
                                rows="5"
                                id="message"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="step3">
                        {/* wizard 2 */}
                        {/* wizard pour compte rendu et fiche de visite */}
                        <div class="row">
                          <div class="col-12">
                            <div class="card">
                              <div class="card-header">
                                <h6 class="card-title">Liste des Lots</h6>
                              </div>
                              <div class="card-body">
                                <form action="" method="post" id="custom-step">
                                  <nav>
                                    <div class="nav nav-tabs" id="nav-tab">
                                      {classement &&
                                        classement.map((e) => {
                                          return (
                                            <>
                                              <a
                                                class="nav-link"
                                                id={`step11-tab${e._id}`}
                                                data-bs-toggle="tab"
                                                href={`#step11${e._id}`}
                                                onClick={(ev) => {
                                                  console.log(e);
                                                  if (e.profile.planningSo) {
                                                    setDataPlanningSo(
                                                      e.profile.planningSo
                                                    )
                                                  }
                                                  if (e.profile.engagements) {
                                                    console.log(
                                                      e.profile.engagements
                                                    )
                                                    setDataEngagement(
                                                      e.profile.engagements
                                                    )
                                                  }
                                                  if (
                                                    e.profile.administratifs
                                                  ) {
                                                    setDataAdministratif(
                                                      e.profile.administratifs
                                                    )
                                                  }
                                                  if (e.profile.documents) {
                                                    setDataDocument(
                                                      e.profile.documents
                                                    )
                                                  }
                                                  if (e.profile.securites) {
                                                    setDataSecurite(
                                                      e.profile.securites
                                                    )
                                                  }
                                                  if (
                                                    e.profile
                                                      .installationChantier
                                                  ) {
                                                    setDataInstallation(
                                                      e.profile
                                                        .installationChantier
                                                    )
                                                  }
                                                  if (e.profile.effectifs) {
                                                    setDataEffectif(
                                                      e.profile.effectifs
                                                    )
                                                  }
                                                  setBool(!bool)
                                                }}
                                              >
                                                {e.titre}
                                              </a>
                                            </>
                                          )
                                        })}
                                    </div>
                                  </nav>
                                  <div class="tab-content" id="nav-tabContent">
                                    {classement &&
                                      classement.map((e) => {
                                        return (
                                          <>
                                            <div
                                              class="tab-pane"
                                              id={`step11${e._id}`}
                                            >
                                              <div id="grid" className="row">
                                                <div className="container-fluid mt-4">
                                                  <h5>
                                                    {' '}
                                                    <u>Planifications:</u>{' '}
                                                  </h5>
                                                  <ReactTabulator
                                                    data={
                                                      dataPlanningSo
                                                        ? dataPlanningSo
                                                        : []
                                                    }
                                                    columns={colonnePlanning}
                                                    height={'200px'}
                                                    layout={'fitColumns'}
                                                    events={{
                                                      cellEdited:
                                                        planningEdited,
                                                    }}
                                                    style={{
                                                      border:
                                                        'solid lightgray 1px',
                                                    }}
                                                  />
                                                  <div className="d-flex flex-row-reverse">
                                                    <button
                                                      type="button"
                                                      class="btn btn-sm btn-primary"
                                                      onClick={(el) => {
                                                        el.preventDefault()
                                                        addPlanning(
                                                          e.entreprise,
                                                          e._id
                                                        )
                                                      }}
                                                    >
                                                      <i class="ti ti-plus"></i>{' '}
                                                      Ajouter
                                                    </button>
                                                  </div>
                                                </div>

                                                <div className="container-fluid mt-4">
                                                  <h5>
                                                    {' '}
                                                    <u>Engagements:</u>{' '}
                                                  </h5>
                                                  <ReactTabulator
                                                    data={
                                                      dataEngagement
                                                        ? dataEngagement
                                                        : []
                                                    }
                                                    columns={colonneEngagement}
                                                    height={'200px'}
                                                    layout={'fitColumns'}
                                                    events={{
                                                      cellEdited:
                                                        engagementEdited,
                                                    }}
                                                    style={{
                                                      border:
                                                        'solid lightgray 1px',
                                                    }}
                                                  />
                                                  <div className="d-flex flex-row-reverse">
                                                    <button
                                                      type="button"
                                                      class="btn btn-sm btn-primary"
                                                      onClick={(el) => {
                                                        el.preventDefault()
                                                        addEngagementRow(
                                                          e.entreprise,
                                                          e._id
                                                        )
                                                      }}
                                                    >
                                                      <i class="ti ti-plus"></i>{' '}
                                                      Ajouter
                                                    </button>
                                                  </div>
                                                </div>

                                                <div className="container-fluid mt-2">
                                                  <h5>
                                                    {' '}
                                                    <u>Administratifs:</u>{' '}
                                                  </h5>
                                                  <ReactTabulator
                                                    data={
                                                      dataAdministratif
                                                        ? dataAdministratif
                                                        : []
                                                    }
                                                    columns={
                                                      colonneAdministratif
                                                    }
                                                    height={'200px'}
                                                    layout={'fitColumns'}
                                                    events={{
                                                      cellEdited:
                                                        administratifEdited,
                                                    }}
                                                    style={{
                                                      border:
                                                        'solid lightgray 1px',
                                                    }}
                                                  />
                                                  <div className="d-flex flex-row-reverse">
                                                    <button
                                                      type="button"
                                                      class="btn btn-sm btn-primary"
                                                      onClick={(el) => {
                                                        el.preventDefault()
                                                        addAdministratifRow(
                                                          e.entreprise,
                                                          e._id
                                                        )
                                                      }}
                                                    >
                                                      <i class="ti ti-plus"></i>{' '}
                                                      Ajouter
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="container-fluid mt-2">
                                                  <h5>
                                                    {' '}
                                                    <u>Documents:</u>{' '}
                                                  </h5>
                                                  <ReactTabulator
                                                    data={
                                                      dataDocument
                                                        ? dataDocument
                                                        : []
                                                    }
                                                    columns={colonneDocument}
                                                    height={'200px'}
                                                    layout={'fitColumns'}
                                                    events={{
                                                      cellEdited:
                                                        documentEdited,
                                                    }}
                                                    style={{
                                                      border:
                                                        'solid lightgray 1px',
                                                    }}
                                                  />
                                                  <div className="d-flex flex-row-reverse">
                                                    <button
                                                      type="button"
                                                      class="btn btn-sm btn-primary"
                                                      onClick={(el) => {
                                                        el.preventDefault()
                                                        addDocumentRow(
                                                          e.entreprise,
                                                          e._id
                                                        )
                                                      }}
                                                    >
                                                      <i class="ti ti-plus"></i>{' '}
                                                      Ajouter
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="container-fluid mt-2">
                                                  <h5>
                                                    {' '}
                                                    <u>Sécurités:</u>{' '}
                                                  </h5>
                                                  <ReactTabulator
                                                    data={
                                                      dataSecurite
                                                        ? dataSecurite
                                                        : []
                                                    }
                                                    columns={colonneSecurite}
                                                    height={'200px'}
                                                    layout={'fitColumns'}
                                                    events={{
                                                      cellEdited:
                                                        securiteEdited,
                                                    }}
                                                    style={{
                                                      border:
                                                        'solid lightgray 1px',
                                                    }}
                                                  />
                                                  <div className="d-flex flex-row-reverse">
                                                    <button
                                                      type="button"
                                                      class="btn btn-sm btn-primary"
                                                      onClick={(el) => {
                                                        el.preventDefault()
                                                        addSecuriteRow(
                                                          e.entreprise,
                                                          e._id
                                                        )
                                                      }}
                                                    >
                                                      <i class="ti ti-plus"></i>{' '}
                                                      Ajouter
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="container-fluid mt-2">
                                                  <h5>
                                                    {' '}
                                                    <u>
                                                      Installation chantier:
                                                    </u>{' '}
                                                  </h5>
                                                  <ReactTabulator
                                                    data={
                                                      dataInstallation
                                                        ? dataInstallation
                                                        : []
                                                    }
                                                    columns={
                                                      colonneInstallation
                                                    }
                                                    height={'200px'}
                                                    layout={'fitColumns'}
                                                    events={{
                                                      cellEdited:
                                                        installationEdited,
                                                    }}
                                                    style={{
                                                      border:
                                                        'solid lightgray 1px',
                                                    }}
                                                  />
                                                  <div className="d-flex flex-row-reverse">
                                                    <button
                                                      type="button"
                                                      class="btn btn-sm btn-primary"
                                                      onClick={(el) => {
                                                        el.preventDefault()
                                                        addInstallationRow(
                                                          e.entreprise,
                                                          e._id
                                                        )
                                                      }}
                                                    >
                                                      <i class="ti ti-plus"></i>{' '}
                                                      Ajouter
                                                    </button>
                                                  </div>
                                                </div>
                                             
                                                    <div className="container-fluid mt-2">
                                                  <h5>
                                                    {' '}
                                                    <u>Effectifs:</u>{' '}
                                                  </h5>
                                                  <ReactTabulator
                                                    data={
                                                      dataEffectif
                                                        ? dataEffectif
                                                        : []
                                                    }
                                                    columns={colonneEffectif}
                                                    height={'200px'}
                                                    layout={'fitColumns'}
                                                    events={{
                                                      cellEdited:
                                                        effectifEdited,
                                                    }}
                                                    style={{
                                                      border:
                                                        'solid lightgray 1px',
                                                    }}
                                                  />
                                                  <div className="d-flex flex-row-reverse">
                                                    <button
                                                      type="button"
                                                      class="btn btn-sm btn-primary"
                                                      onClick={(el) => {
                                                        el.preventDefault()
                                                        addEffectifRow(
                                                          e.entreprise,
                                                          e._id
                                                        )
                                                      }}
                                                    >
                                                      <i class="ti ti-plus"></i>{' '}
                                                      Ajouter
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      })}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* fin wizard */}
                        {/* fin wizard 2 */}
                      </div>
                      <div class="tab-pane " id="step4">
                        {/* wizard pour compte rendu et fiche de visite */}
                        <div class="row">
                          <div class="col-12">
                            <div class="card">
                              <div class="card-body">
                                <form id="custom-step">
                                  <nav>
                                    <div class="nav nav-tabs" id="nav-tab">
                                      <a
                                        className="nav-link"
                                        id="step22-tab21"
                                        data-bs-toggle="tab"
                                        href="#step2221"
                                      >
                                        Pour mémoire
                                      </a>
                                      <a
                                        className="nav-link"
                                        id="step22-tab22"
                                        data-bs-toggle="tab"
                                        href="#step2222"
                                      >
                                        Planning à 3 semaines
                                      </a>
                                    </div>
                                  </nav>
                                  <div class="tab-content" id="nav-tabContent">
                                    <div className="tab-pane" id="step2221">
                                      <div className="container-fluid">
                                        <table
                                          class="table table-striped"
                                          style={{ marginTop: '1vw' }}
                                        >
                                          <thead className="thead-light">
                                            <tr>
                                              <th>P.M.</th>
                                              <th>Tâches</th>
                                              <th>Date de début</th>
                                              <th>Date de fin</th>
                                              <th>Avancement</th>
                                              <th>Details</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {allTask &&
                                              allTask.map((e) => {
                                                if (e.isCritical) {
                                                  return (
                                                    <>
                                                      <tr>
                                                        <td>
                                                          <div class="form-check form-switch form-switch-success">
                                                            <input
                                                              class="form-check-input"
                                                              type="checkbox"
                                                              checked
                                                              onChange={(
                                                                el
                                                              ) => {
                                                                updateFavori(
                                                                  el.target
                                                                    .checked,
                                                                  e._id
                                                                ).then((e) => {
                                                                  setBool1(
                                                                    !bool1
                                                                  )
                                                                })
                                                                el.target.checked = false
                                                              }}
                                                            />
                                                            <label
                                                              class="form-check-label"
                                                              for="customSwitchSuccess"
                                                            ></label>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          {e.text ? (
                                                            e.text
                                                          ) : (
                                                            <>...</>
                                                          )}
                                                        </td>
                                                        <td>
                                                          {e.start_date ? (
                                                            dateFr(e.start_date)
                                                          ) : (
                                                            <>...</>
                                                          )}
                                                        </td>
                                                        <td>
                                                          {e.end_date ? (
                                                            dateFr(e.end_date)
                                                          ) : (
                                                            <>...</>
                                                          )}
                                                        </td>
                                                        <td>
                                                          <div class="progress">
                                                            <div
                                                              class="progress-bar"
                                                              role="progressbar"
                                                              style={{
                                                                width: `${
                                                                  e.progress *
                                                                  100
                                                                }%`,
                                                              }}
                                                              aria-valuenow="10"
                                                              aria-valuemin="0"
                                                              aria-valuemax="100"
                                                            >
                                                              {' '}
                                                              {`${(
                                                                e.progress * 100
                                                              ).toFixed(
                                                                2
                                                              )}%`}{' '}
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <i
                                                            class="ti ti-circle-plus"
                                                            style={{
                                                              fontSize: '30px',
                                                            }}
                                                            data-bs-toggle="modal"
                                                            data-bs-target={`#favori${e._id}`}
                                                          ></i>
                                                        </td>
                                                      </tr>
                                                      <Modal
                                                        id={`favori${e._id}`}
                                                        data={e}
                                                        lot={entreprise}
                                                        curentLot={e.lot}
                                                      />
                                                    </>
                                                  )
                                                }
                                              })}
                                          </tbody>
                                        </table>
                                        <div className="container-fluid">
                                          <nav aria-label="Page navigation example">
                                            <ul class="pagination justify-content-end align-items-center">
                                              <span
                                                style={{ marginRight: '5px' }}
                                              >
                                                il y a {nbPage} page(s)
                                              </span>
                                              {page > 1 ? (
                                                <>
                                                  <li
                                                    class={
                                                      page == 1
                                                        ? 'page-item disabled'
                                                        : 'page-item'
                                                    }
                                                    style={{
                                                      marginLeft: '5px',
                                                      cursor: 'pointer',
                                                    }}
                                                    onClick={(e) => {
                                                      if (page > 1) {
                                                        setPage(page - 1)
                                                      }
                                                    }}
                                                  >
                                                    <span class="page-link">
                                                      Précédent
                                                    </span>
                                                  </li>
                                                  <li className="page-link">
                                                    <a
                                                      href="#"
                                                      className="page-ink"
                                                      onClick={(e) => {
                                                        setPage(1)
                                                      }}
                                                    >
                                                      1
                                                    </a>
                                                  </li>
                                                </>
                                              ) : (
                                                ''
                                              )}
                                              <li class="page-item active">
                                                <a class="page-link" href="#">
                                                  {page}
                                                </a>
                                              </li>
                                              {page < nbPage ? (
                                                <>
                                                  <li className="page-link">
                                                    <a
                                                      href="#"
                                                      className="page-ink"
                                                      onClick={(e) => {
                                                        setPage(nbPage)
                                                      }}
                                                    >
                                                      {nbPage}
                                                    </a>
                                                  </li>
                                                  <li
                                                    class="page-item"
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                    onClick={(e) => {
                                                      if (page < nbPage) {
                                                        setPage(page + 1)
                                                      }
                                                    }}
                                                  >
                                                    <span class="page-link">
                                                      Suivant
                                                    </span>
                                                  </li>
                                                </>
                                              ) : (
                                                ''
                                              )}
                                            </ul>
                                          </nav>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="tab-pane" id="step2222">
                                      <div className="container-fluid">
                                        <LastPlanner2
                                          btnPartage={true}
                                          updateBtnPartage={setBtnPartage}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* fin wizard */}
                      </div>
                      <div class="tab-pane" id="step5">
                        {/* wizard pour compte rendu et fiche de visite */}
                        <div class="row">
                          <div class="col-12">
                            <Penalite
                              entreprise={lots}
                              forfait={forfait}
                              setForfait={setForfait}
                            />
                          </div>
                        </div>
                        {/* fin wizard */}
                      </div>
                      <div class="tab-pane" id="step6">
                        <div className="d-flex flex-row-reverse">
                          <div className="mt-2">
                            <button
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault()
                                setDisabled(true)
                                setWait(true)
                                handleSubmit()
                              }}
                              disabled={disabled}
                            >
                              Valider
                            </button>
                          </div>
                        </div>
                        <div className="container-fluid mt-2">
                          <Upload.Dragger
                            multiple={true}
                            maxCount={5}
                            beforeUpload={(files) => {
                              setFile(files)
                              console.log(file)
                              return false
                            }}
                            showUploadList={{ showRemoveIcon: true }}
                            accept=".pdf"
                            listType="picture"
                            onChange={(e) => {
                              console.log(e)
                              setFile(e.fileList)
                            }}
                          >
                            Importer des annexes ou <br />
                            <Button>Parcourir Fichier</Button>
                          </Upload.Dragger>
                        </div>
                        <table
                          class="table table-striped"
                          style={{ marginTop: '1vw' }}
                        >
                          <thead className="thead-light">
                            <tr>
                              <th>N° du Compte Rendu</th>
                              <th>Nom de la pièce</th>
                              <th>Fichier</th>
                              <th>Actif</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allCr &&
                              allCr.map((e) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{e.idCr ? e.idCr : <></>}</td>
                                      <td>{e.filename ? e.filename : <></>}</td>
                                      <td>
                                        <a
                                          href={`${process.env.REACT_APP_URL}/api/v1/comptesrendu/getAnnexe/annexe/${e.fileToken}`}
                                          target="_blank"
                                          class="download-icon-link"
                                        >
                                          <i class="las la-download file-download-icon"></i>
                                        </a>
                                      </td>
                                      <td>
                                        <div class="form-check form-switch form-switch-success">
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            defaultChecked={
                                              e.active ? e.active : false
                                            }
                                            onChange={(el) => {
                                              axios.patch(
                                                `${process.env.REACT_APP_URL}/api/v1/annexes/${e._id}`,
                                                { active: el.target.checked },
                                                {
                                                  headers: {
                                                    Authorization: `Bearer ${infoUser.token}`,
                                                  },
                                                }
                                              )
                                            }}
                                          />
                                          <label
                                            class="form-check-label"
                                            for="customSwitchSuccess"
                                          ></label>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* fin wizard */}
        </div>
      </div>
      {/* Modal Planification */}
      <div class="modal fade" id="myModalPlannification">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Plannification</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                console.log(document.getElementById('modalPlanification').value)
                updateData(
                  {
                    observation:
                      document.getElementById('modalPlanification').value,
                    _id: dataModalPlanification._id,
                  },
                  'planningSo',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataPlanningSo(
                      dataPlanningSo.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              {' '}
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    id="modalPlanification"
                    value={
                      dataModalPlanification.observation
                        ? dataModalPlanification.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalPlanification({
                        ...dataModalPlanification,
                        observation: e.target.value,
                      })
                    }}
                    class="form-control"
                    rows="7"
                    name="text"
                  ></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>{' '}
      {/* Modal Engagement */}
      <div class="modal fade" id="myModalEngagement">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Engagement</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation:
                      document.getElementById('modalEngagement').value,
                    _id: dataModalEngagement._id,
                  },
                  'engagements',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataEngagement(
                      dataEngagement.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    value={
                      dataModalEngagement.observation
                        ? dataModalEngagement.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalEngagement({
                        ...dataModalEngagement,
                        observation: e.target.value,
                      })
                    }}
                    id="modalEngagement"
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Administratif */}
      <div class="modal fade" id="myModalAdministratif">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Administratif</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation:
                      document.getElementById('modalAdministratif').value,
                    _id: dataModalAdministration._id,
                  },
                  'administratifs',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataAdministratif(
                      dataAdministratif.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalAdministratif"
                    value={
                      dataModalAdministration.observation
                        ? dataModalAdministration.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalAdministration({
                        ...dataModalAdministration,
                        observation: e.target.value,
                      })
                    }}
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Securite */}
      <div class="modal fade" id="myModalSecurite">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Sécurité</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation: document.getElementById('modalSecurite').value,
                    _id: dataModalSecurite._id,
                  },
                  'securites',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataSecurite(
                      dataSecurite.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalSecurite"
                    value={
                      dataModalSecurite.observation
                        ? dataModalSecurite.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalSecurite({
                        ...dataModalSecurite,
                        observation: e.target.value,
                      })
                    }}
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Installation */}
      <div class="modal fade" id="myModalInstallation">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Installation chantier</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation:
                      document.getElementById('modalInstallation').value,
                    _id: dataModalInstallation._id,
                  },
                  'installationchantiers',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataInstallation(
                      dataInstallation.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalInstallation"
                    value={
                      dataModalInstallation.observation
                        ? dataModalInstallation.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalInstallation({
                        ...dataModalInstallation,
                        observation: e.target.value,
                      })
                    }}
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Effectif */}
      <div class="modal fade" id="myModalEffectif">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Effectifs</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation: document.getElementById('modalEffectif').value,
                    _id: dataModalEffectif._id,
                  },
                  'effectifs',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data) {
                    setDataEffectif(
                      dataEffectif.map((el) => {
                        if (el._id == res.data._id) {
                          return res.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalEffectif"
                    value={
                      dataModalEffectif.observation
                        ? dataModalEffectif.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalEffectif({
                        ...dataModalEffectif,
                        observation: e.target.value,
                      })
                    }}
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal document */}
      <ModalDocument
        dataModalDocument={dataModalDocument}
        dataModalSuiviDoc={dataModalSuiviDoc}
        indiceDoc={indiceDoc}
        setDataDocument={setDataDocument}
        setDataModalSuiviDoc={setDataModalSuiviDoc}
        dataDocument={dataDocument}
      />
      {wait | wait2 && (
        <>
          <div
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default NewCr
