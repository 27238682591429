import React,{useEffect,useState} from 'react'
import axios from 'axios'


const AddLot = () => {
    const addLot = () => {

    }
  return (
    <div className="container-fluid d-flex">
      <div className="container">
      <div>
        <form>
            <h1>Lot</h1>
          <div class="mb-3 mt-3">
            <label for="email" class="form-label">
              Nom:
            </label>
            <input
              type="text"
              class="form-control"
              id="email"
              name="email"
            />
          </div>
          <div class="mb-3">
            <label for="pwd" class="form-label">
              ID:
            </label>
            <input
              type="text"
              class="form-control"
              id="pwd"
              name="pswd"
            />
          </div>
          <button type="submit" class="btn btn-primary">
            Enregistrer
          </button>
        </form>
      </div>
      </div>
      <div className="container">
      <div>
        <form>
            <h1>Entreprise</h1>
          <div class="mb-3 mt-3">
            <label for="email" class="form-label">
              Nom:
            </label>
            <input
              type="text"
              class="form-control"
              id="email"
              name="email"
            />
          </div>
          <div class="mb-3">
            <label for="pwd" class="form-label">
              Responsable:
            </label>
            <input
              type="text"
              class="form-control"
              id="pwd"
              name="pswd"
            />
          </div>
          <div class="mb-3">
            <label for="pwd" class="form-label">
              Nom:
            </label>
            <input
              type="text"
              class="form-control"
              id="pwd"
              name="pswd"
            />
          </div>
          <div class="mb-3">
            <label for="pwd" class="form-label">
              Email:
            </label>
            <input
              type="text"
              class="form-control"
              id="pwd"
              name="pswd"
            />
          </div>
          <div class="mb-3">
            <label for="pwd" class="form-label">
              Téléphone:
            </label>
            <input
              type="text"
              class="form-control"
              id="pwd"
              name="pswd"
            />
          </div>
          <button type="submit" class="btn btn-primary">
            Enregistrer
          </button>
        </form>
      </div>
      </div>
    </div>
  )
}

export default AddLot
