import React, { useState, useEffect } from 'react'
import { ReactTabulator } from 'react-tabulator'
import axios from 'axios'
const ListTacheNonAssigner = ({ taches,setAsssignement,lot,setWait}) => {
    const infoUsers = localStorage.getItem('users')
    const infoUser = JSON.parse(infoUsers)
    const infoChantiers = localStorage.getItem('infoChantiers')
    const infoChantier = JSON.parse(infoChantiers)

  const [tacheNonAssigner, setTacheNonAssigner] = useState(
    taches
      ? taches.filter((el) => {
          if (!el.lot) {
            return el
          }
        })
      : []
  )
  const dateFr = (date) => {    
    var formattedDate =
    date.getFullYear()+'-'+ (date.getMonth() < 9 ? '0' : '') +
    (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '')+date.getDate()
    return `${formattedDate}`
  }

  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.disabled = true
    view.defaultValue = dateFr(cell.getValue())
    view.addEventListener('blur', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  //format type select
  const formatLot = (cell) => {
    const data = cell.getData()
    const view = document.createElement('select')
    view.className = 'form-select'
    const defaults = document.createElement('option')
    defaults.selected = false
    defaults.innerText = ''
    defaults.value = ''
    view.append(defaults)
   lot && lot.map((element)=>{
    const option1 = document.createElement('option')
    option1.value = element._id
    option1.innerText = element.titre
    if (element.titre == cell.getData().lot?.titre) {
      option1.selected = true
    }else{
      option1.selected = false
    }
    view.appendChild(option1)
    })
    
   
    view.addEventListener('change', function (e) {
      setWait(true)
      axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches/${data._id}`,{lot:e.target.value},{
        headers: {
          Authorization: `Bearer ${infoUser.token}`,
        },
      }).then((res)=>{
        console.log(res)
      }).catch((err)=>{
        console.log(err);
      }).finally((res)=>{
        setWait(false)
      })
  })
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const colonneTache = [
    { title: 'ID', field: 'id' },
    { title: 'NOM', field: 'text' },
    { title: 'DATE DEBUT', width: 145, field: 'start_date',formatter:date },
    { title: 'DATE FIN', width: 145, field: 'end_date',formatter:date },
    {
          title: 'LOT',
          formatter:formatLot
    }
  ]
  useEffect(() => {
    console.log(tacheNonAssigner)
    setTacheNonAssigner(
      taches.filter((el) => {
        if (!el.lot) {
          return el
        }
      })
    )
  }, [taches])
  return (
    <>
      <div
        className="container-fluid contenuRetard"
        style={{
          position: 'fixed',
          top: '0',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="formRetard">
          <div className="container-fluid headerGantt">
            <h5 style={{color:"white",marginTop:'10px',marginLeft:'10px'}}>Liste des tâches non liées</h5>
          </div>
          <div className="container-fluid" style={{ height: '500px' }}>
            {
                tacheNonAssigner.length>0 ? (<>
                <ReactTabulator
              data={tacheNonAssigner ? tacheNonAssigner : []}
              columns={colonneTache}
              layout={'fitColumns'}
              /*                   events={{cellEdited:assignementEdited}}
               */
              options={{
                locale: true,
                pagination: 'local',
                paginationSize: 7,
                langs: {
                  fr: {
                    pagination: {
                      first: 'Premier',
                      first_title: 'Première page',
                      last: 'Dernier',
                      last_title: 'Dernière page',
                      prev: 'Précédent',
                      prev_title: 'Page précédente',
                      next: 'Suivant',
                      next_title: 'Page suivante',
                      page_size: 'Taille de page',
                      page_title: 'Afficher la page',
                      all: 'Tous',
                    },
                  },
                },
              }}
            />
                </>) : <h4 className='text-center'>Toutes les taches sont déja liées </h4>
            }
          </div>
          <div className='container-fluid' style={{display:"flex",justifyContent:'end'}}>
            <button className='btn btn-dark' style={{marginRight:'10px'}} onClick={(ev)=>{
                ev.preventDefault()
                setAsssignement(false)
            }}>Fermer</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListTacheNonAssigner
