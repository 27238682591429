import React from "react";

const Header = () => {
  return (
    <>
      <div className="topbar">
        <nav className="navbar-custom" id="navbar-custom">
          <ul className="list-unstyled topbar-nav float-end mb-0">
            <li className="dropdown">
              <a
                className="nav-link dropdown-toggle nav-user"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <div class="d-flex align-items-center">
                  <img
                    src="assets/images/users/user-4.jpg"
                    alt="profile-user"
                    class="rounded-circle me-2 thumb-sm"
                  />
                  <div>
                    <small className="d-none d-md-block font-11">Admin</small>
                    <span className="d-none d-md-block fw-semibold font-12">
                      Maria Gibson <i className="mdi mdi-chevron-down"></i>
                    </span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href="#">
                  <i class="ti ti-user font-16 me-1 align-text-bottom"></i>{" "}
                  Profile
                </a>
                <a className="dropdown-item" href="#">
                  <i className="ti ti-settings font-16 me-1 align-text-bottom"></i>{" "}
                  Settings
                </a>
                <div className="dropdown-divider mb-0"></div>
                <a className="dropdown-item" href="#">
                  <i className="ti ti-power font-16 me-1 align-text-bottom"></i>{" "}
                  Logout
                </a>
              </div>
            </li>
          </ul>

          <ul className="list-unstyled topbar-nav mb-0">
            <li>
              <button
                className="nav-link button-menu-mobile nav-icon"
                id="togglemenu"
              >
                <i class="ti ti-menu-2"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Header;
