import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import BeatLoader from 'react-spinners/BeatLoader'
import { ReactTabulator } from 'react-tabulator'
import { useToasts } from 'react-toast-notifications'
import { getISOWeek, getISOWeekYear } from 'date-fns'
import {
  getAllData,
  getData,
  addData,
  updateData,
  delData,
  getForfait,
} from '../../../helpers/suivi/suivi'
import ModalDocument from './component/ModalDocument'
import ModalPenalite from './component/ModalPenalite'
import PageNotFound from '../../../components/404/PageNotFound'
import { useNavigate } from 'react-router-dom'
//Profil de chaque entreprise
const ProfilEntreprise = () => {
  const { addToast } = useToasts()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const navigate = useNavigate()
  //const _id = localStorage.getItem('_id')
  //const titreLot = localStorage.getItem('titreLot')
  const [_id, setId] = useState(
    localStorage.getItem('_id') ? localStorage.getItem('_id') : ''
  )
  const [_idLot, setIdLot] = useState(
    localStorage.getItem('_idLot') ? localStorage.getItem('_idLot') : ''
  )
  const [titreLot, setTitreLot] = useState(
    localStorage.getItem('titreLot') ? localStorage.getItem('titreLot') : ''
  )
  const [dataEngagement, setDataEngagement] = useState([])
  const [dataAdministratif, setDataAdministratif] = useState([])
  const [lot, setLot] = useState({})
  const [forfait, setForfait] = useState({})
  const [dataDocument, setDataDocument] = useState([])
  const [dataSecurite, setDataSecurite] = useState([])
  const [dataInstallation, setDataInstallation] = useState([])
  const [dataEffectif, setDataEffectif] = useState([])
  const [dataCorrespondances,setDataCorrespondances] = useState([])
  const [dataPlanningSo, setDataPlanningSo] = useState([])
  const [dataModalPlanification, setDataModalPlanification] = useState({})
  const [dataModalEngagement, setDataModalEngagement] = useState({})
  const [dataModalAdministration, setDataModalAdministration] = useState({})
  const [dataModalDocument, setDataModalDocument] = useState({})
  const [dataModalSecurite, setDataModalSecurite] = useState({})
  const [dataModalInstallation, setDataModalInstallation] = useState({})
  const [dataModalEffectif, setDataModalEffectif] = useState({})
  const [dataModalSuiviDoc, setDataModalSuiviDoc] = useState([])
  const [entreprise, setEntreprise] = useState()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [pourcentageGlobal, setPourcentageGlobal] = useState(0)
  const [colorNote, setColorNote] = useState('black')
  const [listNote, setListNote] = useState([0, 0, 0, 0, 0, 0, 0, 0])
  const [refresh, setRefresh] = useState(true)
  const [wait, setWait] = useState(false)
  const [bool, setBool] = useState(false)
  const [show, setShow] = useState(false)
  const indiceDoc = [
    { value: 'A' },
    { value: 'B' },
    { value: 'C' },
    { value: 'D' },
    { value: 'E' },
    { value: 'F' },
    { value: 'G' },
    { value: 'H' },
    { value: 'I' },
    { value: 'J' },
    { value: 'K' },
    { value: 'L' },
    { value: 'M' },
    { value: 'N' },
    { value: 'O' },
    { value: 'P' },
    { value: 'Q' },
    { value: 'R' },
    { value: 'S' },
    { value: 'T' },
    { value: 'U' },
    { value: 'V' },
    { value: 'W' },
    { value: 'X' },
    { value: 'Y' },
    { value: 'Z' },
  ]
  useEffect(() => {
    getForfait(infoChantier.planning, infoUser.token).then((e) => {
      setForfait(e.data[0])
      setWait(false)
    })
  }, [bool])

  //Recuperattion information lot
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((e) => {
      if (e.data) {
        e.data.map((res) => {
          if (res._id == localStorage.getItem('_idLot')) {
            setLot(res)
          }
        })
      }
      setWait(false)
    })
  }, [bool])
  useEffect(() => {
    getAllData(`entId=${_id}`, 'lots/lotprogress', infoUser.token).then((e) => {
      console.log(e)
      e.data?.pourcentageGlobal &&
        setPourcentageGlobal(e.data.pourcentageGlobal)
      e.data?.color && setColorNote(e.data.color)
      e.data.pourcentage && setListNote(e.data.pourcentage)
      /*     if (e.data.pourcentage) {
      setSeries(e.data.pourcentage)
      setOptions({
        plotOptions: {
          radialBar: {
            dataLabels: {
              show: true,
              total: {
                show: true,
                label: e.data.note,
                formatter: function () {
                  return e.data.pourcentageGlobal
                    ? `${e.data.pourcentageGlobal.toFixed(0)} %`
                    : 0
                },
              },
              hover: {
                offsetY: -10,
                fontSize: '20px',
                color: '#ffffff',
              },
            },
          },
        },
        labels: [
          'Planning',
          'Pannification',
          'Engagements',
          'Administratifs',
          'Documents',
          'Sécurités',
          'Installation',
        ],
      })
    } else {
      addToast("Une erreur s'est produit", {
        appearance: 'error',
        autoDismiss: true,
      })
    }
 */
    })
  }, [])
  //Recupération information entreprise
  useEffect(() => {
    setId(localStorage.getItem('_id'))
    setTitreLot(localStorage.getItem('titreLot'))

    getData(_id, 'entreprises', infoUser.token).then((e) => {
      setEntreprise(e)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&archive=false`,
      'planningSo',
      infoUser.token
    ).then((e) => {
      setDataPlanningSo(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&archive=false`,
      'engagements',
      infoUser.token
    ).then((e) => {
      setDataEngagement(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&archive=false`,
      'administratifs',
      infoUser.token
    ).then((e) => {
      setDataAdministratif(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&archive=false`,
      'documents',
      infoUser.token
    ).then((e) => {
      setDataDocument(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&archive=false`,
      'securites',
      infoUser.token
    ).then((e) => {
      setDataSecurite(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&archive=false`,
      'installationchantiers',
      infoUser.token
    ).then((e) => {
      setDataInstallation(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&archive=false`,
      'effectifs',
      infoUser.token
    ).then((e) => {
      setDataEffectif(e.data)
    })
  }, [_id, titreLot])

  //fonction pour verifier indice unique
  const indiceUnique = (tab, value) => {
    const tableau = tab.map((el) => {
      return el.indiceEnCours
    })
    if (tableau.includes(value)) {
      return true
    } else {
      return false
    }
  }
  //del Function
  const pop = (candidate, data) => {
    return data.filter((d) => d._id !== candidate._id)
  }

  const delPlanning = async (cell) => {
    try {
      await delData(cell.getData(), 'planningSo', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataPlanningSo([...pop(cell.getData(), dataPlanningSo)])
    //cell.getRow().delete()
  }

  const delEngagements = async (cell) => {
    try {
      await delData(cell.getData(), 'engagements', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataEngagement([...pop(cell.getData(), dataEngagement)])
    console.log(cell.getData()._id)
    //cell.getRow().delete()
  }

  const delAdministratifs = async (cell) => {
    try {
      await delData(cell.getData(), 'administratifs', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataAdministratif([...pop(cell.getData(), dataAdministratif)])
    //cell.getRow().delete()
  }

  const delDocuments = async (cell) => {
    try {
      await delData(cell.getData(), 'documents', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataDocument([...pop(cell.getData(), dataDocument)])
    //cell.getRow().delete()
  }

  const delSecurites = async (cell) => {
    try {
      await delData(cell.getData(), 'securites', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataSecurite([...pop(cell.getData(), dataSecurite)])
    //cell.getRow().delete()
  }

  /*const delPenalites = async (cell)=>{
    try{
      await delData(cell.getData(), 'securites')
    }catch(err){alert(err)}
  }*/

  const delInstallationChantier = async (cell) => {
    try {
      await delData(cell.getData(), 'installationchantiers', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataInstallation([...pop(cell.getData(), dataInstallation)])
    //cell.getRow().delete()
  }
  const delEffectif = async (cell) => {
    try {
      await delData(cell.getData(), 'effectifs', infoUser.token)
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setDataEffectif([...pop(cell.getData(), dataEffectif)])
    //cell.getRow().delete()
  }

  //Boutton de suppression
  const button = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-trash')

    //create delete button
    var delBt = document.createElement('button')
    delBt.type = 'button'
    delBt.appendChild(delIcon)
    delBt.classList.add('btn')
    delBt.classList.add('btn-outline-danger')
    delBt.addEventListener('click', function () {
      delFunction(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)

    return buttonHolder
  }
  //input semaine
  const semaine = (cell) => {
    const data = cell.getData()
    console.log(data)
    const div = document.createElement('div')
    const week = document.createElement('input')
    week.classList = 'form-control'
    week.type = 'week'
    week.defaultValue = data.formattedSemaine ? data.formattedSemaine : ''
    week.addEventListener('change', function (e) {
      console.log(e.target.value)
      updateData(
        { ...data, formattedSemaine: e.target.value },
        `effectifs`,
        infoUser.token
      ).then((e) => {
        console.log(e)
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    div.appendChild(week)
    return div
  }
  //Effectif pers
  const pers = (cell) => {
    const data = cell.getData()
    const div = document.createElement('div')
    div.style = 'display:flex;align-items:center'
    const inputPers = document.createElement('input')
    inputPers.type = 'number'
    inputPers.className = 'form-control'
    inputPers.style = 'width:70px;text-align:rigth'
    inputPers.defaultValue = data.prevu ? data.prevu : 0
    inputPers.addEventListener('change', function (e) {
      updateData(
        { ...data, prevu: e.target.value },
        `effectifs`,
        infoUser.token
      ).then((e) => {
        console.log(e)
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    const unite = document.createElement('sapan')
    unite.innerText = 'per(s)'
    div.appendChild(inputPers)
    div.appendChild(unite)
    return div
  }
  //Effectif pers
  const pers2 = (cell) => {
    const data = cell.getData()
    const div = document.createElement('div')
    div.style = 'display:flex;align-items:center'
    const inputPers = document.createElement('input')
    inputPers.type = 'number'
    inputPers.className = 'form-control'
    inputPers.style = 'width:70px;text-align:rigth'
    inputPers.defaultValue = data.reel?.present ? data.reel.present : 0
    inputPers.addEventListener('change', function (e) {
      console.log(data)
      updateData(
        { ...data, reel: { ...data.reel, present: e.target.value } },
        `effectifs`,
        infoUser.token
      ).then((e) => {
        console.log(e)
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    const unite = document.createElement('sapan')
    unite.innerHTML = `<span>per(s) <span style='color:${
      data.reel?.color ? data.reel.color : 'blue'
    };font-weight:bold'>(${
      data.reel?.diff ? data.reel.diff : 0
    })</span> </span>`
    div.appendChild(inputPers)
    div.appendChild(unite)
    return div
  }

  //Modal document
  const modalDocument = (dellFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalDoc')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      console.log(cell.getData())
      setDataModalDocument(cell.getData())
      setDataModalSuiviDoc(
        cell.getData().suiviDocs ? cell.getData().suiviDocs : []
      )
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }
  //Modal Plannification
  const modalPlannification = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalPlannification')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalPlanification(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Engagement
  const modalEngagement = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEngagement')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalEngagement(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Administratif
  const modalAdministratif = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAdministratif')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalAdministration(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Securite
  const modalSecurite = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalSecurite')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalSecurite(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Installation
  const modalInstallation = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalInstallation')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalInstallation(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Effectif
  const modalEffectif = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEffectif')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalEffectif(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Correspondances
  const modalCorrespondances = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEffectif')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalEffectif(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }

  //Create date picker
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('blur', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const inputUrgence = (cell) => {
    const option1 = document.createElement('option')
    option1.value = 1
    option1.innerText = 'Faible'
    if (option1.value == cell.getValue()) {
      option1.selected = true
    }
    const option2 = document.createElement('option')
    option2.value = 2
    option2.innerText = 'Important'
    if (option2.value == cell.getValue()) {
      option2.selected = true
    }
    const option3 = document.createElement('option')
    option3.value = 3
    option3.innerText = 'Primordial'
    if (option3.value == cell.getValue()) {
      option3.selected = true
    }
    const defaults = document.createElement('option')
    defaults.selected = true
    defaults.innerText = cell.getValue() ? cell.getValue() : 0
    defaults.value = cell.getValue() ? cell.getValue() : 0
    const view = document.createElement('select')
    view.className = 'form-select'
    view.appendChild(option1)
    view.appendChild(option2)
    view.appendChild(option3)
    view.addEventListener('blur', function (e) {
      console.log(e.target.value)
      cell.setValue(e.target.value)
    })
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
      if (cell.getValue()) {
        console.log('ok')
        if (
          (data.prevuLe == undefined) |
          (data.prevuLe == '') |
          (data.prevuLe == null)
        ) {
          data.prevuLe = new Date(Date.now()).toISOString()
        }
        if (
          (data.faitLe == undefined) |
          (data.faitLe == '') |
          (data.faitLe == null)
        ) {
          data.faitLe = new Date(Date.now()).toISOString()
        }
        console.log(data)
      } else {
      }
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }
  const colonneEngagement = [
    { title: 'Engagements', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Acté Le', field: 'engageLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalEngagement() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEngagements),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneAdministratif = [
    { title: 'Titre', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalAdministratif() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delAdministratifs),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneDocument = [
    { title: 'Titre', field: 'titre', editor: 'input' },
    { title: 'Phase', field: 'phase', editor: 'input' },
    { title: 'Type', field: 'type', editor: 'input' },
    { title: 'Niveau', field: 'niveau', editor: 'input' },
    { title: 'Zone', field: 'zone', editor: 'input' },
    { title: 'Indice', field: 'indice' },
    { title: 'Date BPE', field: 'dateBPE', formatter: date },
    { title: 'Prévu Le', field: 'prevision', formatter: date },
    { title: 'Diffusé Le', field: 'diffusion', formatter: date },
    { title: 'Visa', field: 'visa' },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'functionButton',
      formatter: modalDocument(),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delDocuments),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonnePlanning = [
    { title: 'Tâches', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Acté Le', field: 'acteLe', formatter: date },
    { title: 'Planifié Le', field: 'prevuLe', formatter: date },
    { title: 'Réalisé Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', with: 100, formatter: modalPlannification() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delPlanning),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneSecurite = [
    { title: 'Remarques', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalSecurite() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delSecurites),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneInstallation = [
    { title: 'Remarques', field: 'titre', editor: 'input' },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Urgence', field: 'urgence', formatter: inputUrgence },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    { title: 'Details', width: 100, formatter: modalInstallation() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delInstallationChantier),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneEffectif = [
    { title: 'Semaine(s)', field: '', formatter: semaine },
    { title: 'Noté le', field: 'noteLe', formatter: date },
    { title: 'Prévu', field: 'prevu', formatter: pers },
    { title: 'Réel (delta)', field: 'reel.present', formatter: pers2 },
    { title: 'Details', width: 100, formatter: modalEffectif() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEffectif),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneCorrespondances = [
    { title: 'Semaine(s)', field: '', formatter: semaine },
    { title: 'Noté le', field: 'noteLe', formatter: date },
    { title: 'Prévu', field: 'prevu', formatter: pers },
    { title: 'Réel (delta)', field: 'reel.present', formatter: pers2 },
    { title: 'Details', width: 100, formatter: modalEffectif() },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEffectif),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]

  const rowDataCandidate = {
    titre: 'New',
    noteLe: Date.now(),
    entreprise: _id,
    planning: infoChantier.planning,
  }
  const planningEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataPlanningSo(
          dataPlanningSo.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `planningSo`, infoUser.token)
      } else {
        data.statut = true
        setDataPlanningSo(
          dataPlanningSo.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `planningSo`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataPlanningSo(
              dataPlanningSo.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `planningSo`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataPlanningSo(
            dataPlanningSo.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `planningSo`, infoUser.token)
        }
      } else {
        updateData(data, `planningSo`, infoUser.token)
      }
    }
  }
  const engagementEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataEngagement(
          dataEngagement.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `engagements`, infoUser.token)
      } else {
        data.statut = true
        setDataEngagement(
          dataEngagement.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `engagements`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataEngagement(
              dataEngagement.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `engagements`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataEngagement(
            dataEngagement.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `engagements`, infoUser.token)
        }
      } else {
        updateData(data, `engagements`, infoUser.token)
      }
    }
  }

  const administratifEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataAdministratif(
          dataAdministratif.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `administratifs`, infoUser.token)
      } else {
        data.statut = true
        setDataAdministratif(
          dataAdministratif.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `administratifs`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataAdministratif(
              dataAdministratif.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `administratifs`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataAdministratif(
            dataAdministratif.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `administratifs`, infoUser.token)
        }
      } else {
        updateData(data, 'administratifs', infoUser.token)
      }
    }
  }
  const documentEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'diffusion') {
      if (
        (data.diffusion == '') |
        (data.diffusion == undefined) |
        (data.diffusion == null)
      ) {
        data.statut = false
        setDataDocument(
          dataDocument.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `documents`, infoUser.token).then((e) => {})
      } else {
        data.statut = true
        setDataDocument(
          dataDocument.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `documents`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.diffusion == '') |
            (data.diffusion == undefined) |
            (data.diffusion == null)
          ) {
            data.diffusion = new Date(Date.now()).toISOString()
            setDataDocument(
              dataDocument.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `documents`, infoUser.token)
          }
        } else {
          data.diffusion = ''
          setDataDocument(
            dataDocument.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `documents`, infoUser.token)
        }
      } else {
        setDataDocument(
          dataDocument.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `documents`, infoUser.token)
      }
    }
  }
  const securiteEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataSecurite(
          dataSecurite.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `securites`, infoUser.token)
      } else {
        data.statut = true
        setDataSecurite(
          dataSecurite.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `securites`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataSecurite(
              dataSecurite.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `securites`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataSecurite(
            dataSecurite.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `securites`, infoUser.token)
        }
      } else {
        updateData(data, `securites`, infoUser.token)
      }
    }
  }
  const installationEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        setDataInstallation(
          dataInstallation.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `installationchantiers`, infoUser.token)
      } else {
        data.statut = true
        setDataInstallation(
          dataInstallation.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
        updateData(data, `installationchantiers`, infoUser.token)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            setDataInstallation(
              dataInstallation.map((el) => {
                if (el._id == data._id) {
                  return data
                } else {
                  return el
                }
              })
            )
            updateData(data, `installationchantiers`, infoUser.token)
          }
        } else {
          data.faitLe = ''
          setDataInstallation(
            dataInstallation.map((el) => {
              if (el._id == data._id) {
                return data
              } else {
                return el
              }
            })
          )
          updateData(data, `installationchantiers`, infoUser.token)
        }
      } else {
        updateData(data, `installationchantiers`, infoUser.token)
      }
    }
  }
  const effectifEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    updateData(data, `effectifs`, infoUser.token).then((e) => {
      console.log(e)
    })
  }
  const correspondancesEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    updateData(data, `effectifs`, infoUser.token).then((e) => {
      console.log(e)
    })
  }


  const addPlanning = async (e) => {
    e.preventDefault()
    const result = await addData(
      rowDataCandidate,
      'planningSo',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setDataPlanningSo([{ ...result }, ...dataPlanningSo])
  }
  const addEngagementRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'engagements',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setDataEngagement([{ ...result }, ...dataEngagement])
  }
  const addAdministratifRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'administratifs',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setDataAdministratif([{ ...result }, ...dataAdministratif])
  }
  const addDocumentRow = async (e) => {
    e.preventDefault()
    setWait(true)
    await addData(rowDataCandidate, 'documents', _idLot, infoUser.token).then(
      (e) => {
        console.log(e)
        if (e.name == 'AxiosError' || e.message == 'erreur') {
          setWait(false)
          addToast("Une erreur s'est produit.", {
            appearance: 'error',
            autoDismiss: true,
          })
        } else {
          setWait(false)
          setDataDocument([{ ...e }, ...dataDocument])
        }
      }
    )
  }
  const addSecuriteRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'securites',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setDataSecurite([{ ...result }, ...dataSecurite])
  }
  const addInstallationRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'installationchantiers',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setDataInstallation([{ ...result }, ...dataInstallation])
  }
  const addEffectifRow = async (e) => {
    console.log(`${getISOWeekYear(Date.now())}-W${getISOWeek(new Date())}`)
    e.preventDefault()
    setWait(true)
    const result = await addData(
      {
        formattedSemaine: `${getISOWeekYear(Date.now())}-W${getISOWeek(
          new Date()
        )}`,
        noteLe: Date.now(),
        prevu: 0,
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'effectifs',
      _idLot,
      infoUser.token
    ).then((e) => {
      console.log(e)
      setWait(false)
      return e
    })
    setDataEffectif([{ ...result }, ...dataEffectif])
  }
  const addCorrespondancesRow = async (e) => {
    console.log(`${getISOWeekYear(Date.now())}-W${getISOWeek(new Date())}`)
    e.preventDefault()
    setWait(true)
    const result = await addData(
      {
        formattedSemaine: `${getISOWeekYear(Date.now())}-W${getISOWeek(
          new Date()
        )}`,
        noteLe: Date.now(),
        prevu: 0,
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'effectifs',
      _idLot,
      infoUser.token
    ).then((e) => {
      console.log(e)
      setWait(false)
      return e
    })
    setDataEffectif([{ ...result }, ...dataEffectif])
  }


  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="page-title-box">
              <div class="float-end"></div>
              <h4 class="page-title">Suivi opérationnel</h4>
            </div>
            <div class="page-title-box">
              <div class="float-end">
                <button
                  className="btn btn-dark"
                  onClick={(e) => {
                    navigate('/SuiviOperationnel')
                  }}
                >
                  Retour
                </button>
              </div>
              <h6 class="page-title">Profil</h6>
            </div>
            {/*debut profil */}
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="met-profile">
                      <div class="row">
                        <div class="col-sm-4 align-self-center mb-3 mb-lg-0">
                          <div class="met-profile-main">
                            <div class="met-profile_user-detail">
                              <h5 class="met-user-name">Lot : {titreLot}</h5>
                              <p class="mb-0 met-user-name-post">
                                Entreprise : {entreprise ? entreprise.nom : ''}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-4 ms-auto align-self-center">
                          <ul class="list-unstyled personal-detail mb-0">
                            <li>
                              <i class="las la-user text-secondary font-22 align-middle "></i>{' '}
                              <b> Responsable </b> :{' '}
                              {entreprise?.responsable?.name
                                ? entreprise.responsable.name
                                : ''}{' '}
                              {entreprise?.responsable?.lastname
                                ? entreprise.responsable.lastname
                                : ''}
                            </li>
                            <li>
                              <i class="las la-phone mr-2 text-secondary font-22 align-middle"></i>{' '}
                              <b> Téléphone </b> : +
                              {entreprise?.responsable?.telephone
                                ? entreprise.responsable.telephone
                                : ''}
                            </li>
                            <li>
                              <i class="las la-envelope text-secondary font-22 align-middle mr-2"></i>{' '}
                              <b> Email </b> :{' '}
                              {entreprise ? entreprise.responsable.email : ''}
                            </li>
                          </ul>
                        </div>
                        <div
                          class="col-sm-4"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                          }}
                        >
                          <div
                            class="row"
                            onMouseOver={(e) => {
                              e.preventDefault()
                              setShow(true)
                            }}
                            onMouseOut={(e) => {
                              e.preventDefault()
                              setShow(false)
                            }}
                            style={{
                              width: '100px',
                              height: '100px',
                              display: 'flex',
                              sjustifyContent: 'center',
                              alignItems: 'center',
                              border: `solid ${colorNote} 2px`,
                              borderRadius: '50%',
                              marginBottom: '10px',
                              cursor: 'pointer',
                            }}
                          >
                            {/*  <Chart
                              options={options}
                              series={series}
                              type="radialBar"
                              width={250}
                            /> */}
                            <span
                              className="text-center"
                              style={{
                                fontWeight: 'bold',
                                color: `${colorNote}`,
                                fontSize: '150%',
                              }}
                            >
                              {pourcentageGlobal
                                ? pourcentageGlobal.toFixed(0)
                                : 0}
                              %
                            </span>
                          </div>
                          {show && (
                            <>
                              <div
                                id="infoNote"
                                style={{
                                  position: 'absolute',
                                  right: '65%',
                                  bottom: '10px',
                                  border: 'solid #2C304D 2px',
                                  borderRadius: '10px',
                                  padding: '5px',
                                  backgroundColor: 'white',
                                  width: '300px',
                                  zIndex: '100000',
                                }}
                              >
                                <div className="d-flex">
                                  <span>P:</span>
                                  <div
                                    class="progress mb-3"
                                    style={{
                                      width: '90%',
                                      marginTop: '5px',
                                      height: '11px',
                                    }}
                                  >
                                    <div
                                      class="progress-bar bg-primary"
                                      role="progressbar"
                                      style={{ width: `${listNote[1]}%` }}
                                      aria-valuenow="20"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {listNote[1].toFixed(0)}%
                                    </div>
                                  </div>{' '}
                                </div>
                                <div className="d-flex">
                                  <span>E:</span>
                                  <div
                                    class="progress mb-3"
                                    style={{
                                      width: '90%',
                                      marginTop: '5px',
                                      height: '11px',
                                    }}
                                  >
                                    <div
                                      class="progress-bar bg-primary"
                                      role="progressbar"
                                      style={{ width: `${listNote[2]}%` }}
                                      aria-valuenow="20"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {listNote[2].toFixed(0)}%
                                    </div>
                                  </div>{' '}
                                </div>
                                <div className="d-flex">
                                  <span>A:</span>
                                  <div
                                    class="progress mb-3"
                                    style={{
                                      width: '90%',
                                      marginTop: '5px',
                                      height: '11px',
                                    }}
                                  >
                                    <div
                                      class="progress-bar bg-primary"
                                      role="progressbar"
                                      style={{ width: `${listNote[3]}%` }}
                                      aria-valuenow="20"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {listNote[3].toFixed(0)}%
                                    </div>
                                  </div>{' '}
                                </div>
                                <div className="d-flex">
                                  <span>D:</span>
                                  <div
                                    class="progress mb-3"
                                    style={{
                                      width: '90%',
                                      marginTop: '5px',
                                      height: '11px',
                                    }}
                                  >
                                    <div
                                      class="progress-bar bg-primary"
                                      role="progressbar"
                                      style={{ width: `${listNote[4]}%` }}
                                      aria-valuenow="20"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {listNote[4].toFixed(0)}%
                                    </div>
                                  </div>{' '}
                                </div>
                                <div className="d-flex">
                                  <span>S:</span>
                                  <div
                                    class="progress mb-3"
                                    style={{
                                      width: '90%',
                                      marginTop: '5px',
                                      height: '11px',
                                    }}
                                  >
                                    <div
                                      class="progress-bar bg-primary"
                                      role="progressbar"
                                      style={{ width: `${listNote[5]}%` }}
                                      aria-valuenow="20"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {listNote[5].toFixed(0)}%
                                    </div>
                                  </div>{' '}
                                </div>
                                <div className="d-flex">
                                  <span>IC:</span>
                                  <div
                                    class="progress mb-3"
                                    style={{
                                      width: '90%',
                                      marginTop: '5px',
                                      height: '11px',
                                    }}
                                  >
                                    <div
                                      class="progress-bar bg-primary"
                                      role="progressbar"
                                      style={{ width: `${listNote[6]}%` }}
                                      aria-valuenow="20"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {listNote[6].toFixed(0)}%
                                    </div>
                                  </div>{' '}
                                </div>
                               {/*  <div className="d-flex">
                                  <span>Ef:</span>
                                  <div
                                    class="progress mb-3"
                                    style={{
                                      width: '90%',
                                      marginTop: '5px',
                                      height: '11px',
                                    }}
                                  >
                                    <div
                                      class="progress-bar bg-primary"
                                      role="progressbar"
                                      style={{ width: `${listNote[7]}%` }}
                                      aria-valuenow="20"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      {listNote[6].toFixed(0)}%
                                    </div>
                                  </div>{' '}
                                </div> */}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            data-bs-toggle="tab"
                            href="#Planning"
                            role="tab"
                            aria-selected="true"
                          >
                            Planification
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Engagement"
                            role="tab"
                            aria-selected="false"
                          >
                            Engagements
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Administratif"
                            role="tab"
                            aria-selected="false"
                          >
                            Administratifs
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Documents"
                            role="tab"
                            aria-selected="false"
                          >
                            Documents
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Securite"
                            role="tab"
                            aria-selected="false"
                          >
                            Sécurités
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Installation"
                            role="tab"
                            aria-selected="false"
                          >
                            Installation de chantier
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Effectif"
                            role="tab"
                            aria-selected="false"
                          >
                            Effectifs
                          </a>
                        </li>
                        {infoUser.user?.role?.roleType == 'admin' && (
                          <>
                            <li class="nav-item">
                              {/* <a
                                class="nav-link"
                                data-bs-toggle="tab"
                                href="#Correspondances"
                                role="tab"
                                aria-selected="false"
                              >
                                Correspondances
                              </a> */}
                            </li>
                          </>
                        )}
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            data-bs-toggle="tab"
                            href="#Penalite"
                            role="tab"
                            aria-selected="false"
                          >
                            Pénalités
                          </a>
                        </li>
                      </ul>

                      <div class="tab-content">
                        {/*Plannification*/}
                        <div
                          class="tab-pane p-3 active"
                          id="Planning"
                          role="tabpanel"
                        >
                          <div class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataPlanningSo}
                                columns={colonnePlanning}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: planningEdited }}
                                // options={{
                                //   pagination: 'local',
                                //   paginationSize: 5,
                                // }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addPlanning(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*Engaements*/}
                        <div
                          class="tab-pane p-3"
                          id="Engagement"
                          role="tabpanel"
                        >
                          <div class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataEngagement}
                                columns={colonneEngagement}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: engagementEdited }}
                                // options={{
                                //   pagination: 'local',
                                //   paginationSize: 5,
                                // }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addEngagementRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*Administratif*/}
                        <div
                          class="tab-pane p-3"
                          id="Administratif"
                          role="tabpanel"
                        >
                          <div id="grid" class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataAdministratif}
                                columns={colonneAdministratif}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: administratifEdited }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addAdministratifRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*Sécuirités*/}
                        <div
                          class="tab-pane p-3"
                          id="Documents"
                          role="tabpanel"
                        >
                          <div class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataDocument}
                                columns={colonneDocument}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: documentEdited }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addDocumentRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*Installastion*/}
                        <div class="tab-pane p-3" id="Securite" role="tabpanel">
                          <div id="grid" class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataSecurite}
                                columns={colonneSecurite}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: securiteEdited }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addSecuriteRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*Effectifs*/}
                        <div class="tab-pane p-3" id="Effectif" role="tabpanel">
                          <div id="grid" class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataEffectif}
                                columns={colonneEffectif}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: effectifEdited }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addEffectifRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*Correspondances*/}
                        <div class="tab-pane p-3" id="correspondances" role="tabpanel">
                          <div id="grid" class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataCorrespondances}
                                columns={colonneCorrespondances}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: correspondancesEdited }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addCorrespondancesRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*Pénalités*/}
                        <div
                          class="tab-pane p-3"
                          id="Installation"
                          role="tabpanel"
                        >
                          <div id="grid" class="row">
                            <div className="container-fluid">
                              <ReactTabulator
                                data={dataInstallation}
                                columns={colonneInstallation}
                                height={'310px'}
                                layout={'fitColumns'}
                                events={{ cellEdited: installationEdited }}
                              />
                              <div className="d-flex flex-row-reverse">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-primary"
                                  onClick={(e) => addInstallationRow(e)}
                                >
                                  <i class="ti ti-plus"></i> Ajouter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane p-3" id="Penalite" role="tabpanel">
                          <div id="grid" class="row">
                            {lot && (
                              <ModalPenalite
                                lot={lot}
                                forfait={forfait}
                                bool={bool}
                                setBool={setBool}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Fin profil */}
          </div>
        </div>
      </div>
      {/* Modal Planification */}
      <div class="modal fade" id="myModalPlannification">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Plannification</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                console.log(document.getElementById('modalPlanification').value)
                updateData(
                  {
                    observation:
                      document.getElementById('modalPlanification').value,
                    _id: dataModalPlanification._id,
                  },
                  'planningSo',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataPlanningSo(
                      dataPlanningSo.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              {' '}
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    id="modalPlanification"
                    value={
                      dataModalPlanification.observation
                        ? dataModalPlanification.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalPlanification({
                        ...dataModalPlanification,
                        observation: e.target.value,
                      })
                    }}
                    class="form-control"
                    rows="7"
                    name="text"
                  ></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Engagement */}
      <div class="modal fade" id="myModalEngagement">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Engagement</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation:
                      document.getElementById('modalEngagement').value,
                    _id: dataModalEngagement._id,
                  },
                  'engagements',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataEngagement(
                      dataEngagement.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    value={
                      dataModalEngagement.observation
                        ? dataModalEngagement.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalEngagement({
                        ...dataModalEngagement,
                        observation: e.target.value,
                      })
                    }}
                    id="modalEngagement"
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Administratif */}
      <div class="modal fade" id="myModalAdministratif">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Administratif</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation:
                      document.getElementById('modalAdministratif').value,
                    _id: dataModalAdministration._id,
                  },
                  'administratifs',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataAdministratif(
                      dataAdministratif.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalAdministratif"
                    value={
                      dataModalAdministration.observation
                        ? dataModalAdministration.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalAdministration({
                        ...dataModalAdministration,
                        observation: e.target.value,
                      })
                    }}
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Securite */}
      <div class="modal fade" id="myModalSecurite">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Sécurité</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation: document.getElementById('modalSecurite').value,
                    _id: dataModalSecurite._id,
                  },
                  'securites',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataSecurite(
                      dataSecurite.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalSecurite"
                    value={
                      dataModalSecurite.observation
                        ? dataModalSecurite.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalSecurite({
                        ...dataModalSecurite,
                        observation: e.target.value,
                      })
                    }}
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Installation */}
      <div class="modal fade" id="myModalInstallation">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Installation chantier</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation:
                      document.getElementById('modalInstallation').value,
                    _id: dataModalInstallation._id,
                  },
                  'installationchantiers',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data.data) {
                    setDataInstallation(
                      dataInstallation.map((el) => {
                        if (el._id == res.data.data._id) {
                          return res.data.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalInstallation"
                    value={
                      dataModalInstallation.observation
                        ? dataModalInstallation.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalInstallation({
                        ...dataModalInstallation,
                        observation: e.target.value,
                      })
                    }}
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Effectif */}
      <div class="modal fade" id="myModalEffectif">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Effectifs</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                updateData(
                  {
                    observation: document.getElementById('modalEffectif').value,
                    _id: dataModalEffectif._id,
                  },
                  'effectifs',
                  infoUser.token
                ).then((res) => {
                  console.log(res)
                  if (res.data) {
                    setDataEffectif(
                      dataEffectif.map((el) => {
                        if (el._id == res.data._id) {
                          return res.data
                        } else {
                          return el
                        }
                      })
                    )
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}
            >
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalEffectif"
                    value={
                      dataModalEffectif.observation
                        ? dataModalEffectif.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalEffectif({
                        ...dataModalEffectif,
                        observation: e.target.value,
                      })
                    }}
                    name="text"
                  ></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Modal document */}
      <ModalDocument
        dataModalDocument={dataModalDocument}
        dataModalSuiviDoc={dataModalSuiviDoc}
        indiceDoc={indiceDoc}
        setDataDocument={setDataDocument}
        setDataModalSuiviDoc={setDataModalSuiviDoc}
        dataDocument={dataDocument}
      />

      {wait && (
        <>
          <div style={{ position: 'fixed', top: '40%', left: '55%' }}>
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default ProfilEntreprise
