import React, { useState } from 'react'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import { arrayMoveImmutable, arrayMoveMutable } from 'array-move'
const DragHandle = sortableHandle(() => <span>::</span>)

const SortableItem = sortableElement(({ value }) => (
  <div>
    <DragHandle />
    {value}
  </div>
))
const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>
})

const UpdateZoneLtp = ({ zone, bool, dispatch, UpdateZone, DeleteZone }) => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [items, setItems] = useState([
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6',
  ])
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems((items) => arrayMoveImmutable(items, oldIndex, newIndex))
    console.log(items);
  }
  return (
    <>
      <div class="modal" id="myModal1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Modification des zones</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body">
              {/* <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                {items.map((value, index) => (
                  <SortableItem
                    key={`item-${value}`}
                    index={index}
                    value={value}
                  />
                ))}
              </SortableContainer> */}
              {zone &&
                zone.map((z) => {
                  return (
                    <>
                      <div class="mb-3 mt-3 d-flex">
                        <input
                          type="text"
                          class="form-control"
                          value={z.label}
                          onChange={(e) => {
                            e.preventDefault()
                             UpdateZone(
                              z._id,
                              e.target.value,
                              infoUser.token
                            ).then((res) => {
                              dispatch({
                                type: 'bool/modifier',
                                payload: !bool,
                              })
                            }) 
                          }}
                        />
                        <button
                          className="btn btn-danger"
                          data-bs-dismiss="modal"
                          style={{ marginLeft: '2px' }}
                          onClick={(e) => {
                            e.preventDefault()
                            DeleteZone(z._id, infoUser.token).then((e) => {
                              dispatch({
                                type: 'bool/modifier',
                                payload: !bool,
                              })
                            })
                          }}
                        >
                          <i className="ti ti-trash"></i>
                        </button>
                      </div>
                    </>
                  )
                })}
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-dark"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateZoneLtp
