import React, { useEffect, useState } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'
import PageNotFound from '../../../components/404/PageNotFound'
const OffLine = () => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
    infoUser?.user?.role?.roleType != 'opc')
  ) {
    return <PageNotFound />
  }
 const [allObservation, setAllObservation] = useState(
    localStorage.getItem('horsLigne')
      ? JSON.parse(localStorage.getItem('horsLigne'))
      : []
  )
  const [allLot, setAllLot] = useState(
    localStorage.getItem('allLot')
      ? JSON.parse(localStorage.getItem('allLot'))
      : []
  )
  const dataTag = [
    {
      value: 'planning',
    },
    {
      value: 'engagement',
    },
    {
      value: 'securite',
    },
    {
      value: 'installation',
    },
  ]

  const [bool, setBool] = useState(false)
  const [listPlan, setListPlan] = useState(
    localStorage.getItem('listPlan')
      ? JSON.parse(localStorage.getItem('listPlan'))
      : []
  )
  const [alever, setAlever] = useState()
  const [nomObervation, setNomObservation] = useState('')
  const [nomPlan, setNomPlan] = useState('Pas de plan précisé')
  const [phase, setPhase] = useState('EXE')
  const [status, setStatus] = useState('designe')
  const [position, setPosition] = useState('')
  const [destinataire, setDestinataire] = useState([])
  const [description, setDescription] = useState('')
  const [observation, setObservation] = useState('')
  const [tag, setTag] = useState([])
  const [groupe, setGroupe] = useState('')
  const [trueGroupe, setTrueGroupe] = useState('Pas de goupe précisé')
  const [allGroupe, setAllGroupe] = useState([])
  //enregistrement du groupe
  const saveGroupe = () => {
    allGroupe.push(groupe)
    localStorage.setItem('allGroupe', JSON.stringify(allGroupe))
  }
  //formatter de date en fr

  //enregistrement de l'observation
  const handleSubmit = () => {
    console.log(alever)
    allObservation.push({
      nomPlan: nomPlan,
      //phase: phase,
      groupe: trueGroupe,
      status: status,
      observation: observation,
      //nomObservation: nomObervation,
      //description: description,
      position: position,
      destinataire: destinataire,
      aLeverLe: alever,
      tag: tag,
    })
    localStorage.setItem('horsLigne', JSON.stringify(allObservation))
    setBool(!bool)
    setStatus('désignée')
    setAlever(null)
  }

  //obtenir les groupes
  useEffect(() => {
    if (localStorage.getItem('allGroupe') == null) {
      setAllGroupe([])
    } else {
      setAllGroupe(JSON.parse(localStorage.getItem('allGroupe')))
    }
  }, [bool])

  //obtenir les observation
  useEffect(() => {
    if (localStorage.getItem('horsLigne') == null) {
      setAllObservation([])
    } else {
      setAllObservation(JSON.parse(localStorage.getItem('horsLigne')))
    }
  }, [bool])
  const navigate = useNavigate()
  //fonction pour formater les dates
  const DateFr = (date) => {
    const result = date.split('-')
    return `${result[2]}/${result[1]}/${result[0]}`
  }

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={false} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end">
                    <button
                      class="dropdown-toggle btn btn-success"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Mode
                      <i className="ti ti-selector"></i>
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/Observation_OffLine')
                        }}
                      >
                        <a
                          class="dropdown-item"
                          style={{ fontSize: '1.2vw', cursor: 'pointer' }}
                        >
                          Hors ligne
                        </a>
                      </li>
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/Observation_Plan')
                        }}
                      >
                        <a
                          class="dropdown-item"
                          style={{ fontSize: '1.2vw', cursor: 'pointer' }}
                        >
                          En ligne
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h4 class="page-title">Observation hors ligne</h4>
                </div>
              </div>
            </div>

            {/* Debut de l'observation hors ligne */}
            <div className="container-fluid">
              <button
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#myModal"
              >
                {' '}
                Ajouter une nouvelle observation
                <i className="ti ti-plus" style={{ marginLeft: '2vw' }}></i>
              </button>
              <button
                className="btn btn-primary"
                style={{ marginLeft: '10px' }}
                data-bs-toggle="modal"
                data-bs-target="#myModal1"
              >
                Ajouter un groupe{' '}
                <i className="ti ti-plus" style={{ marginLeft: '2vw' }}></i>
              </button>
              <button
                className="btn btn-primary"
                style={{ marginLeft: '10px' }}
                data-bs-toggle="modal"
                data-bs-target="#myModal2"
              >
                {' '}
                Liste Groupe{' '}
                <i className="ti ti-list" style={{ marginLeft: '2vw' }}></i>
              </button>
              <div
                className="container-fluid d-flex p-3"
                style={{ flexWrap: 'wrap' }}
              >
                {allObservation &&
                  allObservation.map((e) => {
                    return (
                      <>
                        <div className="card m-2" style={{ width: '390px' }}>
                          <div className="card-header">
                            <div className="row align-items-center">
                              <h5 className="text-center">
                                {e.nomPlan ? e.nomPlan : '...'}
                              </h5>
                            </div>
                          </div>
                          <div className="card-body">
                            <u>Groupe</u> : {e.groupe ? e.groupe : '...'} <br />
                            <u>Status</u> : {e.status=="designe" ? "Désignée" : e.status=="traite" ? "Traitée" : e.status=="annule" ? "Annulée": "..."} <br />
                            <u>Position</u> : {e.position ? e.position : '...'}{' '}
                            <br />
                            <u>Observation</u> :
                            {e.observation ? e.observation : '...'} <br />
                            <u>A lever le</u> :
                            {e.aLeverLe ? DateFr(e.aLeverLe) : '...'}
                          </div>
                          <div className="card-footer">
                            <button
                              className="btn btn-danger"
                              onClick={(el1) => {
                                el1.preventDefault()
                                localStorage.setItem(
                                  'horsLigne',
                                  JSON.stringify(
                                    allObservation.filter((el) => el != e)
                                  )
                                )
                                setBool(!bool)
                              }}
                            >
                              supprimer
                            </button>
                            {/* <button
                              className="btn btn-dark"
                              style={{ marginLeft: '10px' }}
                            >
                              Détails
                            </button> */}
                          </div>
                        </div>
                      </>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Liste Groupe */}
      <div class="modal" id="myModal2">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Lise des groupes</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div class="modal-body">
              {allGroupe &&
                allGroupe.map((e) => {
                  return (
                    <>
                      <div
                        className="d-flex mt-2"
                        style={{ justifyContent: 'space-between' }}
                      >
                        <h5> {e}</h5>
                        <button
                          className="btn btn-danger"
                          data-bs-dismiss="modal"
                          onClick={(el) => {
                            el.preventDefault()
                            localStorage.setItem(
                              'allGroupe',
                              JSON.stringify(allGroupe.filter((gp) => gp != e))
                            )
                            setBool(!bool)
                          }}
                        >
                          <i className="ti ti-archive"></i>
                        </button>
                      </div>
                    </>
                  )
                })}
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-dark"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Ajout d'un groupe */}
      <div class="modal" id="myModal1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Ajouter un groupe</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                saveGroupe()
                e.target.reset()
                setBool(!bool)
              }}
            >
              <div class="modal-body">
                <div class="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}
                  >
                    Nom du groupe:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    style={{ width: '98%' }}
                    placeholder="nom  du groupe"
                    onChange={(e) => {
                      setGroupe(e.target.value)
                    }}
                    required
                  />
                </div>
              </div>

              <div class="modal-footer">
                <button
                  className="btn btn-success"
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  fermer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Modal de création */}
      <div class="modal" id="myModal">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Observation</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                e.target.reset()
                handleSubmit()
              }}
            >
              <div class="modal-body">
                <div class="mb-3">
                  <label class="form-label" style={{ color: 'black' }}>
                    Nom du plan:
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setNomPlan(e.target.value)
                    }}
                  >
                    <option selected value="Pas de plan préciser">
                      {' '}
                      Sélectionner un plan
                    </option>
                    {listPlan &&
                      listPlan.map((el) => {
                        return (
                          <>
                            <option value={el.description}>
                              {el.description}
                            </option>
                          </>
                        )
                      })}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label" style={{ color: 'black' }}>
                    Groupe :
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      e.preventDefault()
                      setTrueGroupe(e.target.value)
                    }}
                  >
                    <option value="Pas de goupe préciser">
                      Sélectionner un groupe
                    </option>
                    {allGroupe &&
                      allGroupe.map((e) => {
                        return (
                          <>
                            <option value={e}>{e}</option>
                          </>
                        )
                      })}
                  </select>
                </div>
                {/* <div class="mb-3">
                  <label class="form-label" style={{ color: 'black' }}>
                    Nom de l'observation:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    style={{ width: '98%' }}
                    placeholder="Nom observation"
                    onChange={(e) => {
                      setNomObservation(e.target.value)
                    }}
                    required
                  />
                </div> */}
                {/* <div class="mb-3">
                  <label class="form-label" style={{ color: 'black' }}>
                    Phase:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    style={{ width: '98%' }}
                    placeholder="phase"
                    onChange={(e) => {
                      setPhase(e.target.value)
                    }}
                  />
                </div> */}
                <div class="mb-3">
                  <label class="form-label" style={{ color: 'black' }}>
                    Statut:
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                  >
                    <option value={status ? status : 'designe'}>
                      Désignée
                    </option>
                    <option value="traite">Traitée</option>
                    <option value="annule">Annulée</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}
                  >
                    <i class="ti ti-square-x" style={{ color: 'red' }}></i>{' '}
                    Observation:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    style={{ width: '98%' }}
                    placeholder="Observation"
                    onChange={(e) => {
                      setObservation(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}
                  >
                    <i class="ti ti-square-x" style={{ color: 'red' }}></i> Tag:
                  </label>
                  <Multiselect
                    displayValue="value"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck(e) {
                      setTag(e)
                    }}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck(e) {
                      setTag(e)
                    }}
                    options={dataTag}
                    selectedValues={tag}
                    placeholder="Tag"
                  />
                </div>
                <div class="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}
                  >
                    Position:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    style={{ width: '98%' }}
                    placeholder="Position"
                    onChange={(e) => {
                      setPosition(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}
                  >
                    <i class="ti ti-square-x" style={{ color: 'red' }}></i>{' '}
                    Destinataire:
                  </label>
                  <Multiselect
                    displayValue="titre"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck(e) {
                      setDestinataire(e)
                    }}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck(e) {
                      setDestinataire(e)
                    }}
                    options={[...allLot]}
                    selectedValues={destinataire}
                    placeholder="Destinataire(s)"
                  />
                </div>
                {/* <div class="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}
                  >
                    Descritpion:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    style={{ width: '98%' }}
                    placeholder="Description"
                    onChange={(e) => {
                      setDescription(e.target.value)
                    }}
                  />
                </div> */}
                <div class="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}
                  >
                    <i class="ti ti-square-x" style={{ color: 'red' }}></i> A
                    lever le:
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    style={{ width: '98%' }}
                    onChange={(e) => {
                      setAlever(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-dark"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default OffLine
