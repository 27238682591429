import React, { useState, useEffect } from 'react'
import '../../../../assets/plugins/select/selectr.min.js'
import Multiselect from 'multiselect-react-dropdown'
import { getRecap } from '../../../../helpers/planning/retard/retard.js'
import { updateResponsable } from '../../../../helpers/planning/retard/retard.js'
import { useDispatch, useSelector } from 'react-redux'
const Modal = ({ id, data, lot, responsables,idTache}) => {
  const dispatch = useDispatch()
  const [dataRecap, setDataRecap] = useState([{}])
  const bool = useSelector((state) => state.bool.bool)
  const [responsable, setResponsable] = useState(
    responsables ? responsables : []
  )
  const [observation, setObservation] = useState(
    data.tache?.observation ? data.tache?.observation : ''
  )
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  useEffect(() => {}, [dataRecap])
  useEffect(() => {
    setResponsable(responsables)
  }, [])
  const handleSubmit = () => {
    if (responsable) {
      updateResponsable(
        data.responsableEngage,
        responsable,
        observation,
        data._id,
        data.tache._id,
        data.dure_retard,
        infoUser.token
      ).then((e) => {
        dispatch({ type: 'bool/modifier', payload: !bool })
      })
    } else {
      updateResponsable(
        data.responsableEngage,
        responsables,
        observation,
        data._id,
        data.tache._id,
        data.dure_retard,
        infoUser.token
      ).then((e) => {
        dispatch({ type: 'bool/modifier', payload: !bool })
      })
    }
  }
  //formatter de date en fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }

  return (
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id={id}>
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Information sur le retard (tache :{' '}
              {data.tache ? data.tache.text : 'tache'})
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style={{ height: '70vh' }}>
            <div class="row">
              <div class="col-12">
                <ul
                  class="nav nav-tabs mb-3 nav-justified"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      id="general_chat_tab"
                      data-bs-toggle="tab"
                      href={`#general_chat${data._id}`}
                      role="tab"
                    >
                      Responsable(s)
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="group_chat_tab"
                      data-bs-toggle="tab"
                      href={`#group_chat${data._id}`}
                      role="tab"
                    >
                      Observation(s)
                    </a>
                  </li>
                  <li
                    class="nav-item"
                    role="presentation"
                    onClick={(e) => {
                      e.preventDefault()
                      getRecap(idTache, infoUser.token).then((el) => {
                        console.log(el.data.data)
                        if (el.data?.data) {
                          setDataRecap(el.data.data)
                        }
                      })
                    }}
                  >
                    <a
                      class="nav-link"
                      id="personal_chat_tab"
                      data-bs-toggle="tab"
                      href={`#personal_chat${data._id}`}
                      role="tab"
                    >
                      Récapitulatif des mails envoyés
                    </a>
                  </li>
                </ul>
                <div class="chat-body-left" data-simplebar>
                  <div class="tab-content chat-list" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id={`general_chat${data._id}`}
                    >
                      <div
                        className="conatiner-fluid"
                        style={{ height: '50vh' }}
                      >
                        <Multiselect
                          displayValue="titre"
                          onKeyPressFn={function noRefCheck() {}}
                          onRemove={function noRefCheck(e) {
                            setResponsable(e)
                          }}
                          onSearch={function noRefCheck() {}}
                          onSelect={function noRefCheck(e) {
                            setResponsable(e)
                          }}
                          options={lot}
                          selectedValues={data.lotResponsable}
                          placeholder="Responsable(s)"
                        />
                      </div>
                    </div>

                    <div class="tab-pane fade" id={`group_chat${data._id}`}>
                      <div className="conainer-fluid">
                        <div class="form-group">
                          <label for="exampleFormControlTextarea1">
                            Observations:
                          </label>
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            defaultValue={
                              data.tache?.observation
                                ? data.tache.observation
                                : ''
                            }
                            onChange={(e) => {
                              setObservation(e.target.value)
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id={`personal_chat${data._id}`}>
                      <div className="container-fluid mt-3">
                        <div className="table">
                          <table class="table table-striped" id="datatable">
                            <thead>
                              <tr>
                                <th>Date d'envoi</th>
                                <th>Destinataire(s)</th>
                                <th>Détails</th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataRecap &&
                                dataRecap.map((e) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          {e.dateEnvoie
                                            ? dateFr(e.dateEnvoie)
                                            : '...'}
                                        </td>
                                        <td>{e.destinataire?.email ? e.destinataire.email : "..."}</td>
                                        <td>
                                          {' '}
                                          <a
                                            class="text-center"
                                            href={`${process.env.REACT_APP_URL}/api/v1/mails/${e.filename}`}
                                            target="_blank"
                                          >Télécharger</a>{' '}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              className="btn btn-success"
              onClick={(e) => {
                e.preventDefault()
                handleSubmit()
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {' '}
              Enregistrer
            </button>
            <button
              class="btn btn-dark "
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
