import React from "react";
import Menu from "./components/Menu";
import Header from "./navBar/Header";
import Table from "./components/EditableTable";

const ChantierTemplate = () => {
  return (
    <>
      <div className="d-flex flex-column">
        <Menu />
        <Header />
        <Table />
      </div>
    </>
  );
};

export default ChantierTemplate;
