import React from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import PinMedia from './component/PinMedia'
import ImageMarker from 'react-image-marker';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import PageNotFound from '../../../components/404/PageNotFound';

//annotation du media
const Media = () => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
    infoUser?.user?.role?.roleType != 'opc')
  ) {
    return <PageNotFound />
  }

    const navigate = useNavigate()
    const { _id } = useParams();
    const [data, setData] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [image, setImage] = useState('');  
    const bool = useSelector((state) => state.bool.bool);
  
    useEffect(() => {
        axios
      .get(`${process.env.REACT_APP_URL}/api/v1/ressources/${_id}`,{
        headers: {
          Authorization: `Bearer ${infoUser.token}`
        },
      })
      .then((e) => {
        setData(e.data.data);
        setMarkers(e.data.data.pins);
      })
      .catch((e) => {
        console.log(e);
      });
    }, [bool]);
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="page-title-box">
              <div class="float-end"></div>
              <h4 class="page-title">Observation</h4>
            </div>
            <button className='btn btn-primary' onClick={(e)=>{
                navigate('/Observation_Plan')
            }} >Retour</button>
            <div className="container bg-dark mt-2">
            <ImageMarker
            src={`${process.env.REACT_APP_URL}/api/v1/ressources/?filename=${data.nomMedia}&format=media&accessToken=${infoUser.token}`}
            markers={markers}
            onAddMarker={(marker) => setMarkers([...markers, marker])}
            markerComponent={(e) => (
              <PinMedia props={e} idt={false} idImage={_id} />
            )}
          />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Media
