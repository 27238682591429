import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import { getAllCR, getAllFiche,updateCr,updatefiche } from '../../../helpers/rapport/rapport'
import PageNotFound from '../../../components/404/PageNotFound'
import BeatLoader from 'react-spinners/BeatLoader'
import { useToasts } from 'react-toast-notifications'
//import {crypto} from "crypto"
//page des comptes rendu et fiche de visite
const Rapport = () => {
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const {addToast} = useToasts()
  const navigate = useNavigate()
  const [bool,setBool]=useState(false)
  const [cr, setCr] = useState([])
  const [fiche, setFiche] = useState([])
  const [wait, setWait] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  console.log(infoChantier);
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const cryptage = (url) => {
    const cipher = crypto.createCipher(
      'aes-256-cbc',
      process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY
    )
    let encryptedUrl = cipher.update(url, 'utf8', 'hex')
    encryptedUrl += cipher.final('hex')
    return encryptedUrl
  }
  useEffect(() => {
    setWait(true)
    getAllCR(infoChantier.planning, infoUser.token).then((e) => {
      console.log(e)
      if (e.name == 'AxiosError') {
        setCr([])
      } else {
        setCr(e.data)
      }
    })
    getAllFiche(infoChantier.planning, infoUser.token).then((e) => {
      console.log(e)
      if (e.name == 'AxiosError') {
        setFiche([])
      } else {
        setFiche(e.data)
      }
      setWait(false)
    })
  }, [bool])
  //formatter de date en fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fuild">
            <div class="page-title-box">
              <div class="float-end"></div>
              <h4 class="page-title">Rapports</h4>
            </div>
            {/* debut rapport  */}
            <div class="row">
              <div class="col-lg-3">
                <div class="card">
                  <div class="card-header">
                    <div class="row align-items-center">
                      <div class="col">
                        <h4 class="card-title">Categories</h4>
                      </div>
                      <div class="col-auto"></div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="files-nav">
                      <div
                        class="nav flex-column nav-pills"
                        id="files-tab"
                        aria-orientation="vertical"
                      >
                        <a
                          class="nav-link active"
                          id="files-projects-tab"
                          data-bs-toggle="pill"
                          href="#files-projects"
                          aria-selected="true"
                        >
                          <i
                            data-feather="folder"
                            class="align-self-center icon-dual-file icon-sm me-2"
                          ></i>
                          <div class="d-inline-block align-self-center">
                            <h5 class="m-0">Comptes Rendus</h5>
                          </div>
                          <span class="badge bg-success ms-auto font-10">
                            {cr?.length ? cr.length : '0'}
                          </span>
                        </a>

                        <a
                          class="nav-link  align-items-center"
                          id="files-documents-tab"
                          data-bs-toggle="pill"
                          href="#files-documents"
                          aria-selected="false"
                        >
                          <i
                            data-feather="folder"
                            class="align-self-center icon-dual-file icon-sm me-2"
                          ></i>
                          <div class="d-inline-block align-self-center">
                            <h5 class="m-0">Fiches de visites</h5>
                          </div>
                          <span class="badge bg-success ms-auto font-10">
                            {fiche.length ? fiche.length : 0}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-9">
                <div class="">
                  <div class="tab-content" id="files-tabContent">
                    <div class="float-end">
                      {
                        (infoUser?.user?.role?.roleType == 'admin' ||
                        infoUser?.user?.role?.roleType == 'opc') && <>
                         <button
                        class="dropdown-toggle btn btn-primary"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Nouveau
                        <i className="ti ti-selector"></i>
                      </button>
                        </>
                      }
                     
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li
                          onClick={(e) => {
                            e.preventDefault()
                            navigate('/Rapport_NewCR')
                          }}
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1vw' }}
                          >
                            Comptes rendus
                          </a>
                        </li>
                        <li
                          onClick={(e) => {
                            e.preventDefault()
                            navigate('/Rapport_NewFiche')
                          }}
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1vw' }}
                          >
                            Fiches de visites
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="tab-pane fade show active" id="files-projects">
                      <h4 class="card-title mt-0 mb-3">Compte rendu</h4>
                      <div class="file-box-content">
                        {cr &&
                          cr.map((e) => {
                            return (
                              <>
                                <div class="file-box">
                                  <a href="#" class="download-icon-link">
                                    <i
                                      class="las la-bars file-download-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                    ></i>
                                    <ul class="dropdown-menu">
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href={`${process.env.REACT_APP_URL}/api/v1/comptesrendu/file/${e.fileToken}`}
                                          target="_blank"
                                        >
                                          Voir
                                        </a>
                                      </li>
                                      <li onClick={(el)=>{
                                        updateCr(e._id,infoUser.token).then((res)=>{
                                          console.log(res);
                                          if(res.name=='AxiosError'){
                                            addToast(res.response?.data?.message ? res.response.data.message : "Une erreur s'est produit" , {
                                              appearance: 'error',
                                              autoDismiss: true,
                                            })
                                          }else{
                                            addToast("Le compte rendu a été supprimé avec succès.", {
                                              appearance: 'success',
                                              autoDismiss: true,
                                            })
                                          }
                                          
                                          setBool(!bool)
                                        })
                                      }}>
                                        <a class="dropdown-item" href="#">
                                          Supprimer
                                        </a>
                                      </li>
                                    </ul>
                                  </a>
                                  <div className="text-center">
                                    <a
                                      class="text-center"
                                      href={`${process.env.REACT_APP_URL}/api/v1/comptesrendu/file/${e.fileToken}`}
                                      target="_blank"
                                    >
                                      <i class="lar la-file-pdf text-danger"></i>
                                      <h6 class="text-truncate">
                                        {e.nomFichier ? e.nomFichier : <>...</>}
                                      </h6>
                                      <small class="text-muted">
                                        {e.dateCR
                                          ? dateFr(e.dateCR)
                                          : '18-04-2023'}
                                      </small>
                                    </a>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                      </div>
                    </div>

                    <div class="tab-pane fade" id="files-documents">
                      <h4 class="mt-0 card-title mb-3">Fiche de visite</h4>
                      <div class="file-box-content">
                        {fiche.map((e) => {
                          return (
                            <>
                              <div class="file-box">
                                <a class="download-icon-link">
                                  <i
                                    class="las la-bars file-download-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                  ></i>
                                  <ul class="dropdown-menu">
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        href={`${process.env.REACT_APP_URL}/api/v1/visites/file/${e.fileToken}`}
                                        target="_blank"
                                      >
                                        voir
                                      </a>
                                    </li>
                                    <li onClick={(el)=>{
                                      updatefiche(e._id,infoUser.token).then((res)=>{
                                        console.log(res);
                                        if(res.name=='AxiosError'){
                                          addToast(res.response?.data?.message ? res.response.data.message : "Une erreur s'est produit" , {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }else{
                                          addToast("La fiche de visite a été supprimée avec succès.", {
                                            appearance: 'success',
                                            autoDismiss: true,
                                          })
                                        }
                                        setBool(!bool)
                                      })
                                    }}>
                                      <a class="dropdown-item" href="#">
                                        Supprimer
                                      </a>
                                    </li>
                                  </ul>
                                </a>
                                <div className="text-center">
                                  <a
                                    class="text-center"
                                    href={`${process.env.REACT_APP_URL}/api/v1/visites/file/${e.fileToken}`}
                                    target="_blank"
                                  >
                                    <i class="lar la-file-pdf text-danger"></i>
                                    <h6 class="text-truncate">
                                      {e.nomDocument ? e.nomDocument : <>...</>}
                                    </h6>
                                    <small class="text-muted">
                                      {e.dateExport
                                        ? dateFr(e.dateExport)
                                        : '18/04/2023'}
                                    </small>
                                  </a>
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>

                    <div class="tab-pane fade" id="files-hide">
                      <h4 class="mt-0 card-title mb-3">Hide</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* fin rapport */}
          </div>
        </div>
      </div>
      {wait && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '40%',
              left: '55%',
              zIndex: '10000',
            }}
          >
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default Rapport
