import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import 'react-tabulator/lib/styles.css'
import 'tabulator-tables/dist/css/tabulator_bootstrap3.min.css'
import { ReactTabulator } from 'react-tabulator'
import BeatLoader from 'react-spinners/BeatLoader'
import {
  getAllContact,
  updateContact,
  delContact,
  addContact,
  getUser,
  addIntervenant,
} from '../../../helpers/contact/contact'
import { updateData, getAllData, getData } from './../../../helpers/suivi/suivi'
import { getAllRole } from '../../../helpers/role/role'
import PageNotFound from '../../../components/404/PageNotFound'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
const Contacte = () => {
  const {addToast} = useToasts()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const [contact, setContact] = useState([])
  const [bool, setBool] = useState(false)
  const [wait, setWait] = useState(false)
  const [users, setUsers] = useState([])
  const [intervenant, setIntervenant] = useState([])
  const [idIntervenant, setIdIntervenant] = useState()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)

  useEffect(() => {
    setWait(true)
    getUser(infoChantier.intervenants?._id, infoUser.token).then((e) => {
      setIntervenant(e.data.info.intervenants)
      setIdIntervenant(e.data.info._id)
      setUsers(e.data.result)
    })
    getAllData(`planning=${infoChantier.planning}`, 'lots', infoUser.token)
      .then((result) => {
        const contacts = result.data.reduce(
          (accumulator, { _id, titre, entreprise }) => {
            const { responsable } = entreprise
            responsable.entreprise = entreprise
            responsable.lot = titre
            responsable.idLot = _id
            const exist = accumulator.find((res) => res._id === responsable._id)
            if (!exist) {
              accumulator.push(responsable)
            }
            return accumulator
          },
          []
        )
        getData(infoChantier.opc, 'users', infoUser.token)
          .then((opc) => {
            setContact([...contacts])
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .finally((res) => {
        setWait(false)
      })
  }, [bool])
  const delRow = (cell) => {
    const inters = intervenant.filter(
      (e) => e.intervenant != cell.getData()._id
    )
    addIntervenant(inters, idIntervenant, infoUser.token).then((el) => {
      if(el.name=='AxiosError'){
        addToast(el.response?.data?.message ? el.response.data.message : "Une erreur s'est produit" , {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      setBool(!bool)
    })
  }
  const buttons = (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-trash')

    //create delete button
    var delBt = document.createElement('button')
    delBt.type = 'button'
    delBt.appendChild(delIcon)
    delBt.classList.add('btn')
    delBt.classList.add('btn-outline-danger')
    delBt.addEventListener('click', function () {
      delRow(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)

    return buttonHolder
  }

  const columns = [
    {
      title: 'Rôle',
      field: 'role.roleName',
      editor: 'select',
      editorParams: {
        values: {
          'Entreprise de construction': 'Entreprise de construction',
        },
      },
    },
    { title: 'Lot', field: 'lot', editor: 'input' },
    { title: 'Société', field: 'entreprise.nom', editor: 'input' },
    {
      title: 'Nom',
      field: 'name',
      editor: 'input',
    },
    {
      title: 'Prénom',
      field: 'lastname',
      editor: 'input',
    },
    {
      title: 'Email',
      field: 'email',
      editor: 'input',
    },
    {
      title: 'Téléphone',
      field: 'telephone',
      editor: 'input',
    },
    {
      title: '',
      field: 'functionButton',
      formatter: buttons,
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]
  const columnUser = [
    {
      title: 'Rôle',
      field: 'role.roleName',
      editor: 'select',
      editorParams: {
        values: {
          'Maitrise d’ouvrage': 'Maitrise d’ouvrage',
          'Maitrise d’œuvre': 'Maitrise d’œuvre',
          'Assistance à maitrise d’ouvrage': 'Assistance à maitrise d’ouvrage',
          Architecte: 'Architecte',
          'Maitrise d’œuvre d’exécution': 'Maitrise d’œuvre d’exécution',
          'D.E.T.': 'D.E.T.',
          'O.P.C.': 'O.P.C.',
          'Bureau d’études techniques': 'Bureau d’études techniques',
          'Bureau de contrôle': 'Bureau de contrôle',
          'Coordonnateur SPS': 'Coordonnateur SPS',
          'Coordonnateur SSI': 'Coordonnateur SSI',
          //ent: 'Entreprise de construction',
          'Utilisateur simple': 'Utilisateur simple',
        },
      },
    },
    {
      title: 'Société',
      field: 'entrepriseName',
      editor: 'input',
    },
    {
      title: 'Nom',
      field: 'name',
      editor: 'input',
    },
    {
      title: 'Prénom',
      field: 'lastname',
      editor: 'input',
    },
    {
      title: 'Email',
      field: 'email',
      editor: 'input',
    },
    {
      title: 'Téléphone',
      field: 'telephone',
      editor: 'input',
    },
    {
      title: '',
      field: 'functionButton',
      formatter: buttons,
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {},
    },
  ]

  const edited = async (cell) => {
    console.log(cell.getData())
    const { ...cellData } = cell.getData()
    const { roleName } = cellData.role
    console.log(roleName)
    const { data } = await getAllRole(`roleName=${roleName}`, infoUser.token).then((res)=>{
      console.log(res);
      return res
    })
    console.log(data)
    if (!data || data.length === 0) {
      setBool(!bool)
    }
    const { idLot } = cellData
    const { lot } = cellData
    const { entreprise } = cellData
    if (lot) {
      await updateData({ lot: lot, _id: idLot }, 'lots', infoUser.token).then(
        (e) => {
          console.log(e)
         /*  if(e.name=='AxiosError'){
            addToast(e.response?.data?.message ? e.response.data.message : "Une erreur s'est produit" , {
              appearance: 'error',
              autoDismiss: true,
            })
          } */
        }
      )
    }
    if (entreprise) {
      await updateData(entreprise, `entreprises`, infoUser.token).then((e) => {
        console.log(e);
      })
    }
    cellData.role = data[0]._id
    updateContact(cellData, infoUser.token).then((e) => {
      console.log(e)
      if (e.name == 'AxiosError') {
          addToast(e.response?.data?.message ? e.response.data.message : "Une erreur s'est produit" , {
            appearance: 'error',
            autoDismiss: true,
          })
        setBool(!bool)
      }
    })
  }

  const addRow = async () => {
    setWait(true)
    const { data } = await getAllRole(`roleType=user`, infoUser.token)
    if (!data || data.length === 0) {
      alert('Error: No role found')
    }
    await axios
      .get(
        `${process.env.REACT_APP_MDP_API}/create/?include_digits&include_uppercase&include_lowercase&password_length=8&quantity=1&add_custom_characters=!@#%?`
      )
      .then((e) => {
        const formData = new FormData()
        formData.append('email', `newuser${Date.now()}@mail.com`)
        formData.append('role', data[0]._id)
        formData.append('telephone', 'Non défini')
        formData.append('lastname', 'Non défini')
        formData.append('name', 'Non défini')
        formData.append('entrepriseName', 'Non défini')
        formData.append('password', e.data.passwords[0])
        formData.append('passwordConfirm', e.data.passwords[0])
        addContact(formData, infoUser.token)
          .then((e) => {
            console.log('eto')
            console.log(e)
            const inters = []
            inters.push(...intervenant)
            inters.push({ intervenant: e._id })
            console.log(inters)
            addIntervenant(inters, idIntervenant, infoUser.token)
              .then((el) => {
                setWait(false)
                setBool(!bool)
              })
              .catch((e) => {
                setWait(false)
              })
          })
          .catch((e) => {
            setWait(false)
          })
      })
  }

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <h4 className="page-title">Contacts</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Nos contacts</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="mb-2 d-flex flex-row-reverse">
                        {(infoUser.user?.role?.roleType == 'admin') |
                          (infoUser.user?.role?.roleType == 'opc') ? (
                          <>
                            <button
                              class="btn btn-outline-primary align-sel btn-sm mb-1 mb-xl-0"
                              onClick={() => addRow()}
                            >
                              <i class="ti ti-plus"></i>Nouveau contact
                            </button>
                          </>
                        )  : ""}
                      </div>
                      <h5>
                        {' '}
                        <u>Entreprises</u>{' '}
                      </h5>
                      <ReactTabulator
                        data={contact}
                        columns={columns}
                        height={'310px'}
                        layout={'fitColumns'}
                        events={{ cellEdited: edited }}
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <h5>
                        {' '}
                        <u>Intervenants</u>{' '}
                      </h5>
                      <ReactTabulator
                        data={users && users.map((el)=>{
                          if(el.role?.roleType=="admin" ||  el.role?.roleType=="ent"){                   
                          }else{
                            return el
                          }
                        })}
                        columns={columnUser}
                        height={'310px'}
                        layout={'fitColumns'}
                        events={{ cellEdited: edited }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {wait && (
        <>
          <div style={{ position: 'absolute', top: '45%', left: '50%' }}>
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default Contacte
