import React, { useState, useEffect } from 'react'
import logo from '../../assets/images/Kolos_logo/Kolos Logo-long-new.png'
import { connexion } from '../../helpers/connexion/connexion'
import { useNavigate } from 'react-router-dom'
import BeatLoader from 'react-spinners/BeatLoader'

//Page de connexion à SOODA
const Connexion = () => {
  const navigate = useNavigate()
  const [notFound, setNotFound] = useState(false)
  const [souvenir, setSouvenir] = useState(localStorage.getItem('souvenir')||false)
  const [email, setEmail] = useState(localStorage.getItem("email"))
  const [password, setPassword] = useState(localStorage.getItem('password'))
  const [erreur, setErreur] = useState(false)
  const [loading, setLoading] = useState(false)
  //fonction pour la connexion de l'utilisateur
  const handleSubmit = () => {
    setLoading(true)
    const data = { email: email, password: password }
    connexion(data).then((e) => {
      if (!e.status) {
        setErreur(true)
        setLoading(false)
      } else {
        if (e.status == 'success') {
          sessionStorage.clear()
          localStorage.setItem('users', JSON.stringify(e))
          localStorage.setItem('isLogin', true)
          if (souvenir) {
            localStorage.setItem('email', email)
            localStorage.setItem('password', password)
            localStorage.setItem('souvenir', true)
          }else{
            localStorage.removeItem('email')
            localStorage.removeItem('password')
            localStorage.removeItem('souvenir')
          }
          setLoading(false)
          navigate('/accueil')
        }
        setLoading(false)
        setErreur(true)
      }
    })
  }

  return (
    <>
      {loading && (
        <>
          <div
            className="container-fluid d-flex"
            style={{
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              zIndex: '2000',
              backgroundColor: 'rgba(104, 93, 119, 0.7)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BeatLoader color="yellowgreen" loading={true} size={50} />
          </div>
        </>
      )}
      <div class="container-md">
        <div class="row vh-100 d-flex justify-content-center">
          <div class="col-12 align-self-center">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 mx-auto">
                  <div class="card">
                    <div class="card-body p-0 auth-header-box bg-dark">
                      <div class="text-center p-4 mt-2">
                        <a href='#' class="logo logo-admin">
                          <img
                            src={logo}
                            height="50"
                            alt="logo"
                            class="auth-logo"
                          />
                        </a>
                        <p class="text-muted  mb-0 mt-2">Se connecter</p>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <form
                        class="my-4"
                        onSubmit={(e) => {
                          e.preventDefault()
                          handleSubmit()
                        }}
                      >
                        {erreur && (
                          <>
                            <div
                              class="alert alert-danger border-0 text-center"
                              role="alert"
                            >
                              <strong>
                                {' '}
                                Email ou mot de passe incorrecte!
                              </strong>
                            </div>
                          </>
                        )}
                        <div class="form-group mb-2">
                          <label class="form-label" for="username">
                            Utilisateur
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            id="username"
                            name="username"
                            placeholder="Nom d'utilisateur"
                            defaultValue={localStorage.getItem('email')}
                            onChange={(e) => {
                              setErreur(false)
                              setEmail(e.target.value)
                            }}
                            required
                          />
                        </div>

                        <div class="form-group">
                          <label class="form-label" for="userpassword">
                            Mot de passe
                          </label>
                          <input
                            type="password"
                            class="form-control"
                            name="password"
                            id="userpassword"
                            defaultValue={localStorage.getItem('password')}
                            placeholder="mot de passe"
                            onChange={(e) => {
                              setErreur(false)
                              setPassword(e.target.value)
                            }}
                            required
                          />
                        </div>
                        <div class="form-group row mt-3">
                          <div class="col-sm-6">
                            <div class="form-check form-switch form-switch-success">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="customSwitchSuccess"
                                onChange={(e) => {
                                  setSouvenir(e.target.checked)
                                }}
                                defaultChecked={
                                  localStorage.getItem('souvenir') || false
                                }
                              />
                              <label
                                class="form-check-label"
                                for="customSwitchSuccess"
                              >
                                Se souvenir de moi
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-6 text-end">
                            <a href="#" class="text-muted font-13" onClick={(e)=>{
                              localStorage.removeItem('email')
                              localStorage.removeItem('password')
                              localStorage.removeItem('souvenir')
                              navigate('/Recuperation_MDP')
                            }}>
                              <i class="dripicons-lock"></i> Mot de passe
                              oublié?
                            </a>
                          </div>
                        </div>

                        <div class="form-group mb-0 row">
                          <div class="col-12">
                            <div class="d-grid mt-3">
                              <button class="btn btn-primary" type="submit">
                                Se connecter{' '}
                                <i class="fas fa-sign-in-alt ms-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div class="m-3 text-center text-muted">
                      </div>
                      <hr class="hr-dashed mt-4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {notFound && <></>}
    </>
  )
}

export default Connexion
