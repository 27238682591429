import React, { useState, useEffect } from 'react'
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css'
import { initScheduler } from '../../../helpers/planning/gestionTache/gantt'
import { useNavigate } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { scheduler } from 'dhtmlx-scheduler'
import { useSelector, useDispatch } from 'react-redux'
import { ReactTabulator } from 'react-tabulator'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import FormatPdfLtp from './component/FormatPdfLtp'
import UpdateZoneLtp from './component/UpdateZoneLtp'
import {
  getAllEvent,
  addEvent,
  addZone,
  getAllZone,
  delEvent,
  updateEvent,
  getAllLotGlissant,
  UpdateZone,
  DeleteZone,
  shareEvent,
} from '../../../helpers/planning/gestionTache/dataScheduler'
import { getDataPresence } from '../../../helpers/suivi/suivi'
//Page d'affichage du las planner system

const LastPlanner2 = ({ zoom, planning, btnPartage, updateBtnPartage }) => {
  const bool = useSelector((state) => state.bool.bool)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc')
  ) {
    return <PageNotFound />
  }

  const dispatch = useDispatch()
  scheduler.plugins({
    multiselect: true,
    multisection: true,
    timeline: true,
    minical: true,
    daytimeline: true,
    limit: true,
    outerdrag: true,
    drag_between: true,
  })
  scheduler.xy.bar_height = 37
  const navigate = useNavigate()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [zone, setZone] = useState([])
  const [wait, setWait] = useState(false)
  const [bools, setBools] = useState(false)
  const [newZone, setNewZone] = useState('')
  const [format, setFormat] = useState(true)
  const [legende, setLegende] = useState([])
  const [dataPresence, setDataPresence] = useState([])
  const [destinataire, setDestinataire] = useState([])
  if (!scheduler._onEventSave) {
    scheduler._onEventSave = scheduler.attachEvent(
      'onEventSave',
      function (id, ev, is_new) {
        const planningLP = localStorage.getItem('infoChantiers')
        const planningReel = JSON.parse(planningLP)
        var ev1 = scheduler.getEvent(id)
        if (ev1._id) {
          updateEvent(ev1._id, ev, infoUser.token).then((e) => {
            console.log('save update')
            console.log(ev)
            setBools(!bools)
            dispatch({ type: 'bool/modifier', payload: false })
          })
        } else {
          addEvent(planningReel.planning, ev, infoUser.token).then((e) => {
            setBools(!bools)
            dispatch({ type: 'bool/modifier', payload: false })
          })
        }
        return true
      }
    )
  }
  if (!scheduler._onBeforeEventChanged) {
    scheduler._onBeforeEventChanged = scheduler.attachEvent(
      'onBeforeEventChanged',
      function (ev, e, is_new, original) {
        console.log('update')
        var ev1 = scheduler.getEvent(original.id)
        console.log(ev1)
        console.log(original)
        if (ev._id) {
          updateEvent(ev._id, ev, infoUser.token)
            .then((e) => {})
            .catch((er) => {
              console.log(er)
              alert("Une erreur s'est produit, veuillez réssayer!")
            })
          return true
        } else {
          return true
        }
      }
    )
  }
  if (!scheduler._onBeforeEventDelete) {
    scheduler._onBeforeEventDelete = scheduler.attachEvent(
      'onBeforeEventDelete',
      function (id, ev) {
        console.log('suppr')
        delEvent(ev._id, infoUser.token).then((e) => {
          setBools(!bools)
          dispatch({ type: 'bool/modifier', payload: false })
        })
        return true
      }
    )
  }

  const checkbox = (cell) => {
    console.log(destinataire)
    const candidate = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
      if (cell.getValue()) {
        candidate.envoyerEmailLp = true
        console.log(candidate.envoyerEmailLp)
      } else {
        candidate.envoyerEmailLp = false
        console.log(candidate.envoyerEmailLp)
      }
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }

  const colonneDestinataire = [
    { title: 'Nom', field: 'name' },
    { title: 'Prénom', field: 'lastname' },
    { title: 'Entreprise', field: 'entrepriseName' },
    { title: 'Email', field: 'email' },
    {
      title: '',
      field: 'envoyerEmailLp',
      formatter: checkbox,
    },
  ]

  //formatter de date en fr
  const dateFr = (date) => {
    var formattedDate =
      (date.getDate() < 10 ? '0' : '') +
      date.getDate() +
      '/' +
      (date.getMonth() < 9 ? '0' : '') +
      (date.getMonth() + 1) +
      '/' +
      date.getFullYear()
    return `Le ${formattedDate}`
  }
  const printContent = () => {
    const original = document.body.innerHTML
    //nouveau format
    const cible = document.getElementById('cibles')
    const navline = document.querySelector('.dhx_cal_navline')
    const header = document.querySelector('.dhx_cal_header')
    const dataPlanner = document.querySelector('.dhx_timeline_table_wrapper')
    dataPlanner.style = 'position:absolute;top:100px;width:100%'
    const containerData = document.createElement('div')
    containerData.style =
      'width:140%;position:relative;background-color:white;z-index:222222222222'
    cible.appendChild(containerData)
    containerData.appendChild(navline)
    containerData.appendChild(header)
    containerData.appendChild(dataPlanner)
    const container = document.querySelector('.hide-format')
    container.style = 'display:block'
    document.body.innerHTML = ''
    document.body.style = 'background-color:white'
    document.body.appendChild(container)
    window.print()
    //console.log(document.body)
    document.body.innerHTML = original
    location.reload()
  }
  useEffect(() => {
    getDataPresence(infoChantier.planning, infoUser.token).then((e) => {
      if (e.responsables) {
        setDataPresence(e.responsables)
      }
    })
  }, [])
  useEffect(() => {
    setWait(true)
    if (infoChantier.planning) {
      getAllEvent(infoChantier.planning, infoUser.token).then((datas) => {
        getAllZone(infoChantier.planning, infoUser.token).then((zones) => {
          console.log(zones)
          setZone(zones)
          getAllLotGlissant(infoChantier.planning, infoUser.token).then(
            (lot) => {
              setLegende(lot)
              initScheduler(scheduler, 'schedule', datas, lot, [
                ...zones,
                { label: 'Non attribuées', key: '0' },
              ])
              const events = scheduler.getEvents()
              events.map((e) => {
                for (var i = 0; i < lot.length; i++) {
                  if (lot[i].key === e.lot) {
                    e.color = lot[i].color
                    break
                  }
                }
              })
              setWait(false)
            }
          )
        })
      })
    } else {
    }
  }, [bools, bool])

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexWrap: 'no-wrap',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '60%', display: 'flex', alignItems: 'center' }}>
          {zoom && (
            <button
              class="dropdown-toggle btn btn-primary"
              type="button"
              onClick={(e) => {
                navigate('/planning_GestionTache')
              }}
            >
              Zoom par défaut
            </button>
          )}
          <button
            className="btn btn-primary"
            style={{ marginLeft: '0.5vw' }}
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Affichage <i className="ti ti-selector"></i>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li
              onClick={(e) => {
                e.preventDefault()
                scheduler.setCurrentView(new Date(), 'month')
              }}
            >
              <a class="dropdown-item" href="#" style={{ fontSize: '1.2vw' }}>
                Mois
              </a>
            </li>
            <li
              onClick={(e) => {
                e.preventDefault()
                scheduler.setCurrentView(new Date(), 'timeline')
              }}
            >
              <a class="dropdown-item" href="#" style={{ fontSize: '1.2vw' }}>
                Semaine
              </a>
            </li>
          </ul>
          <button
            className="btn btn-success"
            style={{ marginLeft: '0.5vw' }}
            data-bs-toggle="modal"
            data-bs-target="#myModal"
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            Ajouter une zone
            <i className="ti ti-plus"></i>
          </button>
          <div class="modal" id="myModal">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Ajouter une zone</h4>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    e.target.reset()
                  }}
                >
                  <div class="modal-body">
                    <div class="mb-3 mt-3">
                      <label for="email" class="form-label">
                        Nouvelle zone:
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        onChange={(e) => {
                          e.preventDefault()
                          setNewZone(e.target.value)
                        }}
                        required
                      />
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      type="submit"
                      class="btn btn-success"
                      data-bs-dismiss="modal"
                      onClick={(e) => {
                        addZone(
                          infoChantier.planning,
                          newZone,
                          infoUser.token
                        ).then((e) => {
                          setBools(!bools)
                          dispatch({ type: 'bool/modifier', payload: !bool })
                        })
                      }}
                    >
                      Enregistrer
                    </button>
                    <button
                      type="button"
                      class="btn btn-dark"
                      data-bs-dismiss="modal"
                    >
                      Fermer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <button
            className="btn btn-primary"
            style={{ marginLeft: '0.5vw' }}
            data-bs-toggle="modal"
            data-bs-target="#myModal1"
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            Liste des zones
            <i className="ti ti-list"></i>
          </button>
          {btnPartage && (
            <div
              className="d-flex"
              style={{ paddingTop: '3px', marginLeft: '3px' }}
            >
              <div className="btn">
                <span style={{ paddingTop: '2px' }} className="mt-2">
                  Partager:
                </span>
              </div>
              <label class="switch p-1" style={{ position: 'relative' }}>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    scheduler.setCurrentView(new Date(), 'timeline')
                    if (e.target.checked) {
                      updateBtnPartage(true)
                    } else {
                      updateBtnPartage(false)
                    }
                  }}
                />
                <span class="slider round"></span>
              </label>
            </div>
          )}
          {/*Modal pour la modification et suppression d'une zone*/}
          <UpdateZoneLtp
            zone={zone}
            bool={bool}
            dispatch={dispatch}
            UpdateZone={UpdateZone}
            DeleteZone={DeleteZone}
          />
          <div class="modal" id="myModal2">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Liste des destinataires</h4>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                  ></button>
                </div>
                <div class="modal-body">
                  <h5>Sélectionner les destinataires</h5>
                  {/* <div className="d-flex flex-row-reverse">
                    <div className='form-check form-switch form-switch-primary mt-2' style={{position:'absolute',zIndex:'2000000',right:'125px'}}>
                    <input type="text" className='form-check-input' />
                    </div>                  
                  </div> */}
                  <ReactTabulator
                    data={dataPresence}
                    columns={colonneDestinataire}
                    layout={'fitColumns'}
                    options={{
                      locale: true,
                      pagination: 'local',
                      paginationSize: 5,
                      langs: {
                        fr: {
                          pagination: {
                            first: 'Premier',
                            first_title: 'Première page',
                            last: 'Dernier',
                            last_title: 'Dernière page',
                            prev: 'Précédent',
                            prev_title: 'Page précédente',
                            next: 'Suivant',
                            next_title: 'Page suivante',
                            page_size: 'Taille de page',
                            page_title: 'Afficher la page',
                            all: 'Tous',
                          },
                        },
                      },
                    }}
                  />
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={(e) => {
                      e.preventDefault()
                      console.log(destinataire)
                      const garde = document.querySelector(
                        '.pageGardeLastPlanner'
                      )
                      const corps = document.createElement('div')
                      corps.style =
                        'width:3000px;position:relative;z-index:222222222222222222222;background-color:white'
                      const navPlanner =
                        document.querySelector('.dhx_cal_navline')
                      const headPlanner =
                        document.querySelector('.dhx_cal_header')
                      const dataPlanner = document.querySelector(
                        '.dhx_timeline_table_wrapper'
                      )
                      dataPlanner.style =
                        'position:absolute;top:110px;left:0;width:140%;background-color:white'
                      corps.appendChild(navPlanner)
                      corps.appendChild(headPlanner)
                      corps.appendChild(dataPlanner)
                      console.log(corps.innerHTML)
                      shareEvent(
                        infoChantier.planning,
                        corps.innerHTML,
                        garde.innerHTML,
                        dataPresence,
                        infoUser.token
                      ).then((res) => {
                        console.log(res)
                        alert("L'email a été envoyé avec succès")
                        location.reload()
                      })
                    }}
                    data-bs-dismiss="modal"
                  >
                    Envoyer
                  </button>
                  <button
                    type="button"
                    class="btn btn-dark"
                    data-bs-dismiss="modal"
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            width: '30%',
          }}
        ></div>

        {zoom && (
          <>
            <button
              className="btn btn-primary"
              style={{ marginRight: '10px' }}
              data-bs-toggle="modal"
              data-bs-target="#myModal2"
              onClick={(e) => {
                e.preventDefault()
              }}
            >
              Partager
            </button>
            <button
              className="btn btn-primary"
              style={{ marginRight: '10px' }}
              onClick={(e) => {
                e.preventDefault()
                printContent()
                setWait(true)
              }}
            >
              Exporter
            </button>
          </>
        )}
      </div>
      <div className="container-fluid">
        <div
          id="schedule"
          class="dhx_cal_container"
          style={{ width: '100%', height: '80vh' }}
        >
          <div class="dhx_cal_navline">
            <div
              class="dhx_cal_prev_button"
              onClick={(e) => {
                e.preventDefault()
                console.log(scheduler.getState().mode)
                if (scheduler.getState().mode == 'timeline') {
                  scheduler.setCurrentView(
                    new Date(scheduler.getState().date.valueOf() + 86400000),
                    scheduler.getState().mode
                  )
                }
              }}
            ></div>
            <div
              class="dhx_cal_next_button"
              onClick={(e) => {
                e.preventDefault()
                console.log(scheduler.getState().mode)
                if (scheduler.getState().mode == 'timeline') {
                  scheduler.setCurrentView(
                    new Date(scheduler.getState().date.valueOf() - 86400000),
                    scheduler.getState().mode
                  )
                }
              }}
            ></div>
            <div class="dhx_cal_today_button">Aujourd'hui</div>
            <div class="dhx_cal_date"></div>
          </div>
          <div class="dhx_cal_header"></div>
          <div class="dhx_cal_data"></div>
        </div>
      </div>
      {wait && (
        <>
          <div
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              display: 'flex',
              top:'0',
              left:"0",
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: '200000000000000',
            }}
          >
            <div>
              <BeatLoader color="yellowgreen" loading={true} size={25} />
            </div>
          </div>
        </>
      )}

      {format && (
        <>
          <FormatPdfLtp legende={legende} />
        </>
      )}
    </>
  )
}

export default LastPlanner2
