import axios from 'axios'
export const getUser = async (id,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/intervenants/${id}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}
export const addIntervenant = async (candidate,id,token) => {
  console.log(candidate);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
   const result = await axios.patch(
      `${process.env.REACT_APP_URL}/api/v1/intervenants/${id}`,
      {_id:id,intervenants:candidate},config
    ).then((res)=>{
      return res
    }).catch((err)=>{
      return err
    })
    return result
  } catch (err) {
    return { message: 'erreur',erreur:err }
  }
}


export const addContact = async (candidate,token) => {
    const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_URL}/api/v1/users`,
      candidate,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      }
    )
    return data.data
  } catch (err) {
    console.log(err);
    return { message: 'erreur' }
  }
}
export const updateContact = async (data,token) => {

  try {
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/users/${data._id}`, {
        name:data.name,
        lastname:data.lastname,
        telephone:data.telephone,
        email:data.email,
        role:data.role,
        entrepriseName:data.entrepriseName
      },{
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      })
    return result.data
  } catch (err) {
    return err
  }
}

export const getAllContact = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/users`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const delContact = async (data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    //console.log(data._id)
    const result = await axios
      .delete(`${process.env.REACT_APP_URL}/api/v1/users/${data._id}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}
