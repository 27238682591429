import React, { useEffect, useState } from 'react'
import Menu from '../../components/navBar/Menu'
import Header from '../../components/navBar/Header'
import photoUser from '../../assets/images/sooda_logo/default.png'
import { useToasts } from 'react-toast-notifications'
import {
  getOneUser,
  updateUserInfo,
  updateUserSociete,
  getChantierUser,
  updateChantierUser,
} from '../../helpers/user/user'
import axios from 'axios'
import { Navigate, useNavigate } from 'react-router-dom'
import BeatLoader from 'react-spinners/BeatLoader'
//page profil utilisateur
const Profil = () => {
  const {addToast} = useToasts()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const navigate = useNavigate()
  const [date, setDate] = useState()
  const [data, setData] = useState({})
  const [nom, setNom] = useState('')
  const [prenom, setPrenom] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [societe, setSociete] = useState('')
  const [bool, setBool] = useState(false)
  const InfoUsers = localStorage.getItem('users')
  const users = JSON.parse(InfoUsers)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [listChantier, setListChantier] = useState([])
  console.log(users)
  const [wait, setWait] = useState(false)
  useEffect(() => {
    setWait(true)
    getChantierUser(users?.user?._id, users.token).then((e) => {
      console.log(e)
      if (e.data) {
        setListChantier(e.data)
      }
    })
  }, [bool])
  useEffect(() => {
    setWait(true)
    getOneUser(users?.user?._id, users.token).then((e) => {
      if (e.data) {
        setData(e.data)
        setPrenom(e.data?.lastname)
        setNom(e.data.name)
        setEmail(e.data.email)
        setTel(e.data.telephone)
        setSociete(e.data.entrepriseName)
        setWait(false)
      }
    })
  }, [bool])
  //enregistrement des informations personnelles
  const savePerso = () => {
    setWait(true)
    updateUserInfo(users.user?._id, nom, prenom, email, tel, users.token).then(
      (e) => {
        setBool(!bool)
        setWait(false)
        if(e.name=='AxiosError'){
          addToast(e.response?.data?.message ? e.response.data.message : "Une erreur s'est produit" , {
            appearance: 'error',
            autoDismiss: true,
          })
        }
        
      }
    )
  }
  const saveSociete = () => {
    setWait(true)
    updateUserSociete(users.user?._id, societe, users.token).then((e) => {
      setBool(!bool)
      setWait(false)
      if(e.name=='AxiosError'){
        addToast(e.response?.data?.message ? e.response.data.message : "Une erreur s'est produit" , {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      
    })
  }
  return (
    <>
      {wait && (
        <>
          <div
            className="container-fluid d-flex"
            style={{
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              zIndex: '2000',
              backgroundColor: 'rgba(104, 93, 119, 0.7)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BeatLoader color="yellowgreen" loading={true} size={50} />
          </div>
        </>
      )}
      <div className="d-flex flex-column">
        <Menu bool={false} />
        <Header bool={false} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="contaier-fluid">
            <div class="page-title-box">
              <div class="float-end"></div>
            </div>
            <div class="page-title-box">
              <div class="float-end">
                <div
                  className="btn btn-dark"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate(-2)
                  }}
                >
                  Retour
                </div>
              </div>
              <h6 class="page-title">Profil</h6>
            </div>

            {/* debut profil 
            
            "@syncfusion/ej2-base": "^20.3.50",
            "@syncfusion/ej2-react-schedule": "^20.3.58",
            */}
            <div class="met-profile bg-white p-4 mb-4">
              <div class="row">
                <div class="col-lg-4 align-self-center mb-3 mb-lg-0">
                  <div class="met-profile-main">
                    <div class="met-profile-main-pic">
                      <img
                        src={photoUser}
                        alt=""
                        height="110"
                        class="rounded-circle"
                      />
                      <span class="met-profile_main-pic-change">
                        <i class="fas fa-camera"></i>
                      </span>
                    </div>
                    <div class="met-profile_user-detail">
                      <h5 class="met-user-name">
                        Rôle :{' '}
                        {data.role?.roleType
                          ? data.role.roleType.toUpperCase()
                          : 'OPC'}
                      </h5>
                      <p class="mb-0 met-user-name-post">
                        Entreprise :{' '}
                        {data.entrepriseName
                          ? data.entrepriseName
                          : 'Citron Ingénieux'}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 ms-auto align-self-center">
                  <ul class="list-unstyled personal-detail mb-0">
                    <li class="">
                      <i class="las la-user text-secondary font-22 align-middle "></i>{' '}
                      <b> Responsable </b> : {data.name ? data.name : ''}{' '}
                      {data.lastnmae ? data.lastname : ''}
                    </li>
                    <li class="">
                      <i class="las la-phone mr-2 text-secondary font-22 align-middle"></i>{' '}
                      <b> Téléphone </b> :{' '}
                      {data.telephone ? data.telephone : ''}
                    </li>
                    <li class="">
                      <i class="las la-envelope text-secondary font-22 align-middle mr-2"></i>{' '}
                      <b> Email </b> : {data.email ? data.email : ''}
                    </li>
                  </ul>
                </div>
                <div class="col-lg-4 align-self-center">
                  <div class="row"></div>
                </div>
              </div>
            </div>

            {/* details */}
            <div class="row">
              <div class="col-lg-4">
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    savePerso()
                  }}
                >
                  <div class="card">
                    <div class="card-body">
                      <h4>Informations personnelles</h4>
                      <label class="form-label" for="setFullName">
                        Nom
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        onChange={(e) => {
                          setNom(e.target.value)
                        }}
                        defaultValue={data.name ? data.name : ''}
                        placeholder="Nom"
                        required
                      />
                      <label class="form-label" for="setFullName">
                        Prénom
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="setFullName"
                        placeholder="Prénom"
                        defaultValue={data.lastname ? data.lastname : ''}
                        onChange={(e) => {
                          setPrenom(e.target.value)
                        }}
                        required
                      />
                      <label class="form-label mt-2" for="setEmail">
                        Email
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="setEmail"
                        placeholder="Email"
                        defaultValue={data.email ? data.email : ''}
                        onChange={(e) => {
                          setEmail(e.target.value)
                        }}
                        required
                      />
                      <label class="form-label mt-2" for="setPassword">
                        Téléphone
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Téléphone"
                        defaultValue={data.telephone ? data.telephone : ''}
                        onChange={(e) => {
                          setTel(e.target.value)
                        }}
                        required
                      />
                      <button type="submit" class="btn btn-primary btn-sm mt-3">
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-lg-4">
                <div class="card">
                  <div class="card-body">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        saveSociete()
                      }}
                    >
                      <h4>Entreprise</h4>
                      <label class="form-label mt-2" for="setPassword">
                        Nom société
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Société"
                        defaultValue={
                          data.entrepriseName ? data.entrepriseName : ''
                        }
                        onChange={(e) => setSociete(e.target.value)}
                        required
                      />
                      <label class="form-label mt-2" for="setPassword">
                        SIRET
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="SIRET"
                        required
                      />
                      <button type="submit" class="btn btn-primary btn-sm mt-3">
                        Enregistrer
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              {(users?.user?.role?.roleType == 'admin' ||
                users?.user?.role?.roleType == 'opc') && (
                <>
                  <div class="col-lg-4">
                    <div class="card">
                      <div class="card-header">
                        <div class="row align-items-center">
                          <div class="col">
                            <h5>Réglage du compte</h5>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <form>
                          <label class="form-label mt-2" for="setPassword">
                            Envoi automatique:
                          </label>
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="ICOnotify"
                              onChange={(e) => {
                                console.log(e.target.checked)
                                axios
                                  .patch(
                                    `${process.env.REACT_APP_URL}/api/v1/users/${users.user?._id}`,
                                    {
                                      envoyerEmailRappelEtRelance:
                                        e.target.checked,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${users.token}`,
                                      },
                                    }
                                  )
                                  .then((el) => {
                                    console.log(el)
                                    setBool(!bool)
                                  })
                                  .catch((el) => {
                                    console.log(el)
                                  })
                              }}
                              checked={
                                data ? data.envoyerEmailRappelEtRelance : false
                              }
                            />
                            <label class="form-check-label" for="ICOnotify">
                              Envoi rappel + relance automatique
                            </label>
                          </div>
                          <div class="form-check form-switch mt-2">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="notyfySound"
                              onChange={(e) => {
                                console.log(e.target.checked)
                                axios
                                  .patch(
                                    `${process.env.REACT_APP_URL}/api/v1/users/${users.user?._id}`,
                                    {
                                      envoyerEmailRapport: e.target.checked,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${users.token}`,
                                      },
                                    }
                                  )
                                  .then((el) => {
                                    console.log(el)
                                    setBool(!bool)
                                  })
                                  .catch((el) => {
                                    console.log(el)
                                  })
                              }}
                              checked={data ? data.envoyerEmailRapport : false}
                            />
                            <label class="form-check-label" for="notyfySound">
                              Envoi automatique des rapports
                            </label>
                          </div>
                          <hr />
                          <label class="form-label mt-2" for="setPassword">
                            Liste des chantiers:
                          </label>
                          {listChantier &&
                            listChantier.map((c) => {
                              return (
                                <>
                                  <div class="form-check form-switch mt-2">
                                    <label
                                      class="form-check-label"
                                      for="notyfySound"
                                    >
                                      {c.titre}
                                    </label>
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="notyfySound"
                                      onChange={(el) => {
                                        if (el.target.checked) {
                                          updateChantierUser(
                                            c._id,
                                            true,
                                            users.token
                                          ).then((e) => {
                                            console.log(e)
                                          })
                                        } else {
                                          updateChantierUser(
                                            c._id,
                                            false,
                                            users.token
                                          ).then((e) => {
                                            console.log(e)
                                          })
                                        }
                                      }}
                                      defaultChecked={
                                        c.envoieEmail ? c.envoieEmail : false
                                      }
                                    />
                                  </div>
                                </>
                              )
                            })}

                          <hr />
                          {users.user?.email == 'admin@gmail.com' && (
                            <>
                              <label class="form-label mt-2" for="setPassword">
                                Date des tâches à rappeler:
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                onChange={(e) => {
                                  setDate(e.target.value)
                                }}
                              />
                            </>
                          )}
                          <button
                            className="btn btn-primary mt-3"
                            onClick={(e) => {
                              e.preventDefault()
                              axios
                                .get(
                                  `${process.env.REACT_APP_URL}/api/v1/courriers/envoiRapport?planning=${infoChantier.planning}&date=${date}`,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${users.token}`,
                                    },
                                  }
                                )
                                .then((el1) => {
                                  addToast("l'email du compte rendu a été envoyé avec succès.",{
                                    appearance:"success",
                                    autoDismiss:true
                                  })
                                  console.log(el1)
                                })
                              axios
                                .get(
                                  `${process.env.REACT_APP_URL}/api/v1/courriers/cronTest?date=${date}`,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${users.token}`,
                                    },
                                  }
                                )
                                .then((el1) => {
                                   addToast("l'email de rappel a été envoyé avec succès.",{
                                    appearance:"success",
                                    autoDismiss:true
                                  })
                                  console.log(el1)
                                })
                              axios
                                .get(
                                  `${process.env.REACT_APP_URL}/api/v1/courriers/rappelRetard`,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${users.token}`,
                                    },
                                  }
                                )
                                .then((el) => {
                                  addToast("l'email de rappel des retards a été envoyé avec succès.",{
                                    appearance:"success",
                                    autoDismiss:true
                                  })
                                })
                            }}
                          >
                            Envoyer rapports
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* fi profil */}
        </div>
      </div>
    </>
  )
}

export default Profil
