import axios from 'axios'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
const Modal = ({ id ,setWait,setData,data}) => {
  const {addToast} = useToasts()
  const bool = useSelector((state) => state.bool.bool)
  const dispatch = useDispatch()
  const [zone, setZone] = useState('')
  const [lot, setLot] = useState('')
  const [chant, setChant] = useState('')
  const [file, setFile] = useState('')
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  
  return (
    <div
      class="modal fade"
      id={id}
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Ajout d'un nouveau plan
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form
              id="champ"
              onSubmit={(e) => {
                setWait(true)
                e.preventDefault()
                addToast("Enregistrement en cours...", {
                  appearance: 'success',
                  autoDismiss: true,
                })
                const a = document.getElementById('champ')
                a.reset()
                console.log(zone)
                console.log(lot)
                console.log(chant)
                console.log(file)
                const formData = new FormData()
                formData.append('file', file)
                formData.append('zone', zone)
                formData.append('lot', lot)
                formData.append('description', chant)
                formData.append('planning', infoChantier?.planning)
                console.log(formData)
                axios
                  .post(
                    `${process.env.REACT_APP_URL}/api/v1/ressources/converttopic`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${infoUser.token}`,
                      },
                    }
                  )
                  .then((e) => {
                    addToast("Le plan a été créé avec succès.", {
                      appearance: 'success',
                      autoDismiss: true,
                    })
                    console.log(e)
                    setData([...data,e.data.data])
                    setWait(false)
                  })
                  .catch((e) => {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                    console.log(e)
                    setWait(false)
                  })
              }}
            >
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Nom du plan :
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputPassword1"
                  onChange={(e) => {
                    setChant(e.target.value)
                  }}
                  required
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Photo :
                </label>
                <input
                  type="file"
                  class="form-control"
                  id="exampleInputPassword1"
                  accept=".pdf"
                  onChange={(e) => {
                    setFile(e.target.files[0])
                  }}
                  required
                />
              </div>
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Enregistrer
              </button>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Modal
