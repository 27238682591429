import React, { useState, useEffect } from 'react'
import { GeoAltFill } from 'react-bootstrap-icons'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
const PinMedia = ({ props, idt, idImage }) => {
  const bools = useSelector((state) => state.bool.bool)
  const [bool2, setBool2] = useState(idt)
  const [del, setDel] = useState(true)
  const dispatch = useDispatch()
  const [description, setDescription] = useState('')
  const [markers, setMarkers] = useState({})
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)

  useEffect(() => {
    setMarkers(props)
  }, [])
  useEffect(() => {}, [bool2, del])
  return (
    <>
      {del && (
        <div
          className="d-flex"
          style={{ alignItems: 'center' }}
          onClick={(e) => {
            setBool2(true)
            dispatch({ type: 'pinMedia/modifier', payload: true })
          }}
        >
          <i
            className="ti ti-circle-dashed"
            style={{ fontSize: '50px', color: 'red' }}
          ></i>
        </div>
      )}
      {bool2 ? (
        <>
          <div className="docPopup" style={{ zIndex: '20000' }}>
            <h5 className="text-center">
              <u>PinPoint {props.itemNumber}</u>
            </h5>
            <label class="form-label">Description:</label>
            <div className="d-flex" style={{ justifyContent: 'center' }}>
              <input
                type="text"
                class="form-control"
                defaultValue={markers?.description}
                style={{ width: '98%' }}
                onChange={(e) => {
                  setDescription(e.target.value)
                }}
              />
            </div>
            <div className="d-flex" style={{ marginTop: '0.5vw' }}>
              <button
                className="btn btn-sm btn-success"
                style={{ fontSize: '15px', marginRight: '5px' }}
                onClick={(e) => {
                  setMarkers({ ...props, description: description })
                  console.log({ ...markers, description: description })
                  const formData = new FormData()
                  formData.append('top', markers.top)
                  formData.append('left', markers.left)
                  formData.append('description', description)
                  formData.append('itemNumber', markers.itemNumber)
                  axios
                    .patch(
                      `${process.env.REACT_APP_URL}/api/v1/ressources/${idImage}`,
                      formData,
                      {
                        headers: {
                          Authorization: `Bearer ${infoUser.token}`,
                        },
                      }
                    )
                    .then((e) => {
                      console.log(e)
                      dispatch({ type: 'bool/modifier', payload: !bools })
                    })
                    .catch((e) => {
                      console.log(e)
                    })
                }}
              >
                Enregstrer
              </button>{' '}
              <br />
              <button
                className="btn btn-sm btn-dark"
                style={{ fontSize: '15px', marginRight: '5px' }}
                onClick={(e) => {
                  setBool2(false)
                  dispatch({ type: 'pinMedia/modifier', payload: false })
                }}
              >
                Fermer
              </button>{' '}
              <br />
              <button
                className="btn btn-sm btn-danger"
                style={{ fontSize: '15px', marginRight: '5px' }}
                onClick={(e) => {
                  setBool2(false)
                  setDel(false)
                  console.log(props.itemNumber)
                  axios
                    .delete(
                      `${process.env.REACT_APP_URL}/api/v1/ressources/${idImage}?itemNumber=${props.itemNumber}`,
                      {
                        headers: {
                          Authorization: `Bearer ${infoUser.token}`,
                        },
                      }
                    )
                    .then((e) => {
                      console.log(e)
                      dispatch({ type: 'bool/modifier', payload: !bools })
                    })
                    .catch((e) => {
                      console.log(e)
                    })
                }}
              >
                Supprimer
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default PinMedia
