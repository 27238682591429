import React, { useEffect, useState } from 'react'
import {
  getProgPrev,
  getProgGeneral,
} from '../../../helpers/planning/dashBoard/upload'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { BeatLoader } from 'react-spinners'
import { Bar } from 'react-chartjs-2'
import { useToasts } from 'react-toast-notifications'
const InfoDashboard = () => {
  const {addToast} = useToasts()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const  infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [progressAnnuel, setProgressAnnuelle] = useState([])
  const [general, setGeneral] = useState({})
  const [datas,setDatas] = useState({})
  const [wait, setWait] = useState(true)
  const [date, setDate] = useState('')
  const [currentYear,setCurrenYear] =useState({})
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )
  useEffect(() => {
    setWait(true)
    getProgGeneral(infoChantier.planning, 2020,infoUser.token).then((e) => {
      console.log(e)
      if(e.message == "erreur"){
        setGeneral([])
      }else{
        setGeneral(e)
      }
    })
    getProgPrev(infoChantier.planning,infoUser.token).then((e) => {
      console.log(e);
      if(e.status == "error"){
        console.log("il y a erreur");
        setProgressAnnuelle([])
        setCurrenYear({})
        setWait(false)
      }else{
        setDatas(e)
        setProgressAnnuelle(e.progressionDuChantier)
        setCurrenYear(e.progressionDuChantier[0])
        setWait(false)
      }
      
    }).catch((err)=>{
      console.log(err);
      addToast("Une erreur s'est produit.", {
        appearance: 'error',
        autoDismiss: true,
      })
    }).finally((res)=>{
      setWait(false)
    })
  }, [])
  useEffect(()=>{
   if(progressAnnuel){
    progressAnnuel?.map((e)=>{
      if(e.year == date){
        setCurrenYear(e)
      }
   })
   }
  },[date])
  const options = {
    scales: {
      y: {
        max: 100,
        min: 0,
        ticks: {
          stepSize: 2,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Avencement mois par mois',
      },
    },
  }
  const labels = [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ]
  const data = {
    labels,
    datasets: [
      {
        label: 'Avancement réelle',
         data: currentYear?.rgpPerMonth
           ? currentYear.rgpPerMonth.map((e) => (e ? e : 0))
           : labels.map(() => 0),
        backgroundColor: '#007bff',
        borderRadius: 50,
        borderWidth: 1,
        indexAxis: 'x',
        barThickness: 15,
        grouped: true,
        maxBarThickness: 20,
        barPercentage: 50,
      },
      {
        label: 'Avancement prévisionnnelle',
         data: currentYear?.gpPerMonth
           ? currentYear.gpPerMonth.map((e) => (e ? e : 0))
           : labels.map(() => 0),
        backgroundColor: '#6c757d',
        borderRadius: 50,
        borderWidth: 1,
        indexAxis: 'x',
        barThickness: 25,
        grouped: true,
        maxBarThickness: 15,
        barPercentage: 50,
      },
    ],
  }
  return (
    <>
      <div className="row">
        <div className="col-sm-4 mt-5">
          <div class="card report-card">
            <div class="card-body">
              <div class="row d-flex justify-content-center">
                <div class="col">
                  <p class="text-dark mb-1 fw-semibold">Avancement général</p>
                  <h5 class="fw-bold">
                    {datas?.progress?.rgpPerMonth
                      ? datas.progress.rgpPerMonth.toFixed(0)
                      : 0}{' '}
                    %
                  </h5>
                  <p class="mb-0 text-truncate text-muted">
                    {datas?.progress?.gpPerMonth
                      ? datas.progress.gpPerMonth.toFixed(0)
                      : 0}{' '}
                    % planifié
                  </p>
                </div>
                <div class="col-auto align-self-center">
                  <div class="bg-light-alt d-flex justify-content-center align-items-center thumb-md  rounded-circle">
                    <i class="ti ti-stack"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card report-card">
            <div class="card-body">
              <div class="row d-flex justify-content-center">
                <div class="col">
                  <p class="text-dark mb-1 fw-semibold">Dernier pointage</p>
                  <h5 class="fw-bold">
                    {datas?.dernierPointage
                      ? datas.dernierPointage
                      : 'JJ/MM/AAAA'}{' '}
                  </h5>
                </div>
                <div class="col-auto align-self-center">
                  <div class="bg-light-alt d-flex justify-content-center align-items-center thumb-md  rounded-circle">
                    <i class="ti ti-checkbox"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card report-card">
            <div class="card-body">
              <div class="row d-flex justify-content-center">
                <div class="col">
                  <p class="text-dark mb-1 fw-semibold">Delta planning</p>
                  <h5 class="fw-bold">
                    {general.penalite?.retardConsigne
                      ? general.penalite.retardConsigne.toFixed(0)
                      : '0'}{' '}
                    jour(s) de retard
                  </h5>
                  <p class="mb-0 text-truncate text-muted">
                    {general.penalite?.somme ? general.penalite.somme.toFixed(0) : '0'} €
                    pénalité applicable(s)
                  </p>
                </div>
                <div class="col-auto align-self-center">
                  <div class="bg-light-alt d-flex justify-content-center align-items-center thumb-md  rounded-circle">
                    <i className="ti ti-currency-euro"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="row d-flex" style={{justifyContent:"center"}}>
            <h6 className="text-center mt-3">Avancement mois par mois : </h6>
            <select class="form-select text-center" aria-label="Default select example" style={{width:"50%"}} onChange={(e)=>{
              setDate(e.target.value)
            }}>
              <option selected>Sélectionner l'année à afficher</option>
             {
              progressAnnuel && progressAnnuel.map((e)=>{
                return (<><option value={e.year}>{e.year}</option></>)
              })
             }
            </select>
          </div>
          <Bar options={options} data={data} />
        </div>
      </div>
      {wait && (
        <>
          <div
            style={{
              position: 'absolute',
              top: '40%',
              left: '55%',
            }}
          >
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
      )}
    </>
  )
}

export default InfoDashboard
