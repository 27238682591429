import axios from 'axios';

const addChantier = async (adresse, titre, photo, client,token) => {
  try {
    const formData = new FormData();
    formData.append('adresse', adresse);
    formData.append('titre', titre);
    formData.append('client', client);
    formData.append('photo', photo);

    const { data } = await axios.post(
      `${process.env.REACT_APP_URL}/api/v1/chantiers`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getChantier = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_URL}/api/v1/chantiers`,config
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { addChantier, getChantier };
// import axios from "axios";

// export const addChantier = async (adresse,titre,photo,client) => {
//     try {      
//       const formData = new FormData();
//       formData.append('adresse', adresse);
//       formData.append('titre', titre);
//       formData.append('client',client)
//       formData.append('photo', photo);
//       console.log(formData);
//       await axios.post(`${process.env.REACT_APP_URL}/api/v1/chantiers`, formData, {
//           headers: {
//             'Content-Type': 'multipart/form-data'
//           },
//         })
//         .then((res) => {
//           console.log(res);
//           return res.data;
//         })
//         .catch((err) => {
//           console.log(err);
//           return err;
//         });
//     } catch (err) {
//       console.log(err);
//       return { message: 'erreur' };
//     }
//   };
// export const getChantier = async () => {
//     try{
//         const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/chantiers`).then((e)=>{
//             return e.data
//         }).catch((e)=>{return e})
//         return result
//     }catch(err){
//         console.log(err);
//         return err
//     }
// }