import axios from 'axios';

const connexion = async (data) => {
  try {
    const { data: responseData } = await axios.post(
      `${process.env.REACT_APP_URL}/api/v1/users/login`,
      {
        email: data.email,
        password: data.password,
      }
    );
    return responseData.data;
  } catch (error) {
    return {message:"erreur"}
  }
};
const envoiMail = async (data) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_URL}/api/v1/users/forgotPassword`,
      {
        email: data,
      }
    ).then((e)=>{
      return e
    }).catch((err)=>{
      return err
    })
    return result;
  } catch (error) {
    alert('le serveur ne repond pas. Veuillez ressayer dans un instant')
    return error
  }
};

const resetMDP = async (data,token) => {
  try {
    const result = await axios.patch(
      `${process.env.REACT_APP_URL}/api/v1/users/resetPassword/${token}`,
      {
        password:data.password,
        passwordConfirm:data.passwordConfirm
      }
    ).then((e)=>{
      return e
    }).catch((er)=>{
      return er
    })
    return result;
  } catch (error) {
    alert('le serveur ne repond pas. Veuillez ressayer dans un instant')
    return {message:"erreur"}
  }
};


export { connexion,envoiMail,resetMDP};


// import axios from "axios";

// export const connexion = async (data) => {
//     try{
//         const result = await axios.post(`${process.env.REACT_APP_URL}/api/v1/users/login`,{email:data.email,password:data.password}).then((e)=>{
//             return e.data.data
//         }).catch((e)=>{
//             return e.response.data.data
//         })
//         return result
//     }catch(err){
//         return {message:"erreur"}
//     }
// }