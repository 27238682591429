//premier commentaire
import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import { gantt } from 'dhtmlx-gantt'
import { getLastPointage } from '../../../helpers/planning/pointage/pointage'
import { dhtmlxGantt } from '../../../helpers/planning/gestionTache/gantt'
import { getAllData } from '../../../helpers/suivi/suivi'
import { updateOnTask } from '../../../helpers/planning/favori/favori'
import Multiselect from 'multiselect-react-dropdown'
import { getAllTask } from '../../../helpers/planning/gestionTache/dataGantt'
import BeatLoader from 'react-spinners/BeatLoader'
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css'
import './gestionTache.scss'
import 'dhtmlx-gantt/codebase/api'
import 'dhtmlx-gantt/codebase/dhtmlxgantt'
import axios from 'axios'
import PageNotFound from '../../../components/404/PageNotFound'
import { useToasts } from 'react-toast-notifications'
import ImportPlanning from './component/ImportPlanning'
import ListTacheNonAssigner from './component/ListTacheNonAssigner'
//Gestion des taches sur dhtmlx
const GestionTache = () => {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc')
  ) {
    return <PageNotFound />
  }
  const [assignement, setAssignement] = useState(false)
  const [tacheNonAssigner, setTacheNonAssigner] = useState([])
  const [currentColumns, setCurrentColumns] = useState([])
  const [originalColumns, setOriginalColumns] = useState([])
  const [taskUpdate, setTaskUpdate] = useState([])
  const [cheminCritique, setChemeinCritique] = useState(true)
  const [general, setGeneral] = useState(true)
  const [observation, setObservation] = useState(false)
  const [correspondance, setCorrespondance] = useState(false)
  const [update, setUpdate] = useState(false)
  const [oneTask, setOneTask] = useState({})
  const [task, setTask] = useState({})
  const [curentEntreprise, setCurrentEntreprise] = useState('')
  const [allLot, setAllLot] = useState([])
  const [newLot, setNewLot] = useState('')
  const [wait, setWait] = useState(false)
  const [bool, setBool] = useState(false)
  const [lastDate, setLastDate] = useState([])
  const [pt, setPt] = useState(false)
  const [datePt, setDatePt] = useState()
  const [havePlanning, setHavePlaning] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const filterColonne = (tab1, tab2) => {
    const result = []
    const names = tab2.map((t2) => t2.name)
    console.log('filter')
    console.log(names)
    console.log(tab1)
    console.log(tab2)
    tab1.map((t1) => {
      if (names.includes(t1.name)) {
        result.push(t1)
      }
    })
    return result
  }
  //formatter de date en fr
  const dateFr = (date) => {
    var formattedDate =
      (date.getDate() < 10 ? '0' : '') +
      date.getDate() +
      '/' +
      (date.getMonth() < 9 ? '0' : '') +
      (date.getMonth() + 1) +
      '/' +
      date.getFullYear()
    return `Le ${formattedDate}`
  }
  //pointage globale
  const pointageGlobale = () => {
    addToast("Veuillez patienter pendant le calcul de l'avancement.", {
      appearance: 'success',
      autoDismiss: true,
    })
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/pointages/vertical?planning=${infoChantier.planning}&dateDePointage=${datePt}`,
        { data: 'rien' },
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((e) => {
        addToast('Succès.', {
          appearance: 'success',
          autoDismiss: true,
        })
        setBool(!bool)
      })
      .catch((e) => {
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
        console.log(e)
      })
  }

  //fonction pour l'export format pdf
  const exportToPdf = () => {
    console.log(document.querySelector('.gantt_data_area').offsetHeight)
    const hauteur = document.querySelector('.gantt_data_area').offsetHeight
    const foot = document.querySelector('#foot')
    foot.style = `display:flex;justify-content:space-between;align-items:flex-end;height:${
      hauteur - 250
    }px;`
    const corps = document.querySelector('.hide-format')
    corps.style.display = 'block'
    const cible = document.querySelector('#cibles')
    cible.style.position = 'absolute'
    const container = document.createElement('div')
    container.style = 'position:absolute;top:0'
    container.style.width = '100%'
    const gantts = document.querySelector('#gantt1')
    container.appendChild(gantts)
    container.appendChild(foot)
    //container.appendChild(foot)
    document.body.innerHTML = ''
    cible.appendChild(container)
    document.body.style = 'background-color:white'
    document.body.appendChild(corps)

    window.print()
    location.reload()
  }
  //configuration gantt
  //plugin de dhtmlx
  gantt.plugins({
    auto_scheduling: false,
    critical_path: true,
    marker: true,
  })
  //configuration
  gantt.config.date_format = '%Y-%m-%d'
  gantt.config.show_errors = false
  gantt.serialize('json')
  //suppression des tache samedi et dimanche
  gantt.config.work_time = true // removes non-working time from calculations
  //gantt.config.skip_off_time = true // hides non-working time in the chart
  //Suppression tache
  gantt.attachEvent('onAfterTaskDelete', function (id, item) {
    console.log(item)
  })

  //auto déplacement des taches
  gantt.config.auto_scheduling = false
  gantt.config.show_links = true
  gantt.attachEvent('onGanttReady', function () {
    // Code à exécuter lorsque le Gantt est prêt
    setOriginalColumns(gantt.getGridColumns())
    if (sessionStorage.getItem('colonne')) {
      const newColonne = JSON.parse(sessionStorage.getItem('colonne'))
      setCurrentColumns(newColonne)
    } else {
      setCurrentColumns(gantt.getGridColumns())
    }
    setOriginalColumns(gantt.getGridColumns())
  })
  //formatage date
  gantt.attachEvent('onTaskLoading', function (task) {
    task.planned_start = gantt.date.parseDate(task.planned_start, 'xml_date')
    task.planned_end = gantt.date.parseDate(task.planned_end, 'xml_date')
    return true
  })
  gantt.attachEvent('onColumnResize', function (id, new_width) {
    console.log(gantt.getGridColumns())
  })
  gantt.attachEvent('onGridResize', function (old, new_scale) {
    // your code here
    console.log('manova')
    console.log(new_scale)
  })
  //evenement par double clique
  gantt.attachEvent('onTaskDblClick', function (id, e) {
    setTask(gantt.getTask(id))
    setUpdate(true)
  })

  gantt.attachEvent('onAfterTaskUpdate', function (id, item) {})
  useEffect(() => {
    setCurrentEntreprise(task?.lot?.entreprise?.nom)
  }, [task])

  //Recupération de tous les lots
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((e) => {
      setAllLot(e.data)
    })
  }, [])

  useEffect(() => {
    setWait(true)
    if (infoChantier.planning) {
      setHavePlaning(true)
      getAllTask(infoChantier.planning, infoUser.token).then((e) => {
        setTacheNonAssigner(e.data.data)
        getLastPointage(infoChantier.planning, infoUser.token).then((res) => {
          console.log(res)
          if (res.data.date) {
            setLastDate(res.data.date)
          }
        })
        console.log(e.data)
        dhtmlxGantt(gantt, 'gantt1', e)
        gantt.addTaskLayer(function draw_planned(task) {
          if (task.planned_start && task.planned_end) {
            var sizes = gantt.getTaskPosition(
              task,
              task.planned_start,
              task.planned_end
            )
            var el = document.createElement('div')
            el.className = 'baseline'
            el.style.left = sizes.left - 6 + 'px'
            el.style.width = sizes.width + 10 + 'px'
            el.style.height = sizes.height - 13 + 'px'
            el.style.top = sizes.top + 1 + 'px'
            return el
          }
          return false
        })

        gantt.eachTask(function (task) {
          if (task.duration == 0) {
            console.log('task jalon')
            console.log(task)
            task.type = gantt.config.types.milestone
          }
          if (+task.parent > 0) {
            var parent = gantt.getTask(task.parent)
            parent.type = gantt.config.types.project
            parent.color = 'lightgray'
          }
        })
        if (sessionStorage.getItem('colonne')) {
          const newColonne = JSON.parse(sessionStorage.getItem('colonne'))
          console.log(filterColonne(gantt.getGridColumns(), newColonne))
          gantt.config.columns = filterColonne(
            gantt.getGridColumns(),
            newColonne
          )
          gantt.render()
        }
        setWait(false)
      })
    } else {
      setHavePlaning(false)
      setChemeinCritique(false)
      setWait(false)
      //gantt.config.highlight_critical_path = false
    }
  }, [bool])
  //affichage des trois dernier pointage
  useEffect(() => {
    lastDate.map((e, i) => {
      if (
        i == lastDate.length - 1 ||
        i == lastDate.length - 2 ||
        i == lastDate.length - 3
      ) {
        var dateToStr = gantt.date.date_to_str(gantt.config.task_date)
        var markerId = gantt.addMarker({
          start_date: new Date(e), //a Date object that sets the marker's date
          css: 'today', //a CSS class applied to the marker
          text: `${e.split('T')[0]}`, //the marker title
          title: dateToStr(new Date()), // the marker's tooltip
        })
      }
    })
  }, [lastDate])

  //debut baseline
  gantt.config.bar_height = 20
  gantt.config.row_height = 40
  // adding baseline display

  //fonction pour l'affichage des critical path
  const criticalPath = () => {
    if (cheminCritique == false) {
      gantt.config.highlight_critical_path = false
      gantt.render()
    } else {
      gantt.config.highlight_critical_path = true
      gantt.render()
    }
  }
  //fonction pour l'affichage des derniers pointages
  const lastPt = () => {
    pt
      ? (gantt.config.show_markers = true)
      : (gantt.config.show_markers = false)
    gantt.render()
  }

  //formatage date
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0')
  }

  //fonction pour formater les dates
  const formatDate = (date) => {
    date = new Date(date)
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
  }

  //modification des favori apres mis a jour
  gantt.attachEvent('onTaskClick', function (id, e) {
    var checkbox = gantt.utils.dom.closest(e.target, '.gantt-checkbox-column')
    if (checkbox) {
      checkbox.checked = !!checkbox.checked
      gantt.getTask(id).isCritical = checkbox.checked
      return false
    } else {
      return true
    }
  })
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid" style={{ paddingTop: '0.5vw' }}>
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <h4 class="page-title">Planification</h4>
                </div>
              </div>
            </div>

            {/* debut NavBar */}
            <ul class="nav nav-pills">
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Dashboard')}
              >
                <a class="nav-link" href="#">
                  Tableau de bord
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_GestionTache')}
              >
                <a class="nav-link active" href="#">
                  Gestion des tâches
                </a>
              </li>
              {/* <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Pointage')}
              >
                <a class="nav-link" href="#">
                  Pointage
                </a>
              </li> */}
              <li
                class="nav-item"
                onClick={(e) => navigate('/Planning_GestionTache_LastPlanner')}
              >
                <a class="nav-link" href="#">
                  Glissant
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Favori')}
              >
                <a class="nav-link" href="#">
                  Pour mémoire
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Retard')}
              >
                <a class="nav-link" href="#">
                  Retards
                </a>
              </li>
            </ul>
            {/* Fin NavBar */}

            {/* Debut Gantt */}
            <div
              className="container-fluid"
              style={{ width: '100%', marginTop: '1vw', position: 'relative' }}
            >
              {havePlanning ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'no-wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ width: '60%' }}>
                      <button
                        class="dropdown-toggle btn btn-primary"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Zoom
                        <i className="ti ti-selector"></i>
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li
                          onClick={(e) => {
                            e.preventDefault()
                            sessionStorage.setItem('zoomPlanning', 'jour')
                            gantt.config.scales = [
                              { unit: 'day', step: 1, format: '%j %D' },
                            ]
                            gantt.render()
                          }}
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1.2vw' }}
                          >
                            Jours
                          </a>
                        </li>
                        <li
                          onClick={(e) => {
                            e.preventDefault()
                            sessionStorage.setItem('zoomPlanning', 'semaine')
                            gantt.config.scales = [
                              { unit: 'month', step: 1, format: '%F %Y' },
                              { unit: 'week', step: 1, format: '%j %D' },
                            ]
                            gantt.render()
                          }}
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1.2vw' }}
                          >
                            Semaine
                          </a>
                        </li>
                        <li
                          onClick={(e) => {
                            e.preventDefault()
                            sessionStorage.setItem('zoomPlanning', 'mois')
                            gantt.config.scales = [
                              { unit: 'year', step: 1, format: '%Y' },
                              { unit: 'month', step: 1, format: '%j %F' },
                            ]
                            gantt.render()
                          }}
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1.2vw' }}
                          >
                            Mois
                          </a>
                        </li>
                        {/* <li
                          onClick={(e) => {
                            e.preventDefault()
                            navigate('/Planning_GestionTache_LastPlanner')
                          }}
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1.2vw' }}
                          >
                            Glissant
                          </a>
                        </li> */}
                      </ul>
                      <button
                        class="dropdown-toggle btn btn-primary"
                        type="button"
                        id="dropdownMenuButton5"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ marginLeft: '5px' }}
                      >
                        Filtrer
                        <i className="ti ti-selector"></i>
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton5"
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        <li>
                          <a href="#" class="dropdown-item">
                            <Multiselect
                              displayValue="label"
                              onRemove={function noRefCheck(e) {
                                setCurrentColumns(e)
                                console.log(e)
                                sessionStorage.setItem(
                                  'colonne',
                                  JSON.stringify(e)
                                )

                                gantt.config.columns = e
                                gantt.render()
                              }}
                              onSearch={function noRefCheck() {}}
                              onSelect={function noRefCheck(e) {
                                console.log(e)
                                setCurrentColumns(e)
                                sessionStorage.setItem(
                                  'colonne',
                                  JSON.stringify(e)
                                )
                                gantt.config.columns = e
                                gantt.render()
                              }}
                              options={originalColumns}
                              selectedValues={currentColumns}
                              placeholder="Colonne"
                            />
                          </a>
                        </li>
                      </ul>
                      <button
                        className="btn btn-primary"
                        style={{ marginLeft: '0.5vw' }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Nouveau pointage
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">
                                Inserer la date du pointage
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault()
                                e.target.reset()
                                pointageGlobale()
                              }}
                            >
                              <div class="modal-body">
                                <div class="mb-3">
                                  <label class="form-label">
                                    Date du pointage
                                  </label>
                                  <input
                                    type="date"
                                    class="form-control"
                                    onChange={(e) => {
                                      setDatePt(e.target.value)
                                    }}
                                    required
                                  />
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Fermer
                                </button>
                                <button
                                  type="submit"
                                  class="btn btn-primary"
                                  data-bs-dismiss="modal"
                                >
                                  valider
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <button
                        className="btn btn-success"
                        style={{ marginLeft: '0.5vw' }}
                        onClick={(e) => {
                          addToast('Veuillez patienter! ...', {
                            appearance: 'success',
                            autoDismiss: true,
                          })
                          /*  gantt.eachTask(function (task) {
                            taskUpdate.push(task)
                          }) */
                          console.log(gantt.serialize('json'))
                          axios
                            .patch(
                              `${process.env.REACT_APP_URL}/api/v1/taches`,
                              {
                                planning: infoChantier?.planning,
                                ...gantt.serialize('json'),
                              },
                              {
                                headers: {
                                  Authorization: `Bearer ${infoUser.token}`,
                                },
                              }
                            )
                            .then((e) => {
                              addToast('Succès.', {
                                appearance: 'success',
                                autoDismiss: true,
                              })
                              setTaskUpdate([])
                            })
                            .catch((e) => {
                              addToast("Une erreur s'est produit.", {
                                appearance: 'error',
                                autoDismiss: true,
                              })
                              setTaskUpdate([])
                            })
                        }}
                      >
                        Enregistrer
                      </button>
                      <button
                        class="dropdown-toggle btn btn-primary"
                        style={{ marginLeft: '5px' }}
                        type="button"
                        id="dropdownMenuButton4"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="ti ti-plus"></i>
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton4"
                      >
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1.2vw' }}
                          >
                            <span>
                              <div className="btn">
                                <span
                                  style={{ paddingTop: '2px' }}
                                  className="mt-2"
                                >
                                  Chemin critique :
                                </span>
                              </div>
                              <label
                                class="switch p-1"
                                style={{
                                  marginLeft: '0.5vw',
                                  position: 'relative',
                                }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setChemeinCritique(!cheminCritique)
                                  criticalPath()
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={!cheminCritique}
                                />
                                <span class="slider round"></span>
                              </label>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1.2vw' }}
                          >
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div className="btn">
                                <span
                                  style={{ paddingTop: '2px' }}
                                  className="mt-2"
                                >
                                  Pointages :
                                </span>
                              </div>
                              <div>
                                <label
                                  class="switch p-1"
                                  style={{
                                    marginLeft: '0.5vw',
                                    position: 'relative',
                                  }}
                                  onClick={(el) => {
                                    el.preventDefault()
                                    el.stopPropagation()
                                    setPt(!pt)
                                    lastPt()
                                  }}
                                >
                                  <input type="checkbox" checked={!pt} />
                                  <span class="slider round"></span>
                                </label>
                              </div>
                            </span>
                          </a>
                        </li>
                      </ul>
                      {/* <button onClick={(e)=>{
                        gantt.render()
                       gantt.attachEvent("onBeforeTaskDisplay", function(id, task){
                        if (task.lot){
                            return true;
                        }
                        return false;
                    })
                      }}>Filtrer</button> */}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        width: '30%',
                      }}
                    >
                      <button
                        class="dropdown-toggle btn btn-primary"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                      >
                        Importer
                      </button>
                      <ImportPlanning
                        bool={bool}
                        setBool={setBool}
                        setAssignement={setAssignement}
                        setTacheNonAssigner={setTacheNonAssigner}
                        setWait={setWait}
                      />
                      <button
                        class="dropdown-toggle btn btn-primary"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ marginLeft: '5px' }}
                      >
                        Exporter
                        <i className="ti ti-selector"></i>
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {/*  <li
                          onClick={(e) => {
                            e.preventDefault()
                            gantt.exportToMSProject()
                          }}
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1.2vw' }}
                          >
                            Ms Project
                          </a>
                        </li> */}
                        <li
                          onClick={(e) => {
                            e.preventDefault()
                            setWait(true)
                            gantt.config.scales = [
                              { unit: 'year', step: 1, format: '%Y' },
                              { unit: 'month', step: 1, format: '%F' },
                            ]
                            gantt.config.columns = [
                              { name: 'text', label: 'Nom', min_width: 390 },
                              {
                                name: 'start_date',
                                label: 'début',
                                min_width: 90,
                              },
                              { name: 'end_date', label: 'Fin', min_width: 90 },
                              {
                                name: 'progress',
                                label: 'Av%',
                                template: function (task) {
                                  return `${(task.progress * 100).toFixed(2)}%`
                                },
                                align: 'center',
                              },
                              {
                                name: 'duration',
                                label: 'Durée',
                                align: 'center',
                              },
                            ]
                            gantt.config.show_links = false
                            gantt.config.autosize = 'xy'
                            gantt.render()
                            exportToPdf()
                          }}
                        >
                          <a
                            class="dropdown-item text-center"
                            href="#"
                            style={{ fontSize: '1.2vw' }}
                          >
                            PDF
                          </a>
                        </li>
                        {/* <li
                          onClick={(e) => {
                            e.preventDefault()
                            gantt.exportToExcel()
                          }}
                        >
                          <a
                            class="dropdown-item"
                            href="#"
                            style={{ fontSize: '1.2vw' }}
                          >
                            Excel
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div
                    id="gantt1"
                    style={{ width: '100%', height: '75vh', marginTop: '1px' }}
                  ></div>
                </>
              ) : (
                <>
                  <h1>vous devez importer un planning</h1>
                </>
              )}
            </div>
            {/* Fin Gantt*/}

            {/* debut colonne personnaliser */}
            {update && (
              <>
                <div className="container-fluid contenuRetard">
                  <div className="formRetard">
                    <div className="container-fluid headerGantt">
                      <h1 className="text-center">Informations de la tâche</h1>
                      <ul class="nav">
                        <li
                          class="nav-item"
                          onClick={(e) => {
                            e.preventDefault()
                            setCorrespondance(false)
                            setObservation(false)
                            setGeneral(true)
                          }}
                        >
                          <a
                            class={
                              general
                                ? 'nav-link bgActive'
                                : 'nav-link bgNonActive'
                            }
                          >
                            GENERAL
                          </a>
                        </li>
                        <li
                          class="nav-item"
                          onClick={(e) => {
                            e.preventDefault()
                            setCorrespondance(true)
                            setObservation(false)
                            setGeneral(false)
                          }}
                        >
                          <a
                            class={
                              correspondance
                                ? 'nav-link bgActive'
                                : 'nav-link bgNonActive'
                            }
                          >
                            CORRESPONDANCE
                          </a>
                        </li>
                        <li
                          class="nav-item"
                          onClick={(e) => {
                            e.preventDefault()
                            setCorrespondance(false)
                            setGeneral(false)
                            setObservation(true)
                          }}
                        >
                          <a
                            class={
                              observation
                                ? 'nav-link bgActive'
                                : 'nav-link bgNonActive'
                            }
                          >
                            OBSERVATIONS
                          </a>
                        </li>
                      </ul>
                    </div>
                    {general && (
                      <>
                        <form
                          style={{ width: '100%' }}
                          onSubmit={(e) => {
                            e.preventDefault()
                            const a = gantt.getTask(task?.id)
                            gantt.updateTask(task?.id, {
                              ...a,
                              ...{ ...oneTask },
                            })
                            setUpdate(false)
                            setOneTask({})
                          }}
                        >
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}
                          >
                            <div class="mb-3">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label"
                              >
                                ID
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={task?.id}
                                style={{ width: '12vw' }}
                                disabled={true}
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlTextarea1"
                                class="form-label"
                              >
                                Nom
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                defaultValue={task?.text}
                                style={{ width: '12vw' }}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setOneTask({
                                    ...oneTask,
                                    text: e.target.value,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}
                          >
                            <div class="mb-3">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label"
                              >
                                Date début prévue
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                style={{ width: '12vw' }}
                                defaultValue={formatDate(task.planned_start)}
                                disabled={true}
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlTextarea1"
                                class="form-label"
                              >
                                Date début réelle
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                style={{ width: '12vw' }}
                                defaultValue={formatDate(task.start_date)}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setOneTask({
                                    ...oneTask,
                                    start_date: new Date(e.target.value),
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}
                          >
                            <div class="mb-3">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label"
                              >
                                Date fin prévue
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                style={{ width: '12vw' }}
                                defaultValue={formatDate(task.planned_end)}
                                disabled={true}
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlTextarea1"
                                class="form-label"
                              >
                                Date fin réelle
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                style={{ width: '12vw' }}
                                defaultValue={formatDate(task.end_date)}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setOneTask({
                                    ...oneTask,
                                    end_date: new Date(e.target.value),
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}
                          >
                            <div class="mb-3">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label"
                              >
                                Durée Prévue
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                defaultValue={task?.duration}
                                style={{ width: '12vw' }}
                                disabled={true}
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlTextarea1"
                                class="form-label"
                              >
                                Durée réelle
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                defaultValue={task?.duration}
                                style={{ width: '12vw' }}
                                disabled={true}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setOneTask({
                                    ...oneTask,
                                    duration: +e.target.value,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}
                          >
                            <div class="mb-3">
                              <label
                                for="exampleFormControlInput1"
                                class="form-label"
                              >
                                Avancement
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                defaultValue={
                                  task?.progress
                                    ? `${(task.progress * 100).toFixed(2)}%`
                                    : '0%'
                                }
                                style={{ width: '12vw' }}
                                disabled={true}
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                for="exampleFormControlTextarea1"
                                class="form-label"
                              >
                                Date initiale
                              </label>
                              <input
                                type="date"
                                class="form-control"
                                defaultValue={
                                  task?.initial_date
                                    ? formatDate(task.initial_date)
                                    : 'jj/mm/yyyy'
                                }
                                style={{ width: '12vw' }}
                                onChange={(e) => {
                                  e.preventDefault()
                                  setOneTask({
                                    ...oneTask,
                                    initial_date: e.target.value,
                                  })
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              justifyContent: 'space-around',
                            }}
                          ></div>

                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                            }}
                          >
                            <button className="btn btn-success" type="submit">
                              Enregistrer
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={(e) => {
                                e.preventDefault()
                                setUpdate(false)
                                setOneTask({})
                                gantt.deleteTask(task.id)
                              }}
                            >
                              Supprimer
                            </button>
                            <button
                              className="btn btn-dark"
                              onClick={(e) => {
                                e.preventDefault()
                                setUpdate(false)
                                setOneTask({})
                              }}
                            >
                              Fermer
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {correspondance && (
                      <>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            updateOnTask(
                              'lot',
                              newLot,
                              task._id,
                              infoUser.token
                            ).then((e) => {
                              console.log(e)
                              setBool(!bool)
                            })
                            //à ne pas supprimer
                            setUpdate(false)
                            setOneTask({})
                          }}
                        >
                          <div
                            class="mb-3"
                            style={{ width: '50%', marginLeft: '5%' }}
                          >
                            <label for="exampleInputEmail1" class="form-label">
                              Lot:
                            </label>
                            <select
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) => {
                                const ent = JSON.parse(e.target.value)
                                const datas = {
                                  lot: JSON.parse(e.target.value),
                                }
                                setNewLot(datas)
                                setCurrentEntreprise(ent.entreprise.nom)
                                // updateOnTask(
                                //   'lot',
                                //   datas,
                                //   task._id,
                                //   infoUser.token
                                // ).then((e) => {
                                //   console.log(e)
                                //   setBool(!bool)
                                // })
                              }}
                            >
                              <option selected>
                                {task.lot
                                  ? task.lot.titre
                                  : 'Aucun lot associé'}
                              </option>
                              {allLot &&
                                allLot.map((e) => {
                                  return (
                                    <>
                                      <option value={JSON.stringify(e)}>
                                        {e.titre}
                                      </option>
                                    </>
                                  )
                                })}
                            </select>
                          </div>
                          <div
                            class="mb-3"
                            style={{ width: '50%', marginLeft: '5%' }}
                          >
                            <label
                              for="exampleInputPassword1"
                              class="form-label"
                            >
                              Entreprise:
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              defaultValue={
                                curentEntreprise
                                  ? curentEntreprise
                                  : 'Aucune entreprise associée'
                              }
                              disabled={true}
                            />
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                            }}
                          >
                            <button className="btn btn-success" type="submit">
                              Enregistrer
                            </button>

                            <button
                              className="btn btn-dark"
                              onClick={(e) => {
                                e.preventDefault()
                                setUpdate(false)
                                setOneTask({})
                              }}
                            >
                              Fermer
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {observation && (
                      <>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            const a = gantt.getTask(task?.id)
                            gantt.updateTask(task?.id, {
                              ...a,
                              ...{ ...oneTask },
                            })
                            setUpdate(false)
                            setOneTask({})
                          }}
                        >
                          <div
                            class="mb-3"
                            style={{ width: '50%', marginLeft: '5%' }}
                          >
                            <label for="exampleInputEmail1" class="form-label">
                              Description:
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              defaultValue={task?.favori}
                              onChange={(e) => {
                                e.preventDefault()
                                setOneTask({
                                  ...oneTask,
                                  favori: e.target.value,
                                })
                              }}
                            />
                          </div>
                          <div
                            class="mb-3"
                            style={{ width: '50%', marginLeft: '5%' }}
                          >
                            <label
                              for="exampleInputPassword1"
                              class="form-label"
                            >
                              Observations:
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              defaultValue={
                                task?.observation ? task.observation : ''
                              }
                              onChange={(e) => {
                                e.preventDefault()
                                setOneTask({
                                  ...oneTask,
                                  observation: e.target.value,
                                })
                              }}
                            />
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'space-around',
                            }}
                          >
                            <button className="btn btn-success" type="submit">
                              Enregistrer
                            </button>
                            <button
                              className="btn btn-dark"
                              onClick={(e) => {
                                e.preventDefault()
                                setUpdate(false)
                                setOneTask({})
                              }}
                            >
                              Fermer
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {/* fin colonne personnaliser */}
          </div>
        </div>
        <div className="hide-format">
          <table>
            <thead>
              <tr>
                <td>
                  <div class="page-header-space"></div>
                </td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <div class="page" id="cibles">
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        position: 'absolute',
                        top: '-50px',
                        left: '0',
                        right: '0',
                        borderBottom: 'solid gainsboro 1px',
                        paddingBottom: '10px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '20%',
                        }}
                      >
                        <img
                          src={
                            infoChantier?.logoClient
                              ? `${process.env.REACT_APP_URL}/api/v1/ressources/img/files,photo,logoClient/${infoChantier.logoClient}?accessToken=${infoUser.token}`
                              : 'https://back.sooda.fr/logo.png'
                          }
                          alt="KOLOS"
                          style={{ width: '100px', zIndex: '0' }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '60%',
                        }}
                      >
                        <h5 style={{ textAlign: 'center' }}>
                          {infoChantier.titre
                            ? infoChantier.titre.toUpperCase()
                            : ''}
                        </h5>
                      </div>
                      <div
                        style={{
                          width: '20%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <h5 style={{ textAlign: 'center' }}>
                          PLANNING GENERALE
                        </h5>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td>
                  <div class="page-footer-space"></div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <div
          id="foot"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%',
          }}
        >
          <div>{dateFr(new Date(Date.now()))}</div>
          <div>Propulsé par KOLOS</div>
        </div>
      </div>
      {assignement && (
        <ListTacheNonAssigner
          taches={tacheNonAssigner}
          setAsssignement={setAssignement}
          lot={allLot}
          setWait={setWait}
        />
      )}
      {wait && (
        <>
          <div
            style={{
              position: 'fixed',
              top: '0',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              zIndex: '100000000000000000',
            }}
          >
            <div>
              <BeatLoader color="yellowgreen" loading={true} size={25} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default GestionTache
