import React, { useState, useEffect } from 'react'
import { updateDoc, addDoc } from '../../../../helpers/suivi/suivi'
import { useToasts } from 'react-toast-notifications'
import { BeatLoader } from 'react-spinners'
const ModalDocument = ({
  dataModalSuiviDoc,
  dataModalDocument,
  indiceDoc,
  setDataDocument,
  setDataModalSuiviDoc,
  dataDocument,
}) => {
  const { addToast } = useToasts()
  const [wait,setWait]= useState(false)
  const [dateMo, setDateMo] = useState(
    dataModalSuiviDoc[0]?.maitriseOuvrage?.date
      ? dataModalSuiviDoc[0].maitriseOuvrage.date
      : 'jj/mm/aaa'
  )
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  //formatage date
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0')
  }

  //fonction pour formater les dates
  const formatDate = (date) => {
    date = new Date(date)
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
  }

  //fonction pour verifier indice unique
  const indiceUnique = (tab, value) => {
    const tableau = tab.map((el) => {
      return el.indiceEnCours
    })
    if (tableau.includes(value)) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    setDateMo(
      dataModalSuiviDoc[0]?.maitriseOuvrage?.date
        ? dataModalSuiviDoc[0].maitriseOuvrage.date
        : 'jj/mm/aaaa'
    )
  }, [dataModalSuiviDoc])
  return (
    <>
      {/* Modal document */}
      <div class="modal fade" id="myModalDoc">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Détails</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body">
              <div className="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th className="text-nowrap text-center">Nom document</th>
                      <th className="text-nowrap text-center">
                        Indice en cours
                      </th>
                      <th className="text-nowrap text-center">
                        Maitrise d'ouvrage
                      </th>
                      <th className="text-nowrap text-center">
                        Bureau de contrôle
                      </th>
                      <th className="text-nowrap text-center">
                        Coordonnateur SSI
                      </th>
                      <th className="text-nowrap text-center">
                        Coordonnateur SPS
                      </th>
                      <th className="text-nowrap text-center">Architecte</th>
                      <th className="text-nowrap text-center">
                        Maitrise d'oeuvre d'exécution
                      </th>
                      <th className="text-nowrap text-center">
                        Cellule synthèse
                      </th>
                      <th className="text-nowrap text-center">
                        Bureau d'études technique
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataModalSuiviDoc &&
                      dataModalSuiviDoc.map((el, i) => {
                        return (
                          <tr>
                            <td className="text-nowrap">
                              {dataModalDocument.titre
                                ? dataModalDocument.titre
                                : 'Non défini'}
                            </td>
                            <td className="text-nowrap">
                              <select
                                className="form-select"
                                disabled={i == 0 ? false : true}
                                onChange={(ev) => {
                                  updateDoc(
                                    el._id,
                                    {
                                      indiceEnCours: ev.target.value,
                                    },
                                    infoUser.token
                                  ).then((res) => {
                                    console.log(res)
                                    if (res.data) {
                                      setDataDocument(
                                        dataDocument.map((doc) => {
                                          if (doc._id == res.data._id) {
                                            return res.data
                                          } else {
                                            return doc
                                          }
                                        })
                                      )
                                    } else {
                                      addToast("Une erreur s'est produit", {
                                        appearance: 'error',
                                        autoDismiss: true,
                                      })
                                    }
                                  })
                                }}
                              >
                                <option
                                  value={
                                    el.indiceEnCours ? el.indiceEnCours : 'A'
                                  }
                                >
                                  {el.indiceEnCours ? el.indiceEnCours : 'A'}
                                </option>
                                {indiceDoc.map((ix) => {
                                  return (
                                    <>
                                      <option
                                        value={ix.value}
                                        disabled={indiceUnique(
                                          dataModalSuiviDoc,
                                          ix.value
                                        )}
                                      >
                                        {ix.value}
                                      </option>
                                    </>
                                  )
                                })}
                              </select>
                            </td>
                            <td className="text-nowrap">
                              {/* Maitrise d'ouvrage */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      el.maitriseOuvrage?.statut
                                        ? el.maitriseOuvrage.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          maitriseOuvrage: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                          setDataModalSuiviDoc(
                                            res.data.suiviDocs
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>

                                <div>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={
                                      el.maitriseOuvrage?.date
                                        ? formatDate(el.maitriseOuvrage?.date)
                                        : 'jj/mm/aaaa'
                                    }
                                    onChange={(ev) => {
                                      console.log(ev.target.value)
                                      if (ev.target.value == '') {
                                        updateDoc(
                                          el._id,
                                          {
                                            maitriseOuvrage: {
                                              date: ev.target.value,
                                              statut: false,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      } else {
                                        updateDoc(
                                          el._id,
                                          {
                                            maitriseOuvrage: {
                                              date: ev.target.value,
                                              statut: true,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      }
                                    }}
                                  />
                                </div>

                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          maitriseOuvrage: {
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value="Non-visé"
                                      selected={
                                        el.maitriseOuvrage.visa.type ==
                                        'Non-visé'
                                          ? true
                                          : false
                                      }
                                    >
                                      Non-visé
                                    </option>
                                    <option
                                      value="BPE"
                                      selected={
                                        el.maitriseOuvrage.visa.type == 'BPE'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPE
                                    </option>
                                    <option
                                      value="BPS"
                                      selected={
                                        el.maitriseOuvrage.visa.type == 'BPS'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPS
                                    </option>
                                    <option
                                      value="VSO"
                                      selected={
                                        el.maitriseOuvrage.visa.type == 'VSO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VSO
                                    </option>
                                    <option
                                      value="VAO"
                                      selected={
                                        el.maitriseOuvrage.visa.type == 'VAO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO
                                    </option>
                                    <option
                                      value="VAO-B"
                                      selected={
                                        el.maitriseOuvrage.visa.type == 'VAO-B'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO-B
                                    </option>
                                    <option
                                      value="REF"
                                      selected={
                                        el.maitriseOuvrage.visa.type == 'REF'
                                          ? true
                                          : false
                                      }
                                    >
                                      REF
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap ">
                              {/* BUreau de controle */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      el.bureauDeControle?.statut
                                        ? el.bureauDeControle.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          bureauDeControle: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                          setDataModalSuiviDoc(
                                            res.data.suiviDocs
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={
                                      el.bureauDeControle?.date
                                        ? formatDate(el.bureauDeControle?.date)
                                        : 'jj/mm/aaaa'
                                    }
                                    onChange={(ev) => {
                                      console.log(ev.target.value)
                                      if (ev.target.value == '') {
                                        updateDoc(
                                          el._id,
                                          {
                                            bureauDeControle: {
                                              date: ev.target.value,
                                              statut: false,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      } else {
                                        updateDoc(
                                          el._id,
                                          {
                                            bureauDeControle: {
                                              date: ev.target.value,
                                              statut: true,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      }
                                    }}
                                  />
                                </div>

                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          bureauDeControle: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value="Non-visé"
                                      selected={
                                        el.bureauDeControle.visa.type ==
                                        'Non-visé'
                                          ? true
                                          : false
                                      }
                                    >
                                      Non-visé
                                    </option>
                                    <option
                                      value="BPE"
                                      selected={
                                        el.bureauDeControle.visa.type == 'BPE'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPE
                                    </option>
                                    <option
                                      value="BPS"
                                      selected={
                                        el.bureauDeControle.visa.type == 'BPS'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPS
                                    </option>
                                    <option
                                      value="VSO"
                                      selected={
                                        el.bureauDeControle.visa.type == 'VSO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VSO
                                    </option>
                                    <option
                                      value="VAO"
                                      selected={
                                        el.bureauDeControle.visa.type == 'VAO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO
                                    </option>
                                    <option
                                      value="VAO-B"
                                      selected={
                                        el.bureauDeControle.visa.type == 'VAO-B'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO-B
                                    </option>
                                    <option
                                      value="REF"
                                      selected={
                                        el.bureauDeControle.visa.type == 'REF'
                                          ? true
                                          : false
                                      }
                                    >
                                      REF
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Coordonnateur SSI */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      el.coordonateurSSI?.statut
                                        ? el.coordonateurSSI.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          coordonateurSSI: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                          setDataModalSuiviDoc(
                                            res.data.suiviDocs
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={
                                      el.coordonateurSSI?.date
                                        ? formatDate(el.coordonateurSSI?.date)
                                        : 'jj/mm/aaaa'
                                    }
                                    onChange={(ev) => {
                                      console.log(ev.target.value)
                                      if (ev.target.value == '') {
                                        updateDoc(
                                          el._id,
                                          {
                                            coordonateurSSI: {
                                              date: ev.target.value,
                                              statut: false,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      } else {
                                        updateDoc(
                                          el._id,
                                          {
                                            coordonateurSSI: {
                                              date: ev.target.value,
                                              statut: true,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      }
                                    }}
                                  />
                                </div>

                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          coordonateurSSI: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value="Non-visé"
                                      selected={
                                        el.coordonateurSSI.visa.type ==
                                        'Non-visé'
                                          ? true
                                          : false
                                      }
                                    >
                                      Non-visé
                                    </option>
                                    <option
                                      value="BPE"
                                      selected={
                                        el.coordonateurSSI.visa.type == 'BPE'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPE
                                    </option>
                                    <option
                                      value="BPS"
                                      selected={
                                        el.coordonateurSSI.visa.type == 'BPS'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPS
                                    </option>
                                    <option
                                      value="VSO"
                                      selected={
                                        el.coordonateurSSI.visa.type == 'VSO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VSO
                                    </option>
                                    <option
                                      value="VAO"
                                      selected={
                                        el.coordonateurSSI.visa.type == 'VAO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO
                                    </option>
                                    <option
                                      value="VAO-B"
                                      selected={
                                        el.coordonateurSSI.visa.type == 'VAO-B'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO-B
                                    </option>
                                    <option
                                      value="REF"
                                      selected={
                                        el.coordonateurSSI.visa.type == 'REF'
                                          ? true
                                          : false
                                      }
                                    >
                                      REF
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Coordonnateur SPS */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      el.coordonateurSPS?.statut
                                        ? el.coordonateurSPS.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          coordonateurSPS: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                          setDataModalSuiviDoc(
                                            res.data.suiviDocs
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={
                                      el.coordonateurSPS?.date
                                        ? formatDate(el.coordonateurSPS?.date)
                                        : 'jj/mm/aaaa'
                                    }
                                    onChange={(ev) => {
                                      console.log(ev.target.value)
                                      if (ev.target.value == '') {
                                        updateDoc(
                                          el._id,
                                          {
                                            coordonateurSPS: {
                                              date: ev.target.value,
                                              statut: false,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      } else {
                                        updateDoc(
                                          el._id,
                                          {
                                            coordonateurSPS: {
                                              date: ev.target.value,
                                              statut: true,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      }
                                    }}
                                  />
                                </div>

                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          coordonateurSPS: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value="Non-visé"
                                      selected={
                                        el.coordonateurSPS.visa.type ==
                                        'Non-visé'
                                          ? true
                                          : false
                                      }
                                    >
                                      Non-visé
                                    </option>
                                    <option
                                      value="BPE"
                                      selected={
                                        el.coordonateurSPS.visa.type == 'BPE'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPE
                                    </option>
                                    <option
                                      value="BPS"
                                      selected={
                                        el.coordonateurSPS.visa.type == 'BPS'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPS
                                    </option>
                                    <option
                                      value="VSO"
                                      selected={
                                        el.coordonateurSPS.visa.type == 'VSO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VSO
                                    </option>
                                    <option
                                      value="VAO"
                                      selected={
                                        el.coordonateurSPS.visa.type == 'VAO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO
                                    </option>
                                    <option
                                      value="VAO-B"
                                      selected={
                                        el.coordonateurSPS.visa.type == 'VAO-B'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO-B
                                    </option>
                                    <option
                                      value="REF"
                                      selected={
                                        el.coordonateurSPS.visa.type == 'REF'
                                          ? true
                                          : false
                                      }
                                    >
                                      REF
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Architecte */}

                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      el.architecte?.statut
                                        ? el.architecte.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          architecte: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                          setDataModalSuiviDoc(
                                            res.data.suiviDocs
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={
                                      el.architecte?.date
                                        ? formatDate(el.architecte?.date)
                                        : 'jj/mm/aaaa'
                                    }
                                    onChange={(ev) => {
                                      console.log(ev.target.value)
                                      if (ev.target.value == '') {
                                        updateDoc(
                                          el._id,
                                          {
                                            architecte: {
                                              date: ev.target.value,
                                              statut: false,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      } else {
                                        updateDoc(
                                          el._id,
                                          {
                                            architecte: {
                                              date: ev.target.value,
                                              statut: true,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      }
                                    }}
                                  />
                                </div>
                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          architecte: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value="Non-visé"
                                      selected={
                                        el.architecte.visa.type == 'Non-visé'
                                          ? true
                                          : false
                                      }
                                    >
                                      Non-visé
                                    </option>
                                    <option
                                      value="BPE"
                                      selected={
                                        el.architecte.visa.type == 'BPE'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPE
                                    </option>
                                    <option
                                      value="BPS"
                                      selected={
                                        el.architecte.visa.type == 'BPS'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPS
                                    </option>
                                    <option
                                      value="VSO"
                                      selected={
                                        el.architecte.visa.type == 'VSO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VSO
                                    </option>
                                    <option
                                      value="VAO"
                                      selected={
                                        el.architecte.visa.type == 'VAO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO
                                    </option>
                                    <option
                                      value="VAO-B"
                                      selected={
                                        el.architecte.visa.type == 'VAO-B'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO-B
                                    </option>
                                    <option
                                      value="REF"
                                      selected={
                                        el.architecte.visa.type == 'REF'
                                          ? true
                                          : false
                                      }
                                    >
                                      REF
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Maitrise d'oeuvre d'éxécution */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      el.maitriseOeuvreExecution?.statut
                                        ? el.maitriseOeuvreExecution.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          maitriseOeuvreExecution: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                          setDataModalSuiviDoc(
                                            res.data.suiviDocs
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={
                                      el.maitriseOeuvreExecution?.date
                                        ? formatDate(
                                            el.maitriseOeuvreExecution.date
                                          )
                                        : 'jj/mm/aaaa'
                                    }
                                    onChange={(ev) => {
                                      console.log(ev.target.value)
                                      if (ev.target.value == '') {
                                        updateDoc(
                                          el._id,
                                          {
                                            maitriseOeuvreExecution: {
                                              date: ev.target.value,
                                              statut: false,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      } else {
                                        updateDoc(
                                          el._id,
                                          {
                                            maitriseOeuvreExecution: {
                                              date: ev.target.value,
                                              statut: true,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      }
                                    }}
                                  />
                                </div>

                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          maitriseOeuvreExecution: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value="Non-visé"
                                      selected={
                                        el.maitriseOeuvreExecution.visa.type ==
                                        'Non-visé'
                                          ? true
                                          : false
                                      }
                                    >
                                      Non-visé
                                    </option>
                                    <option
                                      value="BPE"
                                      selected={
                                        el.maitriseOeuvreExecution.visa.type ==
                                        'BPE'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPE
                                    </option>
                                    <option
                                      value="BPS"
                                      selected={
                                        el.maitriseOeuvreExecution.visa.type ==
                                        'BPS'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPS
                                    </option>
                                    <option
                                      value="VSO"
                                      selected={
                                        el.maitriseOeuvreExecution.visa.type ==
                                        'VSO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VSO
                                    </option>
                                    <option
                                      value="VAO"
                                      selected={
                                        el.maitriseOeuvreExecution.visa.type ==
                                        'VAO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO
                                    </option>
                                    <option
                                      value="VAO-B"
                                      selected={
                                        el.maitriseOeuvreExecution.visa.type ==
                                        'VAO-B'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO-B
                                    </option>
                                    <option
                                      value="REF"
                                      selected={
                                        el.maitriseOeuvreExecution.visa.type ==
                                        'REF'
                                          ? true
                                          : false
                                      }
                                    >
                                      REF
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Cellule synthèse */}
                              <div className="d-flex">
                                <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      el.celluleSynthese?.statut
                                        ? el.celluleSynthese.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          celluleSynthese: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                          setDataModalSuiviDoc(
                                            res.data.suiviDocs
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={
                                      el.celluleSynthese?.date
                                        ? formatDate(el.celluleSynthese?.date)
                                        : 'jj/mm/aaaa'
                                    }
                                    onChange={(ev) => {
                                      console.log(ev.target.value)
                                      if (ev.target.value == '') {
                                        updateDoc(
                                          el._id,
                                          {
                                            celluleSynthese: {
                                              date: ev.target.value,
                                              statut: false,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      } else {
                                        updateDoc(
                                          el._id,
                                          {
                                            celluleSynthese: {
                                              date: ev.target.value,
                                              statut: true,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      }
                                    }}
                                  />
                                </div>

                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          celluleSynthese: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value="Non-visé"
                                      selected={
                                        el.celluleSynthese.visa.type ==
                                        'Non-visé'
                                          ? true
                                          : false
                                      }
                                    >
                                      Non-visé
                                    </option>
                                    <option
                                      value="BPE"
                                      selected={
                                        el.celluleSynthese.visa.type == 'BPE'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPE
                                    </option>
                                    <option
                                      value="BPS"
                                      selected={
                                        el.celluleSynthese.visa.type == 'BPS'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPS
                                    </option>
                                    <option
                                      value="VSO"
                                      selected={
                                        el.celluleSynthese.visa.type == 'VSO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VSO
                                    </option>
                                    <option
                                      value="VAO"
                                      selected={
                                        el.celluleSynthese.visa.type == 'VAO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO
                                    </option>
                                    <option
                                      value="VAO-B"
                                      selected={
                                        el.celluleSynthese.visa.type == 'VAO-B'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO-B
                                    </option>
                                    <option
                                      value="REF"
                                      selected={
                                        el.celluleSynthese.visa.type == 'REF'
                                          ? true
                                          : false
                                      }
                                    >
                                      REF
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* Bureau d'études technique */}
                              <div className="d-flex">
                              <div className="form-check form-switch formswitch-primary mt-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      el.bureauEtudeTechnique?.statut
                                        ? el.bureauEtudeTechnique.statut
                                        : false
                                    }
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                            bureauEtudeTechnique: {
                                            statut: ev.target.checked,
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        console.log(res)
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                          setDataModalSuiviDoc(
                                            res.data.suiviDocs
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  />
                                </div>
                                <div>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={
                                      el.bureauEtudeTechnique?.date
                                        ? formatDate(el.bureauEtudeTechnique?.date)
                                        : 'jj/mm/aaaa'
                                    }
                                    onChange={(ev) => {
                                      console.log(ev.target.value)
                                      if (ev.target.value == '') {
                                        updateDoc(
                                          el._id,
                                          {
                                            bureauEtudeTechnique: {
                                              date: ev.target.value,
                                              statut: false,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      } else {
                                        updateDoc(
                                          el._id,
                                          {
                                            bureauEtudeTechnique: {
                                              date: ev.target.value,
                                              statut: true,
                                            },
                                          },
                                          infoUser.token
                                        ).then((res) => {
                                          console.log(res)
                                          if (res.data) {
                                            setDataDocument(
                                              dataDocument.map((doc) => {
                                                if (doc._id == res.data._id) {
                                                  return res.data
                                                } else {
                                                  return doc
                                                }
                                              })
                                            )
                                            setDataModalSuiviDoc(
                                              res.data.suiviDocs
                                            )
                                          } else {
                                            addToast(
                                              "Une erreur s'est produit",
                                              {
                                                appearance: 'error',
                                                autoDismiss: true,
                                              }
                                            )
                                          }
                                        })
                                      }
                                    }}
                                  />
                                </div>


                                <div style={{ marginLeft: '5px' }}>
                                  <select
                                    className="form-select"
                                    style={{ width: '120px' }}
                                    onChange={(ev) => {
                                      updateDoc(
                                        el._id,
                                        {
                                          bureauEtudeTechnique: {
                                            //statut:el.maitriseOuvrage.statut,
                                            visa: { type: ev.target.value },
                                          },
                                        },
                                        infoUser.token
                                      ).then((res) => {
                                        if (res.data) {
                                          setDataDocument(
                                            dataDocument.map((doc) => {
                                              if (doc._id == res.data._id) {
                                                return res.data
                                              } else {
                                                return doc
                                              }
                                            })
                                          )
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                    }}
                                    disabled={i == 0 ? false : true}
                                  >
                                    <option
                                      value={
                                        el.bureauEtudeTechnique.visa?.type
                                          ? el.bureauEtudeTechnique.visa.type
                                          : 'Non-visé'
                                      }
                                    >
                                      {el.bureauEtudeTechnique?.visa?.type
                                        ? el.bureauEtudeTechnique.visa.type
                                        : 'Non-visé'}
                                    </option>
                                    <option
                                      value="Non-visé"
                                      selected={
                                        el.bureauEtudeTechnique.visa.type ==
                                        'Non-visé'
                                          ? true
                                          : false
                                      }
                                    >
                                      Non-visé
                                    </option>
                                    <option
                                      value="BPE"
                                      selected={
                                        el.bureauEtudeTechnique.visa.type ==
                                        'BPE'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPE
                                    </option>
                                    <option
                                      value="BPS"
                                      selected={
                                        el.bureauEtudeTechnique.visa.type ==
                                        'BPS'
                                          ? true
                                          : false
                                      }
                                    >
                                      BPS
                                    </option>
                                    <option
                                      value="VSO"
                                      selected={
                                        el.bureauEtudeTechnique.visa.type ==
                                        'VSO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VSO
                                    </option>
                                    <option
                                      value="VAO"
                                      selected={
                                        el.bureauEtudeTechnique.visa.type ==
                                        'VAO'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO
                                    </option>
                                    <option
                                      value="VAO-B"
                                      selected={
                                        el.bureauEtudeTechnique.visa.type ==
                                        'VAO-B'
                                          ? true
                                          : false
                                      }
                                    >
                                      VAO-B
                                    </option>
                                    <option
                                      value="REF"
                                      selected={
                                        el.bureauEtudeTechnique.visa.type ==
                                        'REF'
                                          ? true
                                          : false
                                      }
                                    >
                                      REF
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <button
                type="button"
                class="btn btn-sm btn-primary mt-2"
                /* data-bs-dismiss="modal" */
                onClick={(e) => {
                  setWait(true)
                  addDoc(
                    dataModalDocument._id,
                    infoChantier.planning,
                    infoUser.token
                  ).then((res) => {
                    console.log(res)
                    if(res.data.documentMere){
                      setDataDocument(dataDocument.map((el)=>(el._id==res.data.documentMere._id) ?   res.data.documentMere : el))
                      setDataModalSuiviDoc([res.data.documentMere.suiviDocs[0],...dataModalSuiviDoc])
                    }
                    /* if (res.data) {
                      setDataModalSuiviDoc([res.data, ...dataModalSuiviDoc])
                    } else {
                      addToast("Une erreur s'est produit", {
                        appearance: 'error',
                        autoDismiss: true,
                      })
                    } */
                    setWait(false)
                  })
                }}
              >
                Ajouter +
              </button>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success"
                data-bs-dismiss="modal"
              >
                Enregistrer
              </button>
              <button
                type="button"
                class="btn btn-dark"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
          {
        wait && (
          <>
          <div style={{ position: 'fixed', top: '20%', left: '50%' }}>
            <BeatLoader color="yellowgreen" loading={true} size={25} />
          </div>
        </>
        )
      }
        </div>
      </div>
      
    </>
  )
}

export default ModalDocument
