import React from 'react'

const FormatPdfLtp = ({ legende }) => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  //formatter de date en fr
  const dateFr = (date) => {
    var formattedDate =
      (date.getDate() < 10 ? '0' : '') +
      date.getDate() +
      '/' +
      (date.getMonth() < 9 ? '0' : '') +
      (date.getMonth() + 1) +
      '/' +
      date.getFullYear()
    return `Le ${formattedDate}`
  }

  return (
    <>
      <div className="hide-format">
        <div class="page-header" style={{ display: 'flex' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '20%' }}>
            <img
              src={
                infoChantier?.logoClient
                  ? `${process.env.REACT_APP_URL}/api/v1/ressources/img/files,photo,logoClient/${infoChantier.logoClient}?accessToken=${infoUser.token}`
                  : 'https://back.sooda.fr/logo.png'
              }
              alt="KOLOS"
              style={{ width: '100px', zIndex: '0' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '60%',
            }}
          >
            <h5 style={{ textAlign: 'center' }}>
              {infoChantier.titre ? infoChantier.titre.toUpperCase() : ''}
            </h5>
          </div>
          <div style={{ width: '20%' }}></div>
        </div>

        <div class="page-footer">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{dateFr(new Date(Date.now()))}</div>
            <div>Propulsé par KOLOS</div>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <td>
                <div class="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <div class="page" style={{ height: '600px' }}>
                  <div className="pageGardeLastPlanner">
                    <img
                      src={
                        infoChantier.photo
                          ? `${process.env.REACT_APP_URL}/api/v1/ressources/img/files,photo,chantier/${infoChantier.photo}?accessToken=${infoUser.token}`
                          : 'https://back.sooda.fr/chantier_par_defaut.jpeg'
                      }
                      style={{
                        width: '400px',
                        height: '300px',
                        borderRadius: '20px',
                      }}
                      alt="logo"
                    />{' '}
                    <br />
                    <br />
                    <h1>PLANNING GLISSANT A 3 SEMAINES</h1>
                    <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                      {legende &&
                        legende.map((e) => {
                          return (
                            <>
                              <div
                                className="d-flex"
                                style={{
                                  alignItems: 'center',
                                  marginLeft: '20px',
                                }}
                              >
                                <div
                                  className="carre"
                                  style={{
                                    width: '15px',
                                    height: '15px',
                                    backgroundColor: `${
                                      e.color ? e.color : 'white'
                                    }`,
                                  }}
                                ></div>
                                <span
                                  style={{
                                    fontSize: '14px',
                                    marginLeft: '5px',
                                  }}
                                >
                                  {e.label ? e.label : ''}
                                </span>
                              </div>
                            </>
                          )
                        })}
                    </div>
                  </div>
                </div>
                <div class="page" id="cibles">
                  .
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <div class="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  )
}

export default FormatPdfLtp
