import React from 'react'
import { updateForfait } from '../../../../helpers/suivi/suivi'
import Modal from '../../penalite/Modal'
const Penalite = ({ entreprise, forfait, bool, setBool, setForfait }) => {
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  return (
    <>
      <div className="conatainer-fluid">
        {/* wizard pour compte rendu et fiche de visite */}
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h6 class="card-title">Liste des pénalités par lots</h6>
              </div>
              <div class="card-body">
                <form id="custom-step">
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab">
                      {entreprise?.map((e, i) => {
                        return (
                          <>
                            <a
                              class={`${
                                i == 0 ? 'nav-link active' : 'nav-link'
                              }`}
                              id={`step22-tab${e._id}`}
                              data-bs-toggle="tab"
                              href={`#step22${e._id}`}
                            >
                              {e.titre}
                            </a>
                          </>
                        )
                      })}
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    {entreprise &&
                      entreprise.map((e, i) => {
                        return (
                          <>
                            <div
                              class={`${
                                i == 0 ? 'tab-pane active' : 'tab-pane '
                              }`}
                              id={`step22${e._id}`}
                            >
                              <div className="container-fluid">
                                <table
                                  class="table table-striped"
                                  style={{ marginTop: '1vw' }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Type</th>
                                      <th>Forfait</th>
                                      <th>Retard consigné</th>
                                      <th>Pénalités applicables</th>
                                      <th>Détails</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Planning</td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={
                                            forfait.forfaitPlanning
                                              ? forfait.forfaitPlanning
                                              : 0
                                          }
                                          onChange={(el) => {
                                            el.preventDefault()
                                            updateForfait(
                                              forfait._id,
                                              {
                                                forfaitPlanning:
                                                  el.target.value,
                                              },
                                              infoUser.token
                                            ).then((e) => {
                                              setForfait(e.data)
                                              setBool(!bool)
                                            })
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {e.penaliteDetails.planning
                                          ? e.penaliteDetails.planning.toFixed(
                                              0
                                            )
                                          : 0}{' '}
                                        jour(s)
                                      </td>
                                      <td>
                                        {e.penaliteDetails?.planning
                                          ? (
                                              e.penaliteDetails.planning *
                                              forfait.forfaitPlanning
                                            ).toFixed(0)
                                          : 0}{' '}
                                        €
                                      </td>
                                      <td>
                                        <i
                                          class="ti ti-circle-plus"
                                          style={{
                                            fontSize: '30px',
                                            cursor: 'pointer',
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target={`#retard${e._id}`}
                                        ></i>
                                        <Modal
                                          id={`retard${e._id}`}
                                          data={e}
                                          type="Planning"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Planifications</td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={
                                            forfait.forfaitPlanningSo
                                              ? forfait.forfaitPlanningSo
                                              : 0
                                          }
                                          onChange={(el) => {
                                            el.preventDefault()
                                            updateForfait(
                                              forfait._id,
                                              {
                                                forfaitPlanningSo:
                                                  el.target.value,
                                              },
                                              infoUser.token
                                            ).then((e) => {
                                              setForfait(e.data)
                                              setBool(!bool)
                                            })
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {e.penaliteDetails.planningSo
                                          ? e.penaliteDetails.planningSo.toFixed(
                                              0
                                            )
                                          : 0}{' '}
                                        jour(s)
                                      </td>
                                      <td>
                                        {e.penaliteDetails?.planningSo
                                          ? (
                                              e.penaliteDetails.planningSo *
                                              forfait.forfaitPlanningSo
                                            ).toFixed(0)
                                          : 0}{' '}
                                        €
                                      </td>
                                      <td>
                                        <i
                                          class="ti ti-circle-plus"
                                          style={{
                                            fontSize: '30px',
                                            cursor: 'pointer',
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target={`#retard0${e._id}`}
                                        ></i>
                                        <Modal
                                          id={`retard0${e._id}`}
                                          data={e}
                                          type="PlanningSo"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Engagements</td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={
                                            forfait.forfaitEngagement
                                              ? forfait.forfaitEngagement
                                              : 0
                                          }
                                          onChange={(el) => {
                                            el.preventDefault()
                                            updateForfait(
                                              forfait._id,
                                              {
                                                forfaitEngagement:
                                                  el.target.value,
                                              },
                                              infoUser.token
                                            ).then((e) => {
                                              setForfait(e.data)
                                              setBool(!bool)
                                            })
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {e.penaliteDetails.engagement
                                          ? e.penaliteDetails.engagement.toFixed(
                                              0
                                            )
                                          : 0}{' '}
                                        jour(s)
                                      </td>
                                      <td>
                                        {e.penaliteDetails?.engagement
                                          ? (
                                              e.penaliteDetails.engagement *
                                              forfait.forfaitEngagement
                                            ).toFixed(0)
                                          : 0}{' '}
                                        €
                                      </td>
                                      <td>
                                        <i
                                          class="ti ti-circle-plus"
                                          style={{
                                            fontSize: '30px',
                                            cursor: 'pointer',
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target={`#retard1${e._id}`}
                                        ></i>
                                        <Modal
                                          id={`retard1${e._id}`}
                                          data={e}
                                          type="Engagement"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Administratifs</td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={
                                            forfait.forfaitAdmin
                                              ? forfait.forfaitAdmin
                                              : 0
                                          }
                                          onChange={(el) => {
                                            el.preventDefault()
                                            updateForfait(
                                              forfait._id,
                                              { forfaitAdmin: el.target.value },
                                              infoUser.token
                                            ).then((e) => {
                                              setForfait(e.data)
                                              setBool(!bool)
                                            })
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {e.penaliteDetails.administratif
                                          ? e.penaliteDetails.administratif.toFixed(
                                              0
                                            )
                                          : 0}{' '}
                                        jour(s)
                                      </td>
                                      <td>
                                        {e.penaliteDetails?.administratif
                                          ? (
                                              e.penaliteDetails.administratif *
                                              forfait.forfaitAdmin
                                            ).toFixed(0)
                                          : 0}{' '}
                                        €
                                      </td>
                                      <td>
                                        <i
                                          class="ti ti-circle-plus"
                                          style={{
                                            fontSize: '30px',
                                            cursor: 'pointer',
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target={`#retard2${e._id}`}
                                        ></i>
                                        <Modal
                                          id={`retard2${e._id}`}
                                          data={e}
                                          type="Administratifs"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Documents</td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={forfait.forfaitDocument || 0}
                                          onChange={(el) => {
                                            el.preventDefault()
                                            updateForfait(
                                              forfait._id,
                                              {
                                                forfaitDocument:
                                                  el.target.value,
                                              },
                                              infoUser.token
                                            ).then((e) => {
                                              setForfait(e.data)

                                              setBool(!bool)
                                            })
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {e.penaliteDetails?.document
                                          ? e.penaliteDetails.document.toFixed(
                                              0
                                            )
                                          : 0}{' '}
                                        jour(s)
                                      </td>
                                      <td>
                                        {e.penaliteDetails?.document
                                          ? (
                                              e.penaliteDetails.document *
                                              forfait.forfaitDocument
                                            ).toFixed(0)
                                          : 0}{' '}
                                        €
                                      </td>
                                      <td>
                                        <i
                                          class="ti ti-circle-plus"
                                          style={{ fontSize: '30px' }}
                                          data-bs-toggle="modal"
                                          data-bs-target={`#retard3${e._id}`}
                                        ></i>
                                        <Modal
                                          id={`retard3${e._id}`}
                                          data={e}
                                          type="Documents"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Sécurités</td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={
                                            forfait.forfaitSecurite
                                              ? forfait.forfaitSecurite
                                              : 0
                                          }
                                          onChange={(el) => {
                                            el.preventDefault()
                                            updateForfait(
                                              forfait._id,
                                              {
                                                forfaitSecurite:
                                                  el.target.value,
                                              },
                                              infoUser.token
                                            ).then((e) => {
                                              setForfait(e.data)

                                              setBool(!bool)
                                            })
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {e.penaliteDetails.securite
                                          ? e.penaliteDetails.securite.toFixed(
                                              0
                                            )
                                          : 0}{' '}
                                        jour(s)
                                      </td>
                                      <td>
                                        {e.penaliteDetails?.securite
                                          ? (
                                              e.penaliteDetails.securite *
                                              forfait.forfaitSecurite
                                            ).toFixed(0)
                                          : 0}{' '}
                                        €
                                      </td>
                                      <td>
                                        <i
                                          class="ti ti-circle-plus"
                                          style={{
                                            fontSize: '30px',
                                            cursor: 'pointer',
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target={`#retard4${e._id}`}
                                        ></i>
                                        <Modal
                                          id={`retard4${e._id}`}
                                          data={e}
                                          type="Sécurités"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Installation de Chantier</td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={
                                            forfait.forfaitInstallation
                                              ? forfait.forfaitInstallation
                                              : 0
                                          }
                                          onChange={(el) => {
                                            el.preventDefault()
                                            updateForfait(
                                              forfait._id,
                                              {
                                                forfaitInstallation:
                                                  el.target.value,
                                              },
                                              infoUser.token
                                            ).then((e) => {
                                              setForfait(e.data)

                                              setBool(!bool)
                                            })
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {e.penaliteDetails.installationChantier
                                          ? e.penaliteDetails.installationChantier.toFixed(
                                              0
                                            )
                                          : 0}{' '}
                                        jour(s)
                                      </td>
                                      <td>
                                        {e.penaliteDetails?.installationChantier
                                          ? (
                                              e.penaliteDetails
                                                .installationChantier *
                                              forfait.forfaitInstallation
                                            ).toFixed(0)
                                          : 0}{' '}
                                        €
                                      </td>
                                      <td>
                                        <i
                                          class="ti ti-circle-plus"
                                          style={{
                                            fontSize: '30px',
                                            cursor: 'pointer',
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target={`#retard5${e._id}`}
                                        ></i>
                                        <Modal
                                          id={`retard5${e._id}`}
                                          data={e}
                                          type="Installations"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Retards réunions</td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={
                                            forfait.forfaitRetard
                                              ? forfait.forfaitRetard
                                              : 1
                                          }
                                          onChange={(el) => {
                                            el.preventDefault()
                                            updateForfait(
                                              forfait._id,
                                              {
                                                forfaitRetard: el.target.value,
                                              },
                                              infoUser.token
                                            ).then((e) => {
                                              setForfait(e.data)

                                              setBool(!bool)
                                            })
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {e.penaliteDetails.intervenantEnRetard
                                          ? e.penaliteDetails.intervenantEnRetard.toFixed(
                                              0
                                            )
                                          : 0}
                                      </td>
                                      <td>
                                        {e.penaliteDetails?.intervenantEnRetard
                                          ? (
                                              e.penaliteDetails
                                                .intervenantEnRetard *
                                              forfait.forfaitRetard
                                            ).toFixed(0)
                                          : 0}{' '}
                                        €
                                      </td>
                                      <td>
                                        <i
                                          class="ti ti-circle-plus"
                                          style={{
                                            fontSize: '30px',
                                            cursor: 'pointer',
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target={`#retard6${e._id}`}
                                        ></i>
                                        <Modal
                                          id={`retard6${e._id}`}
                                          data={e}
                                          type="Retards"
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Absences réunions </td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={
                                            forfait.forfaitAbsence
                                              ? forfait.forfaitAbsence
                                              : 1
                                          }
                                          onChange={(el) => {
                                            el.preventDefault()
                                            updateForfait(
                                              forfait._id,
                                              {
                                                forfaitAbsence: el.target.value,
                                              },
                                              infoUser.token
                                            ).then((e) => {
                                              setForfait(e.data)

                                              setBool(!bool)
                                            })
                                          }}
                                        />
                                      </td>
                                      <td>
                                        {e.penaliteDetails.intervenantAbsent
                                          ? e.penaliteDetails.intervenantAbsent.toFixed(
                                              0
                                            )
                                          : 0}
                                      </td>
                                      <td>
                                        {e.penaliteDetails?.intervenantAbsent
                                          ? (
                                              e.penaliteDetails
                                                .intervenantAbsent *
                                              forfait.forfaitAbsence
                                            ).toFixed(0)
                                          : 0}{' '}
                                        €
                                      </td>
                                      <td>
                                        <i
                                          class="ti ti-circle-plus"
                                          style={{
                                            fontSize: '30px',
                                            cursor: 'pointer',
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target={`#retard7${e._id}`}
                                        ></i>
                                        <Modal
                                          id={`retard7${e._id}`}
                                          data={e}
                                          type="Absences"
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )
                      })}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* fin wizard */}
      </div>
    </>
  )
}

export default Penalite
