import React from 'react'
import {
  updateOnTask,
  updateFavori,
} from '../../../../helpers/planning/favori/favori'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
const Modal = ({ id, data, lot, curentLot }) => {
  const  infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [lott, setLott] = useState(curentLot ? curentLot : {})
  const [curentEntreprise, setCurrentEntreprise] = useState(
    curentLot ? curentLot.entreprise?.nom : ''
  )
  const bool = useSelector((state) => state.bool.bool)
  const dispatch = useDispatch()
  //formatter de date en fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}-${dt1[1]}-${dt1[0]}`
    return result
  }
  //formatage date
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0')
  }

  //fonction pour formater les dates
  const formatDate = (date) => {
    date = new Date(date)
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
  }
  const [debut, setDebut] = useState(
    data.start_date ? formatDate(data.start_date) : 'jj/mm/yyyy'
  )
  const [fin, setFIn] = useState(
    data.end_date ? formatDate(data.end_date) : 'jj/mm/yyyy'
  )
  const [dure, setDure] = useState(data.duration ? data.duration : 0)

  const getDuration = (debut, fin) => {
    let startDate = new Date(debut)
    let endDate = new Date(fin)
    let diffInMs = endDate - startDate
    let diffInDays = diffInMs / (1000 * 60 * 60 * 24)
    return diffInDays
  }
  return (
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id={id}>
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Information de la tâche</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div className="row">
              <h5 className="text-center">
                {' '}
                <u>{data.text}</u>{' '}
              </h5>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="username">Date début prévue :</label>
                  <input
                    type="text"
                    class="form-control"
                    id="username"
                    disabled={true}
                    value={data.planned_start ? dateFr(data.planned_start) : ''}
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="useremail">Date fin prévue :</label>
                  <input
                    type="email"
                    class="form-control"
                    id="useremail"
                    disabled={true}
                    value={data.planned_end ? dateFr(data.planned_end) : ''}
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="subject">Durée prévue:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="subject"
                    disabled={true}
                    value={`${getDuration(
                      data.planned_start,
                      data.planned_end
                    )} jour(s)`}
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="username">Date début réelle :</label>
                  <input
                    type="date"
                    class="form-control"
                    defaultValue={debut}
                    onChange={(e) => {
                      e.preventDefault()
                      const datas = {
                        start_date: e.target.value,
                        duration: getDuration(e.target.value, data.end_date),
                      }
                      setDure(getDuration(e.target.value, data.end_date))
                      updateOnTask('start_date', datas, data._id,infoUser.token).then((e) => {
                        console.log(e)
                        dispatch({ type: 'bool/modifier', payload: !bool })
                      })
                    }}
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="useremail">Date fin réelle :</label>
                  <input
                    type="date"
                    class="form-control"
                    defaultValue={fin}
                    onChange={(e) => {
                      e.preventDefault()
                      const datas = {
                        end_date: e.target.value,
                        duration: getDuration(data.start_date, e.target.value),
                      }
                      setDure(getDuration(data.start_date, e.target.value))
                      updateOnTask('end_date', datas, data._id,infoUser.token).then((e) => {
                        console.log(e)
                        dispatch({ type: 'bool/modifier', payload: !bool })
                      })
                    }}
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="subject">Durée réelle :</label>
                  <input
                    type="text"
                    class="form-control"
                    id="subject"
                    disabled={true}
                    value={`${dure} jour(s)`}
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="username">Lot :</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e)=>{
                      const ent = JSON.parse(e.target.value)
                      const datas = {
                        lot : JSON.parse(e.target.value)
                      }
                      setCurrentEntreprise(ent.entreprise.nom)
                      updateOnTask("lot",datas,data._id,infoUser.token).then((e)=>{
                        console.log(e);
                        //dispatch({ type: 'bool/modifier', payload: !bool })
                      })
                    }}
                  >
                    <option selected>{curentLot ? curentLot.titre : "Aucun lot associé"}</option>
                   {
                    lot && lot.map((e)=>{
                      return (
                        <>
                         <option value={JSON.stringify(e)}>{e.titre}</option>
                        </>
                      )
                    })
                   }
                    
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="useremail">Entreprise :</label>
                  <input
                    type="text"
                    class="form-control"
                    id="useremail"
                    disabled={true}
                    value={
                      curentEntreprise
                        ? curentEntreprise
                        : 'Aucune entreprise associée'
                    }
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div className="p-4">
                  <div class="form-check form-switch form-switch-success">
                    <label class="form-check-label" for="customSwitchSuccess">
                      {' '}
                      Pour mémoire
                    </label>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      checked
                      onChange={(e) => {
                        e.preventDefault()
                        updateFavori(e.target.checked, data._id,infoUser.token).then((el) => {
                          console.log(el)
                          dispatch({ type: 'bool/modifier', payload: !bool })
                        })
                      }}
                    />
                  </div>
                </div>
                {/* <div class="mb-3">
                  <label for="subject">Favori:</label>
                  <input
                    type="text"
                    class="form-control"
                    id="subject"
                    disabled={true}
                    value={data.isCritical ? 'oui' : 'non'}
                  />
                </div> */}
              </div>
            </div>
            <div className="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="username">Observations :</label>
                  <textarea
                    class="form-control"
                    id="username"
                    defaultValue={data.observation ? data.observation : ''}
                    onBlur={(e) => {
                      e.preventDefault()
                      const datas = {
                        observation: e.target.value,
                      }
                      updateOnTask('observation', datas, data._id,infoUser.token).then((e) => {
                        console.log(e)
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div
              class="btn btn-dark "
              data-bs-dismiss="modal"
            >
              Fermer
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
