import axios from 'axios'

export const getLastPointage = async (planning,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/pointages/?planning=${planning}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    alert('le serveur ne repond pas')
    return err
  }
}

