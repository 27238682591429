import React, {useState,useEffect} from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

const ModifTache = ({id,data}) => {
    const dispatch = useDispatch()
    const bool = useSelector((state)=>state.bool.bool)
    const [unite,setUnite] = useState(data.unite)
    const [quantite,setQuantite] = useState(data.quantite)
    const [unitaire,setUnitaire] = useState(data.prixUnitaire)
    const [total,setTotal] = useState(data.total)
    const [avancement,setAvancement] = useState("")
    const handleSubmit = () => {
        console.log(unite,quantite,unitaire,total);
        axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches/${data._id}`,{unite:unite,quantite:quantite,prixUnitaire:unitaire,total:total}).then((e)=>{
            console.log(e);
            dispatch({type:"bool/modifier",payload:!bool})
        }).catch((e)=>{console.log(e);})
    }
    const del = () => {
        axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches/${data._id}`,{insideArticle:false,article:null}).then((e)=>{
            dispatch({type:"bool/modifier",payload:!bool})
        }).catch((e)=>{console.log(e);})
    }
  return (
    <div
      class="modal fade modal-dialog-scrollable"
      id={id}
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {data.text}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form onSubmit={(e)=>{
            e.preventDefault()
            handleSubmit()
          }}>
            <div class="modal-body">
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Unité :
                </label>
                <input
                  type="text"
                  class="form-control"
                  defaultValue={data.unite}
                  placeholder="Unité"
                  onChange={(e) => {
                    setUnite(e.target.value)
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Quantité :
                </label>
                <input
                  type="number"
                  class="form-control"
                  defaultValue={data.quantite}
                  placeholder="Unité"
                  onChange={(e) => {
                    setQuantite(e.target.value)
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Avancement du mois:
                </label>
                <input
                  type="number"
                  class="form-control"
                  defaultValue="0"
                  placeholder="Unité"
                  onChange={(e) => {
                    setAvancement(e.target.value)
                  }}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Unitaire en Euros HT :
                </label>
                <input
                  type="number"
                  class="form-control"
                  defaultValue={data.prixUnitaire}
                  placeholder="Unité"
                  onChange={(e) => {
                    setUnitaire(e.target.value)
                  }}
                />
              </div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label">
                      Total en Euros HT :
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      defaultValue={data.total}
                      placeholder="Unité"
                      onChange={(e) => {
                        setTotal(e.target.value)
                  }}
                />
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-success" data-bs-dismiss="modal">
                Enregistrer
              </button>
              <button type="button" onClick={(e)=>{del()}} class="btn btn-danger" data-bs-dismiss="modal">
                Supprimer
              </button>
              <button type="button" class="btn btn-dark" data-bs-dismiss="modal">
                Fermer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModifTache