import React from "react";
import logo from "../../assets/images/Kolos_logo/Kolos Logo-long-new.png";
import { useNavigate } from "react-router-dom";

//page des erreur 404
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div class="container-md">
        <div class="row vh-100 d-flex justify-content-center">
          <div class="col-12 align-self-center">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-5 mx-auto">
                  <div class="card">
                    <div class="card-body p-0 auth-header-box bg-dark">
                      <div class="text-center p-3">
                        <a
                          href="#"
                          class="logo logo-admin"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/");
                          }}
                        >
                          <img
                            src={logo}
                            height="50"
                            alt="logo"
                            class="auth-logo"
                          />
                        </a>
                        <h4 class="mt-3 mb-1 fw-semibold text-white font-18">
                          Page introuvable
                        </h4>
                        <p class="text-muted  mb-0"></p>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="ex-page-content text-center">
                        <img
                          src="assets/images/error.svg"
                          alt="0"
                          class=""
                          height="170"
                        />
                        <h1 class="mt-5 mb-4">404!</h1>
                        <h5 class="font-16 text-muted mb-5">
                          Une erreur s'est produit
                        </h5>
                      </div>
                      <a
                        class="btn btn-primary w-100"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/");
                        }}
                      >
                        Veuillez vous reconnecter{" "}
                        <i class="fas fa-redo ml-1"></i>
                      </a>
                    </div>
                    <div class="card-body bg-light-alt text-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
