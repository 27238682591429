import React, { useState, useEffect } from 'react'
import Multiselect from 'multiselect-react-dropdown'
import { getAllData } from '../../../helpers/suivi/suivi'
const Modal = ({ id, data, type }) => {
  //formatter de date en fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }
  //filtrage des pénalités par leurs types
  const filtrage = () => {
    switch (type) {
      case 'Planning':
        return (
          <>
            <table class="table table-striped" style={{ marginTop: '1vw' }}>
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>date début</th>
                  <th>date fin</th>
                  <th>Retard consigné</th>
                  <th>Responsable engagée</th>
                </tr>
              </thead>
              <tbody>
                {data?.penalites?.map((e) => {
                  if (e.typePenalite == 'planning') {
                    return (
                      <>
                        <tr>
                          <td>
                            {e.retard?.tache?.text
                              ? e.retard.tache.text
                              : 'pose poutres'}
                          </td>
                          <td>
                            {e.retard?.tache?.start_date
                              ? dateFr(e.retard.tache.start_date)
                              : 'jj/mm/aaaa'}
                          </td>
                          <td>
                            {e.retard?.tache?.end_date
                              ? dateFr(e.retard.tache.end_date)
                              : 'jj/mm/aaaa'}
                          </td>
                          <td>
                            {e.retardConsigne
                              ? e.retardConsigne.toFixed(0)
                              : '...'}{' '}
                            jour(s)
                          </td>
                          <td>
                            <div class="form-check form-switch form-switch-success">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="customSwitchSuccess"
                                checked={
                                  e.responsableEngage
                                    ? e.responsableEngage
                                    : true
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                  }
                })}
              </tbody>
            </table>
          </>
        )
      case 'PlanningSo':
        return (
          <>
            <table class="table table-striped" style={{ marginTop: '1vw' }}>
              <thead>
                <tr>
                  <th>Tâches</th>
                  <th>Noté Le</th>
                  <th>Acté Le</th>
                  <th>Planifié Le</th>
                  <th>Réalisé Le</th>
                  <th>Urgence</th>
                </tr>
              </thead>
              <tbody>
                {data?.penalites?.map((e) => {
                  if (e.typePenalite == 'planningSo') {
                    return (
                      <>
                        <tr>
                          <td>
                            {e.planningSoEnRetard?.titre
                              ? e.planningSoEnRetard?.titre
                              : '...'}
                          </td>
                          <td>
                            {e.planningSoEnRetard?.noteLe
                              ? dateFr(e.planningSoEnRetard?.noteLe)
                              : '...'}
                          </td>
                          <td>
                            {e.planningSoEnRetard?.engageLe
                              ? dateFr(e.planningSoEnRetard?.engageLe)
                              : '...'}
                          </td>
                          <td>
                            {e.planningSoEnRetard?.prevuLe
                              ? dateFr(e.planningSoEnRetard?.prevuLe)
                              : '...'}
                          </td>
                          <td>
                            {e.planningSoEnRetard?.faitLe
                              ? dateFr(e.planningSoEnRetard?.faitLe)
                              : '...'}
                          </td>
                          <td>
                            {e.planningSoEnRetard?.urgence
                              ? e.planningSoEnRetard.urgence
                              : '...'}
                          </td>
                        </tr>
                      </>
                    )
                  }
                })}
              </tbody>
            </table>
          </>
        )
      case 'Engagement':
        return (
          <>
            <table class="table table-striped" style={{ marginTop: '1vw' }}>
              <thead>
                <tr>
                  <th>Titre</th>
                  <th>Noté Le</th>
                  <th>Acté Le</th>
                  <th>Prévu Le</th>
                  <th>Fait Le</th>
                  <th>Urgence</th>
                </tr>
              </thead>
              <tbody>
                {data?.penalites?.map((e) => {
                  if (e.typePenalite == 'engagement') {
                    return (
                      <>
                        <tr>
                          <td>
                            {e.engagementEnRetard?.titre
                              ? e.engagementEnRetard?.titre
                              : '...'}
                          </td>
                          <td>
                            {e.engagementEnRetard?.noteLe
                              ? dateFr(e.engagementEnRetard?.noteLe)
                              : '...'}
                          </td>
                          <td>
                            {e.engagementEnRetard?.engageLe
                              ? dateFr(e.engagementEnRetard?.engageLe)
                              : '...'}
                          </td>
                          <td>
                            {e.engagementEnRetard?.prevuLe
                              ? dateFr(e.engagementEnRetard?.prevuLe)
                              : '...'}
                          </td>
                          <td>
                            {e.engagementEnRetard?.faitLe
                              ? dateFr(e.engagementEnRetard?.faitLe)
                              : '...'}
                          </td>
                          <td>
                            {e.engagementEnRetard?.urgence
                              ? e.engagementEnRetard.urgence
                              : '...'}
                          </td>
                        </tr>
                      </>
                    )
                  }
                })}
              </tbody>
            </table>
          </>
        )
      case 'Administratifs':
        return (
          <>
            <table class="table table-striped" style={{ marginTop: '1vw' }}>
              <thead>
                <tr>
                  <th>Titre</th>
                  <th>Noté Le</th>
                  <th>Prévu Le</th>
                  <th>Fait Le</th>
                </tr>
              </thead>
              <tbody>
                {data?.penalites?.map((e) => {
                  if (e.typePenalite == 'administratif') {
                    return (
                      <>
                        <tr>
                          <td>
                            {e.administratifEnRetard?.titre
                              ? e.administratifEnRetard?.titre
                              : '...'}
                          </td>
                          <td>
                            {e.administratifEnRetard?.noteLe
                              ? dateFr(e.administratifEnRetard?.noteLe)
                              : '...'}
                          </td>
                          <td>
                            {e.administratifEnRetard?.prevuLe
                              ? dateFr(e.administratifEnRetard?.prevuLe)
                              : '...'}
                          </td>
                          <td>
                            {e.administratifEnRetard?.faitLe
                              ? dateFr(e.administratifEnRetard?.faitLe)
                              : '...'}
                          </td>
                        </tr>
                      </>
                    )
                  }
                })}
              </tbody>
            </table>
          </>
        )
      case 'Documents':
        return (
          <>
            <table class="table table-striped" style={{ marginTop: '1vw' }}>
              <thead>
                <tr>
                  <th>Titre</th>
                  <th>Phase</th>
                  <th>Type</th>
                  <th>Niveau</th>
                  <th>Zone</th>
                  <th>Prévu le</th>
                  <th>Diffusion</th>
                  <th>Visa</th>
                </tr>
              </thead>
              <tbody>
                {data?.penalites?.map((e) => {
                  if (e.typePenalite == 'document') {
                    return (
                      <>
                        <tr>
                          <td>
                            {e.documentEnRetard?.titre
                              ? e.documentEnRetard?.titre
                              : '...'}
                          </td>
                          <td>
                            {e.documentEnRetard?.phase
                              ? e.documentEnRetard?.phase
                              : '...'}
                          </td>
                          <td>
                            {e.documentEnRetard?.type
                              ? e.documentEnRetard?.type
                              : '...'}
                          </td>
                          <td>
                            {e.documentEnRetard?.niveau
                              ? e.documentEnRetard?.niveau
                              : '...'}
                          </td>
                          <td>
                            {e.documentEnRetard?.zone
                              ? e.documentEnRetard?.zone
                              : '...'}
                          </td>
                          <td>
                            {e.documentEnRetard?.prevision
                              ? dateFr(e.documentEnRetard?.prevision)
                              : '...'}
                          </td>
                          <td>
                            {e.documentEnRetard?.diffusion
                              ? dateFr(e.documentEnRetard?.diffusion)
                              : '...'}
                          </td>
                          <td>
                            {e.documentEnRetard?.visa
                              ? e.documentEnRetard?.visa
                              : '...'}
                          </td>
                        </tr>
                      </>
                    )
                  }
                })}
              </tbody>
            </table>
          </>
        )
      case 'Sécurités':
        return (
          <>
            <table class="table table-striped" style={{ marginTop: '1vw' }}>
              <thead>
                <tr>
                  <th>Titre</th>
                  <th>Noté Le</th>
                  <th>Prévu Le</th>
                  <th>Fait Le</th>
                  <th>urgence</th>
                </tr>
              </thead>
              <tbody>
                {data?.penalites?.map((e) => {
                  if (e.typePenalite == 'securite') {
                    return (
                      <>
                        <tr>
                          <td>
                            {e.securiteEnRetard?.titre
                              ? e.securiteEnRetard?.titre
                              : '...'}
                          </td>
                          <td>
                            {e.securiteEnRetard?.noteLe
                              ? dateFr(e.securiteEnRetard?.noteLe)
                              : '...'}
                          </td>
                          <td>
                            {e.securiteEnRetard?.prevuLe
                              ? dateFr(e.securiteEnRetard?.prevuLe)
                              : '...'}
                          </td>
                          <td>
                            {e.securiteEnRetard?.faitLe
                              ? dateFr(e.securiteEnRetard?.faitLe)
                              : '...'}
                          </td>
                          <td>
                            {e.securiteEnRetard?.urgence
                              ? e.securiteEnRetard?.urgence
                              : '...'}
                          </td>
                        </tr>
                      </>
                    )
                  }
                })}
              </tbody>
            </table>
          </>
        )
      case 'Installations':
        return (
          <>
            <table class="table table-striped" style={{ marginTop: '1vw' }}>
              <thead>
                <tr>
                  <th>Titre</th>
                  <th>Noté Le</th>
                  <th>Prévu Le</th>
                  <th>Fait Le</th>
                  <th>urgence</th>
                </tr>
              </thead>
              <tbody>
                {data?.penalites?.map((e) => {
                  if (e.typePenalite == 'installationChantier') {
                    return (
                      <>
                        <tr>
                          <td>
                            {e.installationChantierEnRetard?.titre
                              ? e.installationChantierEnRetard?.titre
                              : '...'}
                          </td>
                          <td>
                            {e.installationChantierEnRetard?.noteLe
                              ? dateFr(e.installationChantierEnRetard?.noteLe)
                              : '...'}
                          </td>
                          <td>
                            {e.installationChantierEnRetard?.prevuLe
                              ? dateFr(e.installationChantierEnRetard?.prevuLe)
                              : '...'}
                          </td>
                          <td>
                            {e.installationChantierEnRetard?.faitLe
                              ? dateFr(e.installationChantierEnRetard?.faitLe)
                              : '...'}
                          </td>
                          <td>
                            {e.installationChantierEnRetard?.urgence
                              ? e.installationChantierEnRetard.urgence
                              : '...'}
                          </td>
                        </tr>
                      </>
                    )
                  }
                })}
              </tbody>
            </table>
          </>
        )
      case 'Retards':
        return (
          <>
            <table class="table table-striped" style={{ marginTop: '1vw' }}>
              <thead>
                <tr>
                  <th>Jour du retard</th>
                  <th>Intervenant</th>
                  <th>Email</th>
                  <th>Lien vers le compte rendu</th>
                </tr>
              </thead>
              <tbody>
                {data?.penalites?.map((e) => {
                  if (e.typePenalite == 'retard') {
                    return (
                      <>
                        <tr>
                        <td>
                            {e.dateDePenalisation
                              ? dateFr(e.dateDePenalisation)
                              : 'Non défini'}
                          </td>
                          <td>
                            {e.intervenantEnRetard
                              ? (<>{e.intervenantEnRetard.name ?  e.intervenantEnRetard.name : ""} {e.intervenantEnRetard.lastname ? e.intervenantEnRetard.lastname : ""}</>)
                              : 'Non défini'}
                          </td>
                          <td>
                            {e.intervenantEnRetard?.email ? e.intervenantEnRetard.email :'Non défini'}
                          </td>
                          <td>
                            <a href={`${process.env.REACT_APP_URL}/api/v1/comptesrendu/file/${e.cr.fileToken}`} target="_blank"><i className='ti ti-eye' style={{fontSize:"18px",color:'gray'}}></i></a>
                          </td>
                        </tr>
                      </>
                    )
                  }
                })}
              </tbody>
            </table>
          </>
        )
      case 'Absences':
        return (
          <>
            <table class="table table-striped" style={{ marginTop: '1vw' }}>
              <thead>
                <tr>
                  <th>Jour d'absence</th>
                  <th>intervenant</th>
                  <th>Email</th>
                  <th>Lien vers le compte rendu</th>
                </tr>
              </thead>
              <tbody>
              {data?.penalites?.map((e) => {
                  if (e.typePenalite == 'absence') {
                    return (
                      <>
                        <tr>
                        <td>
                            {e.dateDePenalisation
                              ? dateFr(e.dateDePenalisation)
                              : 'Non défini'}
                          </td>
                          <td>
                            {e.intervenantEnRetard
                              ? (<>{e.intervenantEnRetard.name ?  e.intervenantEnRetard.name : ""} {e.intervenantEnRetard.lastname ? e.intervenantEnRetard.lastname : ""}</>)
                              : 'Non défini'}
                          </td>
                          <td>
                            {e.intervenantEnRetard?.email ? e.intervenantEnRetard.email :'Non défini'}
                          </td>
                          <td>
                            <a href={`${process.env.REACT_APP_URL}/api/v1/comptesrendu/file/${e.cr.fileToken}`} target="_blank"><i className='ti ti-eye' style={{fontSize:"18px",color:'gray'}}></i></a>
                          </td>
                        </tr>
                      </>
                    )
                  }
                })}
              </tbody>
            </table>
          </>
        )

      default:
        return <>Aucun pénalité</>
    }
  }

  return (
    <div class="modal fade bd-example-modal-lg" tabindex="-1" id={id}>
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Détails sur les pénalités de l'entreprise
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style={{ height: '70vh' }}>
            <div class="row">
              <div class="col-12">
                <ul
                  class="nav nav-tabs mb-3 nav-justified"
                  id="myTab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link active"
                      id="general_chat_tab"
                      data-bs-toggle="tab"
                      href="#general_chat"
                      role="tab"
                    >
                      Pénalité {type == 'PlanningSo' ? 'Planifications' : type}
                    </a>
                  </li>
                </ul>
                <div class="chat-body-left" data-simplebar>
                  <div class="tab-content chat-list" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="general_chat">
                      <div
                        className="conatiner-fluid"
                        style={{ height: '50vh' }}
                      >
                        {filtrage()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div
              class="btn btn-dark "
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Fermer
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
