import axios from "axios";

export const getLot = async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/lots/allLotsFormated?planning=${planning}`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e.response
        })
        return result
    }catch(err){
        return {message:"erreur"}
    }
}

export const saveLot = async (titre,planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.post(`${process.env.REACT_APP_URL}/api/v1/lots`,{titre:titre,planning:planning},config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e.response
        })
        return result
    }catch(err){
        return {message:"erreur"}
    }
}

export const getSimple = async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/taches/simple?planning=${planning}`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e.response
        })
        return result
    }catch(err){
        return {message:"erreur"}
    }
}

export const saveArticle = async (titre,tacheArticle,lot,planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.post(`${process.env.REACT_APP_URL}/api/v1/articles`,{articleName:titre,taches:tacheArticle,planning:planning,lot:lot},config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e.response
        })
        return result
    }catch(err){
        return {message:"erreur"}
    }
}

export const updateTacheDPGF = async (id,data,item,total,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        switch(item){
            case "unite":
                const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches/${id}`,{unite:data},config).then((e)=>{
                    return e.data
                }).catch((e)=>{
                    return e.response
                })
                return result
            case "quantite" : 
                const results = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches/${id}`,{quantite:data,total:total},config).then((e)=>{
                 return e.data
                 }).catch((e)=>{
                return e.response
                })
                return results
            case "prixUnitaire" :
                const resulta = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches/${id}`,{prixUnitaire:data,total:total},config).then((e)=>{
                    return e.data
                }).catch((e)=>{
                    return e.response
                })
                return resulta
            default : 
                return {message:"erreur"}
        }
    }catch(err){
        return {message:"erreur"}
    }
}