import React from 'react'
import logo from '../../assets/images/Kolos_logo/Kolos Logo-seul.png'
import logoLight from '../../assets/images/Kolos_logo/Kolos Logo-typo.png'
import logoDark from '../../assets/images/sooda_logo/sooda_violet.png'
import { useNavigate } from 'react-router-dom'
const Menu = ({ bool }) => {
  const navigate = useNavigate()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  return (
    <div className="left-sidebar">
      <div
        class="brand"
        onClick={(e) => {
          navigate('/accueil')
        }}
      >
        <a href="#" class="logo">
          <span>
            <img
              src={logo}
              alt="logo-big"
              class="logo-sm"
              style={{ width: '45px', height: '45px' }}
            />
          </span>
          <span>
            <img
              src={logoLight}
              alt="logo-large"
              class="logo-lg logo-light"
              style={{ width: '47%', height: '100%' }}
            />
            <img src={logoLight} alt="logo-large" class="logo-lg logo-dark" />
          </span>

          {/* <span>
            <img src={logo} alt="logo-small" class="logo-sm logo-dark" />
          </span>
          <span>
            <img src={logoLight} alt="logo-small" class="logo-lg logo-dark" />
          </span> */}
        </a>
      </div>
      <div className="menu-content h-100" data-simplebar>
        <div className="menu-body navbar-vertical">
          <div
            className="collapse navbar-collapse tab-content"
            id="sidebarCollapse"
          >
            {bool && (
              <>
                <ul
                  className="navbar-nav tab-pane active"
                  id="Main"
                  role="tabpanel"
                >
                  <li
                    className="nav-item"
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/Planning_Dashboard')
                    }}
                  >
                    <a className="nav-link" href="#">
                      <i className="ti ti-chart-arrows menu-icon"></i>
                      <span>Planning</span>
                    </a>
                  </li>
                  {/*
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#sidebarPlanification"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarPlanification"
                >
                  <i className="ti ti-chart-arrows menu-icon"></i>
                  <span>Planification</span>
                </a>
                {/*
                <div className="collapse " id="sidebarPlanification">
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link" href="index.html">
                        Tableau de bord
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="index.html">
                        Gestion des taches
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="index.html">
                        Planification des pointages
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="index.html">
                        Tache critique
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
                 
              </li>*/}
                  <li
                    className="nav-item"
                    onClick={(e) => {
                      navigate('/SuiviOperationnel')
                    }}
                  >
                    <a className="nav-link" href="#">
                      <i className="ti ti-report menu-icon"></i>
                      <span>Suivi Opérationnel</span>
                    </a>
                  </li>
                  {(infoUser?.user?.role?.roleType == 'admin' ||
                        infoUser?.user?.role?.roleType == 'opc') && (
                    <>
                      <li
                        className="nav-item"
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/Penalite')
                        }}
                      >
                        <a className="nav-link" href="#">
                          <i className="ti ti-clipboard-x menu-icon"></i>
                          <span>Pénalités</span>
                        </a>
                      </li>
                    </>
                  )}

                  <li
                    className="nav-item"
                    onClick={(e) => {
                      navigate('/Rapport_CR')
                    }}
                  >
                    <a className="nav-link" href="#">
                      <i className="ti ti-clipboard-list menu-icon"></i>
                      <span>Rapports</span>
                    </a>
                  </li>
                  {(infoUser?.user?.role?.roleType == 'admin' ||
                    infoUser?.user?.role?.roleType == 'opc') && (
                    <>
                      <li
                        className="nav-item"
                        onClick={(e) => {
                          navigate('/Observation_Plan')
                        }}
                      >
                        <a className="nav-link" href="#">
                          <i className="ti ti-file menu-icon"></i>
                          <span>Observations</span>
                        </a>
                      </li>
                    </>
                  )}

                  {/**
               * <li className="nav-item">
                <a
                  className="nav-link"
                  href="#sidebarRapport"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarRapport"
                >
                  <i className="ti ti-clipboard-list menu-icon"></i>
                  <span>Rapport</span>
                </a>
                <div className="collapse " id="sidebarRapport">
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a className="nav-link" href="index.html">
                        Compte rendu
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="index.html">
                        Fiche de visite
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
               */}
                  <li
                    className="nav-item"
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/Contact')
                    }}
                  >
                    <a className="nav-link" href="#">
                      <i className="ti ti-users menu-icon"></i>
                      <span>Contacts</span>
                    </a>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
