import React, { useEffect, useState } from 'react'
import { ReactTabulator } from 'react-tabulator'
import { getUser } from '../../../../helpers/contact/contact'
import PageNotFound from '../../../../components/404/PageNotFound'
const Presence = ({ data, updateData }) => {
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const checkbox = (cell) => {
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }

  const edited = (cell) => {
    const rowData = cell.getData()
    if (cell.getField() === 'retard' && cell.getValue() === true) {
      rowData.absent = false
      rowData.present = false
      updateData((prevData) => [...prevData])
    }
    if (cell.getField() === 'present' && cell.getValue() === true) {
      rowData.absent = false
      rowData.retard = false
      updateData((prevData) => [...prevData])
    }
    if (cell.getField() === 'absent' && cell.getValue() === true) {
      rowData.retard = false
      rowData.present = false
      updateData((prevData) => [...prevData])
    }
  }

  const colonnePresence = [
    {
      title: 'Nom',
      field: 'name',
      editor: 'input',
    },
    { title: 'Prénom', field: 'lastname', editor: 'input' },
    { title: 'Email', field: 'email', editor: 'input' },
    { title: 'Téléphone', field: 'telephone', editor: 'input' },
    {
      title: 'Présent',
      field: 'present',
      editor: true,
      formatter: checkbox,
    },
    { title: 'Retard', field: 'retard', editor: true, formatter: checkbox },
    { title: 'Absent', field: 'absent', editor: true, formatter: checkbox },
    {
      title: 'Convoqué',
      field: 'convoque',
      editor: true,
      formatter: checkbox,
    },
    {
      title: 'Diffusion',
      field: 'diffusionPresence',
      editor: true,
      formatter: checkbox,
    },
  ]

  return (
    <>
      <div className="d-flex flex-row-reverse mt-2">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          onClick={(e) => {
            e.preventDefault()
            updateData([
              ...data,
              {
                name: 'new',
                lastname: 'user',
                email: 'client@gmail.com',
                telephone: '00 00 00 00 00',
                present: true,
                retard: false,
                absent: false,
                role: 'autre',
                convoque: false,
                diffusionPresence: true,
                entreprise: 'aucune entreprise',
              },
            ])
          }}
        >
          <i class="ti ti-plus"></i> Ajouter
        </button>
      </div>
      <ReactTabulator
        data={data}
        columns={colonnePresence}
        layout={'fitColumns'}
        events={{
          cellEdited: edited,
        }}
        options={{
          locale: true,
          pagination: 'local',
          paginationSize: 20,
          langs: {
            fr: {
              pagination: {
                first: 'Premier',
                first_title: 'Première page',
                last: 'Dernier',
                last_title: 'Dernière page',
                prev: 'Précédent',
                prev_title: 'Page précédente',
                next: 'Suivant',
                next_title: 'Page suivante',
                page_size: 'Taille de page',
                page_title: 'Afficher la page',
                all: 'Tous',
              },
            },
          },
        }}
      />
    </>
  )
}

export default Presence
