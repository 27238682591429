import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import BeatLoader from 'react-spinners/BeatLoader'
import '../../../assets/plugins/datatables/datatable.css'
import {
  getRetard,
  updateRetard,
} from '../../../helpers/planning/retard/retard'
import { getAllData } from '../../../helpers/suivi/suivi'
import { useSelector } from 'react-redux'
import Modal from './Retard/Modal'
import PageNotFound from '../../../components/404/PageNotFound'
import { ReactTabulator } from 'react-tabulator'
//Liste des taches noté en retard
const Retard = () => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc')
  ) {
    return <PageNotFound />
  }
  const bool = useSelector((state) => state.bool.bool)
  const [page, setPage] = useState(1)
  const [nbPage, setNbPage] = useState(0)
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [lot, setLot] = useState([])
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [wait, setWait] = useState(false)
  const [dataRetard, setDataRetard] = useState([])
  const [filtre, setFiltre] = useState('false')
  const [recap, setRecap] = useState([])
  const date = (cell) => {
    const view = document.createElement('input')
    view.style= "background-color:white; border:none"
    view.type = 'date'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.disabled =true
   /*  view.addEventListener('blur', function (e) {
      cell.setValue(e.target.value)
    }) */
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  const formatLot = (cell) => {
  const data = cell.getData()
  console.log(data);
  const lot = data.lotResponsable?.map((el)=>{
    return el.titre
  })
  console.log(lot);
  const lotResponsale = lot ? lot.join(',') : []
  const champ = document.createElement('span')
  champ.innerText = lotResponsale
  const conteneur = document.createElement('span')
  conteneur.appendChild(champ)
  return conteneur
  }
  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = data.responsableEngage ? data.responsableEngage : false
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
      if (cell.getValue()) {
      } else {
      }
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch form-switch-primary mt-2'
    view.appendChild(input)
    return view
  }

  const modalRetard = (delFunction) => (cell) => {
    const data = cell.getData()
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', `#retard${data._id}`)
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.classList.add('btn-outline-success')
    modalBt.addEventListener('click', function () {
      setDataModalAdministration(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }

  const colonneRetards = [
    { title: 'Responsable retards' , formatter:formatLot},
    { title: 'Tâche', field: 'tache.text'},
    { title: 'Date début', field: 'tache.start_date', formatter: date,width: 150, },
    { title: 'Date fin', field: 'tache.end_date', formatter: date,width: 150, },
    { title: 'Retard Consigné', field: 'dure_retard',width: 100},
    {
      title: 'Pénalité',
      field: 'responsableEngage',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Details', width: 100, formatter: modalRetard() },
  ]

  useEffect(() => {
    setWait(true)
    setDataRetard([{}])
    getRetard(infoChantier.planning, page, filtre, infoUser.token).then((e) => {
      console.log(e)
      if (e.data?.status == 'error') {
        setDataRetard([])
        setPage(0)
        setNbPage(0)
        setWait(false)
      } else {
        console.log(e.data);
        setDataRetard(e.data)
        setNbPage(Math.ceil(e.length / 10))
        setWait(false)
      }
    })
  }, [bool, page, filtre])

  useEffect(() => {
    setWait(true)
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((e) => {
      if (e.name == 'AxiosError') {
        setLot([])
        setWait(false)
      } else {
        setLot(e.data)
        setWait(false)
      }
    })
  }, [])
  //formatter de date en fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid" style={{ paddingTop: '0.5vw' }}>
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <h4 class="page-title">Planification</h4>
                </div>
              </div>
            </div>
            <ul class="nav nav-pills">
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Dashboard')}
              >
                <a class="nav-link" href="#">
                  Tableau de bord
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_GestionTache')}
              >
                <a class="nav-link" href="#">
                  Gestion des tâches
                </a>
              </li>
              {/* <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Pointage')}
              >
                <a class="nav-link" href="#">
                  Pointage
                </a>
              </li> */}
              <li
                class="nav-item"
                onClick={(e) => navigate('/Planning_GestionTache_LastPlanner')}
              >
                <a class="nav-link" href="#">
                  Glissant
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Favori')}
              >
                <a class="nav-link" href="#">
                  Pour mémoire
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Retard')}
              >
                {' '}
                <a class="nav-link active" href="#">
                  Retards
                </a>
              </li>
            </ul>
            {/* debut retard */}
            <div className="container-fluid mt-3">
              <div className="table-responsive">
                <div class="page-title-box">
                  <div class="float-end">
                    <select
                      class="form-select"
                      id="sel1"
                      name="sellist1"
                      onChange={(e) => {
                        e.preventDefault()
                        setPage(1)
                        setFiltre(e.target.value)
                      }}
                    >
                      <option value={false} selected>
                        Tous les lots
                      </option>
                      {lot &&
                        lot.map((e) => {
                          return (
                            <>
                              <option value={e._id}>{e.titre}</option>
                            </>
                          )
                        })}
                    </select>
                  </div>
                </div>
              
                <table
                  class="table table-striped"
                  style={{ marginTop: '1vw' }}
                  id="datatable"
                >
                 {/*  <thead>
                    <tr>
                      <th>Lot</th>
                      <th>Tâche</th>
                      <th>Date début</th>
                      <th>Date fin</th>
                      <th>Retard consigné</th>
                      <th>Pénalité</th>
                      <th>Détails</th>
                    </tr>
                  </thead> */}
                  <tbody>
                    {wait && (
                      <>
                        <div
                          style={{
                            position: 'absolute',
                            top: '40%',
                            left: '45%',
                            zIndex:"11111111"
                          }}
                        >
                          <BeatLoader
                            color="yellowgreen"
                            loading={true}
                            size={25}
                          />
                        </div>
                      </>
                    )}
                    {dataRetard &&
                      dataRetard.map((e) => {
                        return (
                          <>
                           {/*  <tr>
                              <td>
                                {e.lotResponsable &&
                                  e.lotResponsable.map((el) => {
                                    return `${el.titre},`
                                  })}
                              </td>
                              <td>{e.tache ? e.tache.text : <></>}</td>
                              <td>
                                {e.tache ? dateFr(e.tache.start_date) : <></>}
                              </td>
                              <td>
                                {e.tache ? dateFr(e.tache.end_date) : <></>}
                              </td>
                              <td>
                                {e.dure_retard ? (
                                  `${e.dure_retard.toFixed(0)} jour(s)`
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                {e.dure_retard ? (
                                  <>
                                    <div class="form-check form-switch form-switch-success">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        defaultChecked={e.responsableEngage}
                                        onChange={(el) => {
                                          updateRetard(
                                            el.target.checked,
                                            e._id,
                                            e.lotResponsable,
                                            infoChantier.planning,
                                            e.dure_retard,
                                            e._id,
                                            infoUser.token
                                          ).then((e) => {
                                            console.log(e)
                                          })
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                {e.dure_retard ? (
                                  <>
                                    <i
                                      class="ti ti-circle-plus"
                                      style={{
                                        fontSize: '30px',
                                        cursor: 'pointer',
                                      }}
                                      data-bs-toggle="modal"
                                      data-bs-target={`#retard${e._id}`}
                                    ></i>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                             */}
                             <Modal
                              id={`retard${e._id}`}
                              data={e}
                              lot={lot}
                              responsables={e.lotResponsable}
                              idTache={e.tache?._id}
                            />
                          </>
                        )
                      })}
                  </tbody>
                </table>
                <div className="container-fluid">
                <div className="container-fluid">
               <ReactTabulator
                  data={dataRetard ? dataRetard : []}
                  columns={colonneRetards}
                  layout={'fitColumns'}
                  options={{
                    locale: true,
                    pagination: 'local',
                    paginationSize: 10,
                    langs: {
                      fr: {
                        pagination: {
                          first: 'Premier',
                          first_title: 'Première page',
                          last: 'Dernier',
                          last_title: 'Dernière page',
                          prev: 'Précédent',
                          prev_title: 'Page précédente',
                          next: 'Suivant',
                          next_title: 'Page suivante',
                          page_size: 'Taille de page',
                          page_title: 'Afficher la page',
                          all: 'Tous',
                        },
                      },
                    },
                  }}
                />
               </div>
                {/*  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end align-items-center">
                      <span style={{ marginRight: '5px' }}>
                        il y a {nbPage} page(s)
                      </span>
                      {page > 1 ? (
                        <>
                          <li
                            class={
                              page == 1 ? 'page-item disabled' : 'page-item'
                            }
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                            onClick={(e) => {
                              if (page > 1) {
                                setPage(page - 1)
                              }
                            }}
                          >
                            <span class="page-link">Précédent</span>
                          </li>
                          <li className="page-link">
                            <a
                              href="#"
                              className="page-ink"
                              onClick={(e) => {
                                setPage(1)
                              }}
                            >
                              1
                            </a>
                          </li>
                        </>
                      ) : (
                        ''
                      )}
                      <li class="page-item active">
                        <a class="page-link" href="#">
                          {page}
                        </a>
                      </li>
                      {page < nbPage ? (
                        <>
                          <li className="page-link">
                            <a
                              href="#"
                              className="page-ink"
                              onClick={(e) => {
                                setPage(nbPage)
                              }}
                            >
                              {nbPage}
                            </a>
                          </li>
                          <li
                            class="page-item"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              if (page < nbPage) {
                                setPage(page + 1)
                              }
                            }}
                          >
                            <span class="page-link">Suivant</span>
                          </li>
                        </>
                      ) : (
                        ''
                      )}
                    </ul>
                </nav> */}
                </div>
              </div>
            </div>
            {/* fin retard */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Retard
