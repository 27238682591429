import axios from 'axios';
export const upload = async (file,id,token) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('idChantier',id)
    const resultat = await axios
      .post(`${process.env.REACT_APP_URL}/api/v1/taches/newproject`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      })
      .then((res) => {
        console.log(res.data)
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return resultat;
  } catch (err) {
    console.log(err);
    return { message: 'erreur',status:'error' };
  }
};

export const getProgPrev = async (id,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const resultat = await axios.get(`${process.env.REACT_APP_URL}/api/v1/profile?planning=${id}`,config).then((res) => {
      return res.data.data
    }).catch((err) => {
        return err.response.data.data
    })
    return resultat
} catch (err) {
    alert("Le serveur ne répond pas")
    return { message: "erreur" }
}
};

export const getProgLot = async (planning,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const resultat = await axios.get(`${process.env.REACT_APP_URL}/api/v1/profile/globalLotProgress?planning=${planning}`,config).then((res) => {
      return res.data.data
    }).catch((err) => {
        return err.response.data.data
    })
    return resultat
} catch (err) {
    alert("Le serveur ne répond pas")
    return { message: "erreur" }
}
};

export const getProgGeneral = async (planning,year,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const resultat = await axios.get(`${process.env.REACT_APP_URL}/api/v1/profile/penalite?planning=${planning}`,config).then((res) => {
      return res.data.data
    }).catch((err) => {
        return err.response.data.data
    })
    return resultat
} catch (err) {
    alert("Le serveur ne répond pas")
    return { message: "erreur" }
}
};

