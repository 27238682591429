import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { GeoAltFill } from 'react-bootstrap-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'
const Pin = ({ props, idt, idImage, lot, tacheSd, tacheSc, tacheSp }) => {
  //formatage date
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0')
  }
  //date en format fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }
  //fonction pour formater les dates
  const formatDate = (date) => {
    date = new Date(date)
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const bool = useSelector((state) => state.bool.bool)
  const [bool2, setBool2] = useState(idt)
  const [itemNumber, setItemNumber] = useState(props.itemNumber)
  const [top, setTop] = useState(props.top)
  const [left, setLeft] = useState(props.left)
  const [del, setDel] = useState(true)
  const [description, setDescription] = useState(
    props.description ? props.description : ''
  )
  const [phase, setPhase] = useState(props.phase ? props.phase : '')
  const [status, setStatus] = useState(props.statut ? props.statut : 'designe')
  const [position, setPosition] = useState(props.position ? props.position : '')
  const [destinataire, setDestinataire] = useState(
    props.destinataire ? props.destinataire : []
  )
  const [observation, setObservation] = useState(
    props.observation ? props.observation : ''
  )
  const [markers, setMarkers] = useState({})
  const [id, setId] = useState()
  const [img, setImg] = useState(true)
  const [image, setImage] = useState()
  const [alever, setAlever] = useState(
    props.aLeverLe ? formatDate(props.aLeverLe) : null
  )
  const [tag, setTag] = useState(props.tag ? props.tag : [])
  const [photo, setPhoto] = useState(props.photo ? props.photo : 'undefined')
  const infoChantiers = localStorage.getItem('infoChantiers')
  const [infoObservation, setInfoObservation] = useState({})
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [allLot, setAllLot] = useState(lot ? lot : [])
  const [allObservation, setAllObservation] = useState(
    localStorage.getItem('horsLigne')
      ? JSON.parse(localStorage.getItem('horsLigne'))
      : []
  )
  const [allGroupe, setAllGroupe] = useState([])
  const [allTacheAssigner, setAllTacheAssigner] = useState(
    props.taches ? props.taches : []
  )
  useEffect(() => {
    if (localStorage.getItem('allGroupe') == null) {
      setAllGroupe([])
    } else {
      setAllGroupe(JSON.parse(localStorage.getItem('allGroupe')))
    }
    if (JSON.parse(localStorage.getItem('horsLigne')) == null) {
      setAllObservation([])
    } else {
      setAllObservation(JSON.parse(localStorage.getItem('horsLigne')))
    }
    setMarkers(props)
    setId(props.itemNumber)
    if (props.photo) {
      setImg(false)
    } else {
      setImg(true)
    }
  }, [])
  const delPin = () => {
    //setMarkers({ ...props, description: description });
    const uniqueIds = allTacheAssigner.reduce((acc, el) => {
      if (!acc.includes(el._id)) {
        acc.push(el._id)
      }
      return acc
    }, [])
    console.log(status)
    const formData = new FormData()
    formData.append('archive', true)
    formData.append('top', top)
    formData.append('left', left)
    formData.append('description', description)
    formData.append('phase', phase)
    formData.append('itemNumber', itemNumber)
    formData.append('aLeverLe', alever ? alever : new Date(Date.now()))
    formData.append('statut', status)
    formData.append('tag', JSON.stringify([]))
    formData.append('position', position)
    formData.append('destinataire', JSON.stringify([]))
    formData.append('observation', observation)
    formData.append('file', image)
    formData.append('_id', props._id)
    formData.append('taches', JSON.stringify(uniqueIds))
    formData.append('photo', photo)
    axios
      .patch(
        `${process.env.REACT_APP_URL}/api/v1/ressources/${idImage}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((e) => {
        dispatch({ type: 'bool/modifier', payload: !bool })
      })
      .catch((e) => {
        console.log(e)
        dispatch({ type: 'bool/modifier', payload: !bool })
      })
  }
  
  const save = () => {
    //setMarkers({ ...props, description: description });
    const uniqueIds = allTacheAssigner.reduce((acc, el) => {
      if (!acc.includes(el._id)) {
        acc.push(el._id)
      }
      return acc
    }, [])
    console.log(status)
    const formData = new FormData()
    formData.append('archive', false)
    formData.append('top', top)
    formData.append('left', left)
    formData.append('description', description)
    formData.append('phase', phase)
    formData.append('itemNumber', itemNumber)
    formData.append('aLeverLe', alever ? alever : new Date(Date.now()))
    formData.append('statut', status)
    formData.append('tag', JSON.stringify(tag))
    formData.append('position', position)
    formData.append('destinataire', JSON.stringify(destinataire))
    formData.append('observation', observation)
    formData.append('file', image)
    formData.append('_id', props._id)
    formData.append('taches', JSON.stringify(uniqueIds))
    formData.append('photo', photo)
    axios
      .patch(
        `${process.env.REACT_APP_URL}/api/v1/ressources/${idImage}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((e) => {
        dispatch({ type: 'bool/modifier', payload: !bool })
      })
      .catch((e) => {
        console.log(e)
        dispatch({ type: 'bool/modifier', payload: !bool })
      })
      .finally(dispatch({ type: 'bool/modifier', payload: !bool }))
  }
  useEffect(() => {}, [bool, bool2, del])

  const dataTag = [
    {
      value: 'plannification',
    },
    {
      value: 'engagement',
    },
    {
      value: 'securite',
    },
    {
      value: 'installation',
    },
  ]
  return (
    <>
      {(del && !props.archive) && (
        <div
          className="d-flex"
          style={{alignItems: 'center'}}
          // draggable={true}
          // onDragStart={(e)=>{
          //   console.log(document.querySelector('.image-marker__marker').parentNode);
          // }}
          // onDragEnd={(e)=>{
          //   console.log(e.target.parentNode.offsetLeft);
          // }}
          // id="dragtarget"
          onClick={(e) => {
            setBool2(true)
          }}
        >
          <GeoAltFill color="red" size={24} />
        </div>
      )}
      {bool && bool2 ? (
        <>
          <div className="docPopup" style={{ zIndex: '20000' }}>
            <div
              className="container-fluid mb-2"
              style={{ position: 'relative' }}
            >
              <i
                class="ti ti-x"
                style={{
                  position: 'absolute',
                  right: '0',
                  cursor: 'pointer',
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
                onClick={(e) => {
                  setBool2(false)
                }}
              ></i>
            </div>
            <form>
              <div class="mb-3">
                <button
                  className="btn btn-primary mt-3"
                  data-bs-toggle="modal"
                  data-bs-target="#myModal"
                  style={{ width: '100%' }}
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  Ajouter depuis les observations hors ligne
                </button>
              </div>
              <div class="mb-3">
                <label
                  for="exampleInputEmail1"
                  class="form-label"
                  style={{ color: 'white' }}
                >
                  Statut:
                </label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setStatus(e.target.value)
                  }}
                >
                  <option
                    value="designe"
                    selected={status == 'designe' ? true : false}
                  >
                    Désignée
                  </option>
                  <option
                    value="traite"
                    selected={status == 'traite' ? true : false}
                  >
                    Traitée
                  </option>
                  <option
                    value="annule"
                    selected={status == 'annule' ? true : false}
                  >
                    Annulée
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label
                  for="exampleInputEmail1"
                  class="form-label"
                  style={{ color: 'white' }}
                >
                  <i class="ti ti-square-x" style={{ color: 'red' }}></i>{' '}
                  Observation:
                </label>
                <input
                  type="text"
                  class="form-control"
                  defaultValue={observation ? observation : ''}
                  style={{ width: '98%' }}
                  onChange={(e) => {
                    setObservation(e.target.value)
                  }}
                />
              </div>

              {/* Assignement à une tâche */}
              <div class="mb-3">
                <label
                  for="exampleInputEmail1"
                  class="form-label"
                  style={{ color: 'white' }}
                >
                  <i class="ti ti-square-x" style={{ color: 'red' }}></i>{' '}
                  Assigner à une tâche:{' '}
                  <button
                    type="button"
                    className="btn btn-sm btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#myModal1"
                  >
                    Assigner{' '}
                    <i
                      class="ti ti-edit"
                      style={{ color: 'white', size: '12px' }}
                    ></i>{' '}
                  </button>
                </label>

                <div
                  class="modal"
                  id="myModal1"
                  style={{ zIndex: '22222222222222222222222222' }}
                >
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title">Liste des tâches</h4>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                        ></button>
                      </div>

                      <div class="modal-body">
                        <ul class="nav nav-tabs" role="tablist">
                          <li className="nav-item">
                            <a
                              class="nav-link active"
                              data-bs-toggle="tab"
                              href="#S-1"
                              role="tab"
                              aria-selected="true"
                            >
                              Semaine dernière
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              class="nav-link"
                              data-bs-toggle="tab"
                              href="#S"
                              role="tab"
                              aria-selected="true"
                            >
                              Semaine en cours
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              class="nav-link"
                              data-bs-toggle="tab"
                              href="#S1"
                              role="tab"
                              aria-selected="true"
                            >
                              Semaine prochaine
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              class="nav-link d-flex align-items-center"
                              data-bs-toggle="tab"
                              href="#Taches"
                              role="tab"
                              aria-selected="true"
                            >
                              Tâches assignées{' '}
                              <span
                                className="justify-content-center d-flex align-items-center bg-dark "
                                style={{
                                  marginLeft: '2px',
                                  color: 'white',
                                  width: '25px',
                                  height: '25px',
                                  borderRadius: '50%',
                                  fontSize: '10px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {allTacheAssigner.length}
                              </span>
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            class="tab-pane p-3 active"
                            id="S-1"
                            role="tabpanel"
                          >
                            <div class="row">
                              <div className="container-fluid">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th>Nom du tâche</th>
                                      <th>date de début</th>
                                      <th>date de fin</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tacheSd &&
                                      tacheSd.map((el) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>
                                                {el.id ? el.id : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.text
                                                  ? el.text
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.start_date
                                                  ? dateFr(el.start_date)
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.end_date
                                                  ? dateFr(el.end_date)
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                {' '}
                                                <button
                                                  type="button"
                                                  className={
                                                    allTacheAssigner.find(
                                                      (obj) => obj.id === el.id
                                                    )
                                                      ? 'btn btn-xs btn-dark'
                                                      : 'btn btn-xs btn-success'
                                                  }
                                                  style={{
                                                    fontSize: '10px',
                                                    fontWeight: 'bold',
                                                  }}
                                                  onClick={(e) => {
                                                    e.preventDefault()
                                                    const objetExistant =
                                                      allTacheAssigner.find(
                                                        (obj) =>
                                                          obj.id === el.id
                                                      )
                                                    if (objetExistant) {
                                                    } else {
                                                      setAllTacheAssigner([
                                                        ...allTacheAssigner,
                                                        el,
                                                      ])
                                                    }
                                                  }}
                                                >
                                                  {allTacheAssigner.find(
                                                    (obj) => obj.id === el.id
                                                  )
                                                    ? 'Assigné'
                                                    : 'Assigner'}
                                                </button>
                                                {/* <input
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setAllTacheAssigner([
                                                        ...allTacheAssigner,
                                                        el,
                                                      ])
                                                    } else {
                                                      setAllTacheAssigner(
                                                        allTacheAssigner.filter(
                                                          (tache) =>
                                                            (tache.id != el.id)
                                                        )
                                                      )
                                                    }
                                                  }}
                                                />{' '} */}
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div class="tab-pane p-3" id="S" role="tabpanel">
                            <div class="row">
                              <div className="container-fluid">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th>Nom du tâche</th>
                                      <th>date de début</th>
                                      <th>date de fin</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tacheSc &&
                                      tacheSc.map((el) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>
                                                {el.id ? el.id : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.text
                                                  ? el.text
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.start_date
                                                  ? dateFr(el.start_date)
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.end_date
                                                  ? dateFr(el.end_date)
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                {' '}
                                                <button
                                                  type="button"
                                                  className={
                                                    allTacheAssigner.find(
                                                      (obj) => obj.id === el.id
                                                    )
                                                      ? 'btn btn-xs btn-dark'
                                                      : 'btn btn-xs btn-success'
                                                  }
                                                  style={{
                                                    fontSize: '10px',
                                                    fontWeight: 'bold',
                                                  }}
                                                  onClick={(e) => {
                                                    e.preventDefault()
                                                    const objetExistant =
                                                      allTacheAssigner.find(
                                                        (obj) =>
                                                          obj.id === el.id
                                                      )
                                                    if (objetExistant) {
                                                    } else {
                                                      setAllTacheAssigner([
                                                        ...allTacheAssigner,
                                                        el,
                                                      ])
                                                    }
                                                  }}
                                                >
                                                  {allTacheAssigner.find(
                                                    (obj) => obj.id === el.id
                                                  )
                                                    ? 'Assigné'
                                                    : 'Assigner'}
                                                </button>
                                                {/* <input
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setAllTacheAssigner([
                                                        ...allTacheAssigner,
                                                        el,
                                                      ])
                                                    } else {
                                                      setAllTacheAssigner(
                                                        allTacheAssigner.filter(
                                                          (tache) =>
                                                            (tache.id != el.id)
                                                        )
                                                      )
                                                    }
                                                  }}
                                                />{' '} */}
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div class="tab-pane p-3" id="S1" role="tabpanel">
                            <div class="row">
                              <div className="container-fluid">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th>Nom du tâche</th>
                                      <th>date de début</th>
                                      <th>date de fin</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tacheSp &&
                                      tacheSp.map((el) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>
                                                {el.id ? el.id : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.text
                                                  ? el.text
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.start_date
                                                  ? dateFr(el.start_date)
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.end_date
                                                  ? dateFr(el.end_date)
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                {' '}
                                                <button
                                                  type="button"
                                                  className={
                                                    allTacheAssigner.find(
                                                      (obj) => obj.id === el.id
                                                    )
                                                      ? 'btn btn-xs btn-dark'
                                                      : 'btn btn-xs btn-success'
                                                  }
                                                  style={{
                                                    fontSize: '10px',
                                                    fontWeight: 'bold',
                                                  }}
                                                  onClick={(e) => {
                                                    e.preventDefault()
                                                    const objetExistant =
                                                      allTacheAssigner.find(
                                                        (obj) =>
                                                          obj.id === el.id
                                                      )
                                                    if (objetExistant) {
                                                    } else {
                                                      setAllTacheAssigner([
                                                        ...allTacheAssigner,
                                                        el,
                                                      ])
                                                    }
                                                  }}
                                                >
                                                  {allTacheAssigner.find(
                                                    (obj) => obj.id === el.id
                                                  )
                                                    ? 'Assigné'
                                                    : 'Assigner'}
                                                </button>
                                                {/* <input
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setAllTacheAssigner([
                                                        ...allTacheAssigner,
                                                        el,
                                                      ])
                                                    } else {
                                                      setAllTacheAssigner(
                                                        allTacheAssigner.filter(
                                                          (tache) =>
                                                            (tache.id != el.id)
                                                        )
                                                      )
                                                    }
                                                  }}
                                                />{' '} */}
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div class="tab-pane p-3" id="Taches" role="tabpanel">
                            <div class="row">
                              <div className="container-fluid">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th>Nom du tâche</th>
                                      <th>date de début</th>
                                      <th>date de fin</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {allTacheAssigner &&
                                      allTacheAssigner.map((el) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>
                                                {el.id ? el.id : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.text
                                                  ? el.text
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.start_date
                                                  ? dateFr(el.start_date)
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                {el.end_date
                                                  ? dateFr(el.end_date)
                                                  : 'Non défini'}
                                              </td>
                                              <td>
                                                <button
                                                  type="button"
                                                  className="btn btn-xs btn-danger"
                                                  style={{
                                                    fontSize: '10px',
                                                    fontWeight: 'bold',
                                                  }}
                                                  onClick={(e) => {
                                                    e.preventDefault()
                                                    setAllTacheAssigner(
                                                      allTacheAssigner.filter(
                                                        (res) => res.id != el.id
                                                      )
                                                    )
                                                  }}
                                                >
                                                  retirer
                                                </button>
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-success"
                          data-bs-dismiss="modal"
                        >
                          Enregistrer
                        </button>
                        <button
                          type="button"
                          class="btn btn-dark"
                          data-bs-dismiss="modal"
                        >
                          Fermer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* fin assignement à une tâche */}
              <div class="mb-3">
                <label
                  for="exampleInputEmail1"
                  class="form-label"
                  style={{ color: 'white' }}
                >
                  <i class="ti ti-square-x" style={{ color: 'red' }}></i> Tag:
                </label>
                <Multiselect
                  displayValue="value"
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck(e) {
                    setTag(e)
                  }}
                  onSearch={function noRefCheck() {}}
                  onSelect={function noRefCheck(e) {
                    setTag(e)
                  }}
                  options={dataTag}
                  selectedValues={tag}
                  placeholder="Tag"
                />
              </div>
              <div class="mb-3">
                <label
                  for="exampleInputEmail1"
                  class="form-label"
                  style={{ color: 'white' }}
                >
                  Position:
                </label>
                <input
                  type="text"
                  class="form-control"
                  defaultValue={position ? position : ''}
                  style={{ width: '98%' }}
                  onChange={(e) => {
                    setPosition(e.target.value)
                  }}
                />
              </div>

              {/* Selection destinataire */}
              <div class="mb-3">
                <label
                  for="exampleInputEmail1"
                  class="form-label"
                  style={{ color: 'white' }}
                >
                  <i class="ti ti-square-x" style={{ color: 'red' }}></i>{' '}
                  Destinataire(s):
                </label>
                <Multiselect
                  displayValue="titre"
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck(e) {
                    setDestinataire(e)
                  }}
                  onSearch={function noRefCheck() {}}
                  onSelect={function noRefCheck(e) {
                    setDestinataire(e)
                  }}
                  options={allLot}
                  selectedValues={destinataire}
                  placeholder="Destinataire(s)"
                />
              </div>
              {/* Fin selection destinataire */}
              <div class="mb-3">
                <label
                  for="exampleInputEmail1"
                  class="form-label"
                  style={{ color: 'white' }}
                >
                  <i class="ti ti-square-x" style={{ color: 'red' }}></i> A
                  lever le:
                </label>
                <input
                  type="date"
                  class="form-control"
                  style={{ width: '98%' }}
                  defaultValue={alever}
                  onChange={(e) => {
                    setAlever(e.target.value)
                  }}
                />
              </div>

              {img ? (
                <>
                  <div class="mb-3">
                    <label
                      for="exampleInputEmail1"
                      class="form-label"
                      style={{ color: 'white' }}
                    >
                      Image:
                    </label>
                    <input
                      type="file"
                      class="form-control"
                      accept=".png,.jpg,.jpeg"
                      style={{ width: '98%' }}
                      onChange={(e) => {
                        setImage(e.target.files[0])
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  {console.log(props)}
                  <div
                    className="container-fluid"
                    style={{ cursor: 'pointer' }}
                  >
                    <h5 className="text-center">
                      {' '}
                      <u
                        className="text-light"
                        onClick={(e) => {
                          navigate(`/Observation_Media/${props.photo}`)
                        }}
                      >
                        Annoter l'image
                      </u>{' '}
                      <button
                        className="btn btn-danger"
                        onClick={(e) => {
                          e.preventDefault()
                          // deleteMedia(props.photo,infoUser.token).then((e)=>{
                          //   console.log(e);
                          // })
                          setImg(true)
                          setImage()
                          setPhoto('undefined')
                        }}
                      >
                        <i className="ti ti-trash"></i>
                      </button>
                    </h5>
                    <img
                      src={`${process.env.REACT_APP_URL}/api/v1/ressources?filename=${props.nomMedia}&format=media&accessToken=${infoUser.token}`}
                      alt="image"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        setImg(true)
                      }}
                      style={{ width: '100%' }}
                      onClick={(e) => {
                        navigate(`/Observation_Media/${props.photo}`)
                      }}
                    />
                  </div>
                </>
              )}
              <div className="d-flex" style={{ marginTop: '0.5vw' }}>
                <button
                  className="btn btn-sm  btn-success"
                  style={{ fontSize: '15px', marginRight: '5px' }}
                  onClick={(e) => {
                    e.preventDefault()
                    save()
                  }}
                >
                  Enregistrer
                </button>{' '}
                <br />
                <button
                  className="btn btn-sm btn-primary"
                  style={{ fontSize: '15px', marginLeft: '5px' }}
                  onClick={(e) => {
                    setBool2(false)
                    dispatch({ type: 'pinMedia/modifier', payload: false })
                  }}
                >
                  Fermer
                </button>
                {''}
                <br />
                <button
                  className="btn btn-sm  btn-dark"
                  style={{ fontSize: '15px', marginLeft: '5px' }}
                  onClick={(e) => {
                    if (confirm("Êtes-vous certain(e) de vouloir supprimer l'observation ? ")) {
                      setBool2(false)
                      setDel(false)
                      dispatch({ type: 'bool/modifier', payload: !bool })
                      console.log(props.itemNumber)
                      delPin()
                    }else{
                      e.preventDefault()
                    }
                    /*  axios
                      .patch(
                        `${process.env.REACT_APP_URL}/api/v1/ressources/${idImage}`,{
                          archive:true
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${infoUser.token}`,
                          },
                        }
                      )
                      .then((e) => {
                        console.log(e)
                        dispatch({ type: 'bool/modifier', payload: !bool })
                      })
                      .catch((e) => {
                        console.log(e)
                        dispatch({ type: 'bool/modifier', payload: !bool })
                      }) */
                  }}
                >
                  Supprimer
                </button>
              </div>
            </form>
            {/* Modal pour la selection de l'observation hors ligne */}
            <div
              class="modal"
              id="myModal"
              style={{ ZIndex: '999999999999999' }}
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title">Ajout de l'observation</h4>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="container">
                      <label className="form-label">
                        Liste des observations hors ligne
                      </label>
                      <select
                        className="form-select"
                        onChange={(e) => {
                          setInfoObservation(JSON.parse(e.target.value))
                        }}
                      >
                        <option value="Sélectionner" selected>
                          {' '}
                          Sélectionner parmis les groupes
                        </option>
                        {allGroupe &&
                          allGroupe.map((e) => {
                            return (
                              <>
                                <optgroup label={e}>
                                  {allObservation &&
                                    allObservation.map((el) => {
                                      if (el.groupe == e) {
                                        return (
                                          <>
                                            <option value={JSON.stringify(el)}>
                                              {el.nomPlan} : {el.observation}
                                            </option>
                                          </>
                                        )
                                      } else {
                                      }
                                    })}
                                </optgroup>
                              </>
                            )
                          })}
                        <optgroup label="Sans groupe">
                          {allObservation &&
                            allObservation.map((el) => {
                              if (el.groupe == 'Pas de goupe précisé') {
                                return (
                                  <>
                                    <option value={JSON.stringify(el)}>
                                      <span className="text-danger">
                                        {el.nomPlan} : {el.observation}
                                      </span>
                                    </option>
                                  </>
                                )
                              } else {
                              }
                            })}
                        </optgroup>
                      </select>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      className="btn btn-success"
                      onClick={(e) => {
                        e.preventDefault()
                        setAlever(infoObservation.aLeverLe)
                        setDestinataire(infoObservation.destinataire)
                        setObservation(infoObservation.observation)
                        setTag(infoObservation.tag)
                        setPosition(infoObservation.position)
                        setStatus(infoObservation.status)
                      }}
                      data-bs-dismiss="modal"
                    >
                      Valider
                    </button>
                    <button
                      type="button"
                      class="btn btn-dark"
                      data-bs-dismiss="modal"
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default Pin
